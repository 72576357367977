/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Dialog, DialogProps } from '@blueprintjs/core';
import { useMemo } from 'react';

import ManageSubscription, { ManageSubscriptionProps } from 'subscription/components/ManageSubscription';
import styles from './styles.module.scss';

export default function ({
  className, widgetProps, ...rest
}: ManageSubscriptionDialogProps) {
  const classes = useMemo(() => {
    const cls = [styles.manageSubscriptionDialog, className];
    return cls.join(" ").trim();
  }, [className])

  return (
    <Dialog
      className={classes}
      title={"Manage Subscription"}
      usePortal={false}
      isCloseButtonShown={true}
      {...rest}>
      <ManageSubscription
        className={styles.manageSubscriptionDialogWidget}
        {...widgetProps} />
    </Dialog>
  );
}

export type ManageSubscriptionDialogProps = DialogProps & {
  widgetProps: ManageSubscriptionProps,
}