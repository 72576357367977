/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";

import { useSyncBranches } from "branch/branch.hooks";
import { useSyncCatalogs } from "catalog/catalog.hooks";
import { useSyncCategories } from "category/category.hooks";
import { isSuccessful } from "common/utils";
import { useSyncEmployees } from "employee/employee.hooks";
import { useSyncMerchantAccounts } from "merchant/merchant.hooks";
import { useSyncProducts } from "product/product.hooks";
import { useGetSubscription } from "subscription/subscription.hooks";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";


export default function ChecklistForm({
  className, ...rest
}: ChecklistFormProps) {
  const { t } = useTranslation(undefined, { keyPrefix: "Checklist.Form" })
  const classes = useMemo(() => [styles.checklist, className].join(" "), [className]);

  const { syncProductsState } = useSyncProducts();
  const { syncCategoriesState } = useSyncCategories();
  const { syncCatalogsState } = useSyncCatalogs();
  const { syncBranchesState } = useSyncBranches();
  const { syncEmployeesState } = useSyncEmployees();
  const { syncMerchantAccountsState } = useSyncMerchantAccounts();
  const { getCurrentSubscriptionState } = useGetSubscription();

  return (
    <div className={classes} {...rest}>
      <ul>
        <li>{t("createBusinessProfile")}</li>
        <ul>
          <li>
            <a href="/business" target="_blank">{t("createYourBusinessProfile")}</a>
            <span>✅</span>
          </li>
        </ul>
        <li>{t("addMenuItems")}</li>
        <ul>
          <li>
            <a href="/products/new" target="_blank">{t("addYourMenuItems")}</a>
            {isSuccessful(syncProductsState) && (
              <span>{syncProductsState.value!.length > 0 ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>{t("addMenuCategories")}</li>
        <ul>
          <li>
            <a href="/categories/new" target="_blank">{t("organizeYourMenuCategories")}</a>
            {isSuccessful(syncCategoriesState) && (
              <span>{syncCategoriesState.value!.length > 0 ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>{t("setUpMenu")}</li>
        <ul>
          <li>
            <a href="/catalogs/new" target="_blank">{t("setUpYourCatalog")}</a>
            {isSuccessful(syncCatalogsState) && (
              <span>{syncCatalogsState.value!.length > 0 ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>{t("setUpYourMerchant")}</li>
        <ul>
          <li>
            <a href="/merchants/stripe/new" target="_blank">{t("enableOnlinePayments")}</a>
            {isSuccessful(syncMerchantAccountsState) && (
              <span>{syncMerchantAccountsState.value!.find(m => m.type === 'stripe' && m.status === 'active') != null ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>{t("setupTrialSubscription")}</li>
        <ul>
          <li>
            <a href="/subscription" target="_blank">{t("startYourTrial")}</a>
            {getCurrentSubscriptionState !== undefined && (
              <span>{getCurrentSubscriptionState.value != null ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>{t("createAndConfigureBranch")}</li>
        <ul>
          <li>
            <a href="/branches/new" target="_blank">{t("addBranchesAndConfigureOpeningHours")}</a>
            {isSuccessful(syncBranchesState) && (
              <span>{syncBranchesState.value!.length > 0 ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>{t("addAndInviteStaff")}</li>
        <ul>
          <li>
            <a href="/employees/new" target="_blank">{t("inviteYourStaff")}</a>
            {isSuccessful(syncEmployeesState) && (
              <span>{syncEmployeesState.value!.length > 0 ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>{t("downloadBusinessApp")}</li>
        <ul>
          <li>
            <a href="/apps" target="_blank">{t("downloadToManageOrders")}</a>
          </li>
        </ul>
        <li>{t("digitalMarketingAssets")}</li>
        <ul>
          <li>
            <a href="/digital-marketing-assets" target="_blank">{t("useYourDigitalMarketingAssets")}</a>
          </li>
        </ul>
        <li>{t("contactSalesSupport")}</li>
        <ul>
          <li>
            <a href="mailto:support@dishdash.io" target="_blank">{t("reachOutSalesSupport")}</a>
          </li>
        </ul>
      </ul>

    </div>
  );
}

export type ChecklistFormProps = {
  className?: string;
};