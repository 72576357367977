/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
	BackActivityIndicator, BackAsyncError, Callout, Center, PageContent,
	PageHeader
} from "common/components";
import { ApiCallStateBuilder } from "common/utils";
import ManageSubscription from "subscription/components/ManageSubscription";
import SubscriptionPlans from "subscription/components/SubscriptionPlans";
import { useGetSubscription } from "subscription/subscription.hooks";


export default function SubscriptionPage() {
  const {t} = useTranslation();
  const { state } = useLocation();
  const { getCurrentSubscriptionState, getCurrentSubscription } = useGetSubscription();

  return (
    <>
      <PageHeader
        title={["subscription"]}
        backButtonPath={state?.backpath}>
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder state={getCurrentSubscriptionState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} onTryAgain={getCurrentSubscription} />}
          onValue={() =>
            <Center>
              <ManageSubscription style={{ margin: 20 }} />
            </Center>
          }
          onValueEmpty={() => (
            <>
              <Callout intent='primary' style={{ marginBottom: 30 }}>
                {t("Subscription.ItemPage.welcomeCallout")}
              </Callout>
              <SubscriptionPlans />
            </>
          )}
        />
      </PageContent>
    </>
  );
}
