/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Decimal } from 'decimal.js';
import { useMemo } from 'react';

import { CartItem } from 'catalog/catalog.entities';
import { CardItem, Column } from "common/components";
import Card, { CardProps } from "common/components/cards/Card";
import styles from "./styles.module.scss";


export default function ({
  className, value, onClick, children, ...rest
}: CartItemCardProps) {
  const classes = useMemo(() => [styles.cartItemCard, className].join(" "), [className]);
  const totalPrice = useMemo(() => {
    let sum = new Decimal(value.product.price).mul(new Decimal(value.quantity));
    value.options?.forEach(option => {
      if (option.price != null) {
        sum = sum.add(new Decimal(option.price).mul(new Decimal(value.quantity)));
      }
    })
    return sum.toNumber().toFixed(2).padEnd(2, '0');
  }, [value]);

  return (
    <Card
      interactive={onClick != null}
      className={classes}
      onClick={onClick}
      {...rest} >
      <Column fill>
        <h4>{value.product.name} <span className='quantity'>{value.quantity}</span></h4>
        {children || value.options?.map((option) =>
          <CardItem
            key={option.id}
            className={styles.option}
            text={option.name}
            right={'+' + option.price}
          />
        )}
        <footer>{totalPrice}</footer>
      </Column>
      {value.product.image?.small &&
        <img
          className={styles.productImage}
          src={value.product.image.small.url}
          onError={(event: any) => event.target.style.display = 'none'} />}
    </Card>
  );
}

export type CartItemCardProps = CardProps & {
  value: CartItem,
};