/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Button, { ButtonProps } from "../../buttons/Button";
import styles from "./styles.module.sass";


export default function FormButton({
  className, ...buttonProps
}: FormButtonProps) {
  if (buttonProps.hidden)
    return null;
  
  const classes = [styles.formButton, className];
  return <Button className={classes.join(' ')} {...buttonProps} />;
}


type FormButtonProps = ButtonProps;