/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from 'moment';

import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import {
  BackActivityIndicator, BackAsyncError, Backout, Button,
  Card, CardContent, CardHeader, Column, DateRangePicker, Grid,
  PageContent, PageHeader
} from "common/components";
import { ApiCallStateBuilder, isEmpty, useApiState } from "common/utils";
import loadAction from 'report/actions/load-periodical-sales-report.action';

import styles from "./styles.module.scss";


export default function ({ period }: { period: 'day' | 'week' | 'month' }) {
  const { t, i18n } = useTranslation();

  const [loadState, dispatch] = useApiState((state) =>
    period === 'day' ? state.report.loadDailySalesReport :
      period === 'week' ? state.report.loadWeeklySalesReport :
        state.report.loadMonthlySalesReport);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(
    [moment().add(-1, 'month').toDate(), moment().toDate()]);

  useEffect(() => {
    if (isEmpty(loadState) && dateRange[0] != null) {
      dispatch(loadAction(dateRange[0]!, dateRange[1]!, period));
    }
  }, [loadState, dateRange])

  return (
    <>
      <PageHeader title={[`Report.PeriodicalSalesReport.Header.${period}`]}>
        <DateRangePicker
          className={styles.dateRangeInput}
          formatDate={(date) => moment(date).format(t("Report.dateRangePickerFormat"))}
          parseDate={(str) => moment(str).toDate()}
          onChange={(range) => setDateRange(range)}
          value={dateRange}
        />
        <Button
          icon='arrow-right'
          className={styles.dateRangeButton}
          onClick={() => dispatch(loadAction(dateRange[0]!, dateRange[1]!, period))}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder
          state={loadState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueNull={() => {
            return <Backout
              icon="series-search"
              message={["Report.noDataBackout"]} />
          }}
          onValue={(report) => {
            return (
              <>
                <h3>{t("Report.PeriodicalSalesReport.title", "", {
                  from: moment(dateRange[0]).format(t("Report.PeriodicalSalesReport.titleDateFormat")),
                  to: moment(dateRange[1]).format(t("Report.PeriodicalSalesReport.titleDateFormat"))
                })}</h3>
                <Grid md={2} xs={1} gap={20}>
                  <Card style={{ minHeight: 400 }}>
                    <CardHeader
                      title={["Report.PeriodicalSalesReport.cardHeader", { currency: report.currency }]} />
                    <CardContent>
                      <Column className={styles.salesReportBody}>
                        <table className={styles.detailedReportTable}>
                          <thead>
                            <tr>
                              <td>{t("Report.PeriodicalSalesReport.Columns.period")}</td>
                              <td>{t("Report.PeriodicalSalesReport.Columns.orders")}</td>
                              <td>{t("Report.PeriodicalSalesReport.Columns.netAmount")}</td>
                              <td>{t("Report.PeriodicalSalesReport.Columns.totalAmount")}</td>
                            </tr>
                          </thead>
                          <tbody>
                            {report.records.map((item, itemIdx) =>
                              <tr key={itemIdx}>
                                <td>
                                  {moment(item.periodBegins).format(t("Report.PeriodicalSalesReport.dateTimeFormat"))}
                                  {period === 'day' ? null : moment(item.periodEnds).format(' - ' + t("Report.PeriodicalSalesReport.dateTimeFormat"))}
                                </td>
                                <td>{item.orders}</td>
                                <td>{item.netAmount.toFixed(2)}</td>
                                <td>{item.totalAmount.toFixed(2)}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Column>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          }} />
      </PageContent>
    </>
  );
}
