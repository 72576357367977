/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { useMemo } from "react";

import styles from "./styles.module.scss";


export default function CardContent({
  className, children, ...rest
}: CardContentProps) {
  const classes = useMemo(() => {
    const cls = [styles.cardContent];
    if (className) cls.push(className);
    return cls.join(' ').trim();
  }, [className]);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
}

export type CardContentProps = React.ComponentPropsWithRef<"div"> & {
}