/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { SignOtpValueState } from "auth/auth.store";
import { ApiAction, failed, loading, success } from "common/utils";


export function SignOtpValueAction(token: string, code: string, value: any): ApiAction {
  return async (dispatch, _store, api) => {
    dispatch(SignOtpValueState(loading()));
    try {
      const { value: valueToken } = await api.post("/auth/otp/sign", {
        data: { token, code, value }
      });
      dispatch(SignOtpValueState(success(valueToken)));
    } catch (error) {
      dispatch(SignOtpValueState(failed(error)));
    }
  };
}