/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from 'moment';

import { ApiAction, ApiCallState, failed, loading, success } from "common/utils";
import { PeriodicalSalesReport } from 'report/report.entities';
import {
  ChangeLoadDailySalesReportState,
  ChangeLoadMonthlySalesReportState,
  ChangeLoadWeeklySalesReportState,
} from "report/report.store";


export default function loadPeriodicalSalesReportAction(since: Date, till: Date, period: 'day' | 'week' | 'month'): ApiAction {
  return async (dispatch, store, api) => {
    const _dispatch = (state: ApiCallState<PeriodicalSalesReport>) => {
      if (period === 'day')
        dispatch(ChangeLoadDailySalesReportState(state));
      else if (period === 'week')
        dispatch(ChangeLoadWeeklySalesReportState(state));
      else
        dispatch(ChangeLoadMonthlySalesReportState(state));
    }

    _dispatch(loading());
    try {
      const { value } = await api.get(`/reports/sales-periodical`, {
        params: {
          since: moment.utc(since).startOf('day').toISOString(),
          till: moment.utc(till).endOf('day').toISOString(),
          period,
        },
        headers: {
          'Utc-Time': moment.utc().toISOString(),
        }
      });
      _dispatch(success(value));
    } catch (error) {
      _dispatch(failed(error));
    }
  };
}
