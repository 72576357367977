/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { SyncProducts } from "product/actions/sync-products.action";
import { Product } from "product/product.entities";
import { isEmpty, ApiSyncController, useApiState } from "common/utils";


var _syncIsRunning = false;

export function useSyncProducts<T extends Product = Product>(opts: {
  auto?: boolean,
  find?: (value: Product, index: number, array: Product[]) => boolean,
  filter?: (value: Product, index: number, array: Product[]) => boolean,
} = {}) {
  const [syncProductsState, dispatch] = useApiState((state) => state.product.sync);

  const syncProducts = useCallback(() => {
    const unsub = new ApiSyncController();
    dispatch(SyncProducts(unsub));
    _syncIsRunning = true;
    return () => {
      _syncIsRunning = false;
      unsub.cancel();
    }
  }, []);

  useEffect(() => {
    if (_syncIsRunning === false && opts.auto !== false && isEmpty(syncProductsState))
      syncProducts();
  }, [opts.auto, syncProductsState])

  const products = useMemo(() =>
    ((opts.filter == null
      ? syncProductsState.value
      : syncProductsState.value?.filter(opts.filter)) || []) as T[],
    [syncProductsState, opts.filter]);

  const product = useMemo(() =>
    opts.find == null
      ? null
      : syncProductsState.value?.find(opts.find) as T,
    [syncProductsState, opts.find]);

  return { syncProductsState, syncProducts, products, product };
}