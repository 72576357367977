/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { SyncBranches } from "branch/actions/sync-branches.action";
import { Branch } from "branch/branch.entities";
import { isEmpty, ApiSyncController, useApiState } from "common/utils";


export function useSyncBranches<T extends Branch = Branch>(opts: {
  auto?: boolean,
  find?: (value: Branch, index: number, array: Branch[]) => boolean,
  filter?: (value: Branch, index: number, array: Branch[]) => boolean,
} = {}, deps: any[] = []) {
  const [syncBranchesState, dispatch] = useApiState((state) => state.branch.sync);

  const syncBranches = useCallback(() => {
    const unsub = new ApiSyncController();
    dispatch(SyncBranches(unsub));
    return unsub.cancel;
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(syncBranchesState))
      syncBranches();
  }, [opts.auto, syncBranchesState])

  const branches = useMemo(() =>
    ((opts.filter == null
      ? syncBranchesState.value
      : syncBranchesState.value?.filter(opts.filter)) || []) as T[],
    [syncBranchesState, opts.filter, ...deps]);

  const branch = useMemo(() =>
    opts.find == null
      ? null
      : syncBranchesState.value?.find(opts.find) as T,
    [syncBranchesState, opts.find, ...deps]);

  return { syncBranchesState, syncBranches, branches, branch };
}