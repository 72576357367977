/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { CurrentBusinessChanged } from "business/business.store";
import { ApiCallState } from "common/utils/api-state";


export const CommonStore = createSlice({
  name: "common",
  initialState: {
    sendFeedback: {} as ApiCallState<void>,
  },
  reducers: {
    SendFeedbackState: (
      state,
      action: { payload: ApiCallState<void> },
    ) => {
      state.sendFeedback = action.payload;
    },
  },
});

export const {
  SendFeedbackState,
} = CommonStore.actions;

export const { reducer: commonReducer, getInitialState } = CommonStore;

export type CommonState = ReturnType<typeof CommonStore.getInitialState>
