/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import {
	BackAsyncError, Backout, Button, Callout, Grid, GridContentLoader
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { ApiCallStateBuilder } from "common/utils";
import EmployeeCard from "employee/components/EmployeeCard";
import { Employee } from "employee/employee.entities";
import { useSyncEmployees } from "employee/employee.hooks";


export default function EmployeesPage() {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const { syncEmployeesState, employees } = useSyncEmployees({
    filter: (employee) => _.isEmpty(searchQuery) ||
      _.values(employee).join(" ").toLowerCase().includes(searchQuery!),
  });

  return (
    <>
      <PageHeader
        icon={<FiUsers size={20} style={{ marginInlineEnd: 2 }} />}
        title={['staff']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text={["add"]}
          intent="success"
          onClick={() => navigate('new')}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder<Employee[]>
          state={syncEmployeesState}
          onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueEmpty={() => (
            <>
              <Callout intent='primary' style={{ marginBottom: 30 }}>
                {t("Employee.ListPage.welcomeCallout")}
              </Callout>
              <div style={{ marginTop: 150 }}>
                <Backout
                  icon={<FiUsers style={{ marginTop: -3, marginInlineStart: -2 }} />}
                  title={["Employee.ListPage.noEmployeesYet"]}>
                  <div style={{ marginTop: 20 }}>
                    <Button
                      outlined
                      text={["add"]}
                      onClick={() => navigate("/employees/new")} />
                  </div>
                </Backout>
              </div>
            </>
          )}
          onValueNotEmpty={() =>
            <Grid md={3} sm={2} xs={1} gap={20} >
              {employees.map(employee =>
                <EmployeeCard
                  key={employee.id}
                  value={employee}
                  onClick={() => navigate(employee.id!)}
                />
              )}
            </Grid>
          }
        />
      </PageContent>
    </>
  );
}
