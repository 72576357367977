/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Table } from "table/table.entities";
import { ApiAction, failed, loading, success } from "common/utils";
import { SaveTableState } from "../table.store";


export function CreateTable(table: Table): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(SaveTableState(loading()));
    try {
      let data: any, contentType: string;
      if (table.picture?.file) {
        data = new FormData();
        data.append("picture", table.picture.file);
        data.append("data", JSON.stringify(table));
        contentType = 'multipart/form-data';
      } else {
        data = { data: table };
        contentType = 'application/json'
      }
      const { value } = await api.post('/business/tables', {
        headers: {
          'Content-Type': contentType,
        },
        data,
      })
      dispatch(SaveTableState(success(value)));
    } catch (error) {
      dispatch(SaveTableState(failed(error)));
    }
  };
}
