/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { SavePincodeAction } from "auth/actions/pincode/save-pincode.action";
import { useHasEmployeeRole, useIdentity } from "auth/auth.hooks";
import { Logout } from "auth/auth.store";
import { CreateBusinessAction, UpdateBusinessAction } from "business/actions";
import {
	LoadMyBusinessesAction
} from "business/actions/load-my-businesses.action";
import {
	SetCurrentBusinessAction
} from "business/actions/set-current-business.action";
import { Business } from "business/business.entities";
import BusinessForm from "business/components/BusinessForm";
import {
	BackActivityIndicator, BackAsyncError, Backout, Card, CardHeader,
	FormButton, TextInputField
} from "common/components";
import { FormProps } from "common/components/form/Form";
import {
	ApiCallStateBuilder, isEmpty, isSuccessful, useApiErrors, useApiState,
	useFormData, useInitial
} from "common/utils";
import { employeeRoles } from "employee/employee.entities";

import styles from "./styles.module.sass";


export default function ({ layout, roles, ...formProps }: BusinessGuardProps) {
  const identity = useIdentity();
  const initial = useInitial();
  const hasEmployeeRole = useHasEmployeeRole();
  const { formData, formErrors, setFormErrors, onChange, changes } =
    useFormData<Business>({
      category: 'restaurant',
      email: identity?.user?.email || '',
      ...identity?.business,
    }, null, []);

  // save & set current
  const [saveState, dispatch] = useApiState((store) => store.business.save);
  const [loadMyBusinessesState] = useApiState((store) => store.business.loadMyBusinesses);
  const [setCurrentBusinessState] = useApiState((store) => store.business.setCurrentBusiness);

  useApiErrors(saveState, setFormErrors);

  useEffect(() => {
    if (identity != null &&
      identity.business == null &&
      isEmpty(loadMyBusinessesState)) {
      dispatch(LoadMyBusinessesAction());
    }
  }, [identity])

  useEffect(() => {
    if (identity != null &&
      identity.business == null &&
      loadMyBusinessesState.value &&
      loadMyBusinessesState.value.length > 0 &&
      isEmpty(setCurrentBusinessState)) {
      dispatch(SetCurrentBusinessAction(loadMyBusinessesState.value[0]));
    }
  }, [identity, loadMyBusinessesState])

  const saveBusiness = useCallback(() => {
    if (formData.id) {
      dispatch(UpdateBusinessAction(formData.id, changes, true));
    } else {
      dispatch(CreateBusinessAction(formData, true));
    }
  }, [formData])

  // pincode
  const [pincode, setPincode] = useState('');
  const [pincodeErrors, setPincodeErrors] = useState({});
  const [savePincodeState] = useApiState((state) => state.auth.pincode.save);
  useApiErrors(savePincodeState, setPincodeErrors);
  useEffect(() => {
    if (!initial && isSuccessful(savePincodeState)) {
      saveBusiness();
    }
  }, [savePincodeState])

  let child: JSX.Element;
  if (identity?.business && setCurrentBusinessState.isLoading !== true) {
    if (roles?.length && !hasEmployeeRole(...roles)) {
      child = (
        <div className="fullscreenGuard">
          <Backout
            icon="lock"
            title={["accessDenied"]}
            message={["youDontHavePermission"]} />
        </div>
      );
    } else {
      child = <Outlet />
    }
  } else {
    child = (
      <div className='fullscreenGuard' >
        <ApiCallStateBuilder
          state={loadMyBusinessesState}
          onLoading={() =>
            <Backout
              icon={<BackActivityIndicator compact />}
              message={["Business.Guard.loadingBackoutMsg"]} />
          }
          onError={(error) => <BackAsyncError
            error={error}
            onTryAgain={() => dispatch(LoadMyBusinessesAction())}
          />}
          onValueEmpty={() => (
            <Card className={styles.card} elevation={1} >
              <CardHeader
                title={["Business.Guard.header"]}
                right={
                  <FormButton
                    minimal
                    icon='cross'
                    onClick={() => dispatch(Logout())}
                  />
                }
              />
              <div className={styles.container}>
                <BusinessForm
                  className={styles.form}
                  value={formData}
                  errors={formErrors}
                  onChange={onChange}
                  disabledFields={formData.id ? [
                    "category",
                  ] : undefined}
                  {...formProps}>
                  <TextInputField
                    selectAllOnFocus
                    maxLength={6}
                    label={["pincode"]}
                    type='password'
                    value={pincode}
                    error={_.get(pincodeErrors, 'value')}
                    tooltip={['Business.Guard.pincodeTooltip']}
                    onChange={(e) => {
                      setPincode(e.target.value);
                      setPincodeErrors({});
                    }}
                  />
                  <FormButton
                    id="saveBusiness"
                    key={'save_button'}
                    intent='primary'
                    text={['save']}
                    loading={saveState.isLoading}
                    onClick={() => {
                      if (pincode.length) {
                        dispatch(SavePincodeAction({ value: pincode, attemptsLeft: 5 }));
                      } else {
                        saveBusiness();
                      }
                    }}
                  />
                </BusinessForm>
              </div>
            </Card>
          )}
          onValue={(data) =>
            <ApiCallStateBuilder
              state={setCurrentBusinessState}
              onLoading={() =>
                <Backout
                  icon={<BackActivityIndicator compact />}
                  message={[
                    "Business.Guard.loggingInAsBackoutMsg",
                    { business: setCurrentBusinessState.value },
                  ]} />
              }
              onError={(error) => <BackAsyncError
                error={error}
                onTryAgain={() => dispatch(SetCurrentBusinessAction(data[0]))}
              />}
            />
          }
        />
      </div>
    );
  }
  return layout == null ? child : layout(child);
}

export type BusinessGuardProps = Omit<FormProps, "onChange"> & {
  roles?: typeof employeeRoles[number][];
  layout?: (child: JSX.Element | null) => JSX.Element,
}