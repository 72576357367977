/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createSlice } from "@reduxjs/toolkit";

import { ApiCallState } from "common/utils/api-state";
import { Country } from "directory/directory.entities";


export const DirectoryStore = createSlice({
  name: "directory",
  initialState: {
    getCountries: {} as ApiCallState<Country[]>,
  },
  reducers: {
    GetCountriesState: (state, action: { payload: ApiCallState<Country[]> }) => {
      state.getCountries = action.payload;
    },
  },
});

export const {
  GetCountriesState,
} = DirectoryStore.actions;

export const { reducer: directoryReducer, getInitialState } = DirectoryStore;
export type DirectoryState = ReturnType<typeof DirectoryStore.getInitialState>
