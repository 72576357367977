/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { isEmpty, useApiState } from "common/utils";
import { GetCountriesAction } from "directory/actions/load-countries.action";
import { Country } from "directory/directory.entities";


export function useGetCountries(opts: {
  auto?: boolean,
  find?: (value: Country, index: number, array: Country[]) => boolean,
  filter?: (value: Country, index: number, array: Country[]) => boolean,
} = {}, deps: any[] = []) {
  const [getCountriesState, dispatch] = useApiState((state) => state.dictionary.getCountries);

  const getCountries = useCallback(() => dispatch(GetCountriesAction()), []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(getCountriesState))
      getCountries();
  }, [opts.auto, getCountriesState])

  const countries = useMemo(() =>
  ((opts.filter == null
    ? getCountriesState.value
    : getCountriesState.value?.filter(opts.filter)) || []),
    [getCountriesState, opts.filter, ...deps]);

  const country = useMemo(() =>
    opts.find == null ? null : getCountriesState.value?.find(opts.find),
    [getCountriesState, opts.find, ...deps]);

  return { getCountriesState, getCountries, countries, country };
}