/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { useMemo } from "react";

import { OnChangeEventHandler } from "common/utils";
import Column, { ColumnProps } from "../../flex/Column";
import styles from "./styles.module.sass";


export default function Form({
  className, children, ...rest }: FormProps) {
  const classes = useMemo(() => {
    const cls = [styles.form, "form"];
    if (className) cls.push(className)
    return cls.join(" ").trim();
  }, [className]);

  return (
    <Column className={classes}
      {..._.omit(rest, [
        "name",
        "visibleFields",
        "hiddenFields",
        "readOnlyFields",
        "value",
        "errors",
        "readOnly",
        "onChange",
      ])}>
      {children}
    </Column>
  );
}

type Join<K, P> = K extends string | number ?
  P extends string | number ?
  `${K}${"" extends P ? "" : "."}${P}`
  : never : never;
type Prev = [never, 0, 1, 2, 3, 4, ...0[]];
type Paths<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
  { [K in keyof T]-?: K extends string | number ?
    `${K}` | Join<K, Paths<T[K], Prev[D]>>
    : never
  }[keyof T] : ""

export type FormProps = Omit<ColumnProps, "title" | "onChange"> & {
}

/**
 * @deprecated use EntityForm with its props
 */
export type EntityFormProps<T = any> = FormProps & {
  name?: string,
  visibleFields?: Array<Paths<T>>,
  hiddenFields?: Array<Paths<T>>,
  readOnlyFields?: Array<Paths<T>>,
  disabledFields?: Array<Paths<T>>,
  value?: T,
  errors?: { [K in keyof T]: any },
  readOnly?: boolean,
  onChange: OnChangeEventHandler<any>,
}