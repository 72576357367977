/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { PageNotFound } from "common/components/back";


export default function Page404() {
  return (
    <PageNotFound />
  );
}
