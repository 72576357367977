/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction, failed, loading, success } from "common/utils";

import { LoginState } from "auth/auth.store";
import { Business } from "business/business.entities";
import { CurrentBusinessChanged, UpdateSetCurrentBusinessState } from "../business.store";


export function SetCurrentBusinessAction(business: Business): ApiAction {
  return async (dispatch, store, api) => {
    dispatch(UpdateSetCurrentBusinessState(loading(business)));
    try {
      const { value } = await api.post(`/business/${business.id}`)
      dispatch(UpdateSetCurrentBusinessState(success(value)));
      // update login state
      const identity = store().auth.login.value!;
      dispatch(LoginState(success({ ...identity, business: value })));
      dispatch(CurrentBusinessChanged());
    } catch (error) {
      dispatch(UpdateSetCurrentBusinessState(failed(error)));
    }
  };
}
