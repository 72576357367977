/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useTranslation } from "react-i18next";
import { AiOutlineBank } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import {
	BackAsyncError, Backout, Button, Callout, Grid, GridContentLoader
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { ApiCallStateBuilder } from "common/utils";
import MerchantAccountCard from "merchant/common/components/MerchantAccountCard";
import { MerchantAccount } from "merchant/merchant.entities";
import { useSyncMerchantAccounts } from "merchant/merchant.hooks";


export default function MerchantAccountsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { syncMerchantAccountsState, merchants } = useSyncMerchantAccounts();

  return (
    <>
      <PageHeader
        icon={<AiOutlineBank size={20} style={{ marginInlineEnd: 2 }} />}
        title={['Merchant.ListPage.header']} >
        <Button
          text={[merchants.find(m => m.type === 'stripe')
            ? "create" : "Merchant.ListPage.createStripeButton"]}
          intent="primary"
          onClick={() => navigate('stripe/new')}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder<MerchantAccount[]>
          state={syncMerchantAccountsState}
          onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueEmpty={() => (
            <div style={{ marginTop: 150 }}>
              <Backout
                icon={<AiOutlineBank style={{ marginTop: -3, marginInlineStart: -2 }} />}
                title={["Merchant.ListPage.noMerchantsYet"]}>
                <div style={{ marginTop: 20 }}>
                  <Button
                    outlined
                    text={["create"]}
                    onClick={() => navigate("/merchants/stripe/new")} />
                </div>
              </Backout>
            </div>
          )}
          onValueNotEmpty={() => (
            <>
              {merchants.find(m => m.type !== 'basic') ? null : (
                <Callout intent='primary' style={{ marginBottom: 30 }}>
                  {t("Merchant.ListPage.welcomeCallout")}
                </Callout>
              )}
              <Grid md={3} sm={2} xs={1} gap={20}>
                {merchants.map(merchant => <MerchantAccountCard
                  key={merchant.id}
                  value={merchant}
                  onClick={() => navigate(merchant.type + '/' + merchant.id!)} />
                )}
              </Grid>
            </>
          )}
        />
      </PageContent>
    </>
  );
}
