/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { Popover2 } from "@blueprintjs/popover2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsStripe } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useIdentity } from "auth/auth.hooks";
import {
	Alert, BackActivityIndicator, BackAsyncError, Button, Callout, Card,
	CardContent, CardHeader, Grid, Menu, MenuItem, PageContent, PageHeader,
	PageNotFound, Toast
} from "common/components";
import {
	ApiCallStateBuilder, isLoading, isSuccessful, useApiErrors, useApiState,
	useFormData, useInitial
} from "common/utils";
import {
	CreateMerchantAccount
} from "merchant/common/actions/create-merchant-account.action";
import {
	DeleteMerchantAccount
} from "merchant/common/actions/delete-merchant-account.action";
import {
	UpdateMerchantAccount
} from "merchant/common/actions/update-merchant-account.action";
import MerchantAccountForm from "merchant/common/components/MerchantAccountForm";
import { StripeMerchantAccount } from "merchant/merchant.entities";
import { useSyncMerchantAccounts } from "merchant/merchant.hooks";


export default function StipeMerchantAccountPage() {
  const initial = useInitial();
  const navigate = useNavigate();
  const identity = useIdentity();
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();

  // store
  const { syncMerchantAccountsState, merchant } = useSyncMerchantAccounts<StripeMerchantAccount>({
    find: merchant => merchant.id === id,
  });
  const [saveState, dispatch] = useApiState((store) => store.merchant.save);
  const [deleteState] = useApiState((store) => store.merchant.delete);

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<StripeMerchantAccount>(merchant, {
      type: 'stripe',
      name: 'Stripe',
      displayName: identity?.business?.name,
      uponArrivalPayments: true,
      atCounterPayments: false,
      cardPayments: true,
      applePay: true,
      googlePay: true,
      afterOnboardingRedirectUrl: window.location.href.replace('/new', '/${id}'),
    }, [merchant]);
  useApiErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        if (id === "new") {
          const acc = saveState.value as StripeMerchantAccount;
          if (acc.status === 'onboarding')
            window.location.replace(acc.stripeOnboardingLink!);
          else
            navigate(pathname.replace("/new", `/${saveState.value!.id}`), { replace: true });
        }
      }
    }
  }, [saveState]);
  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({ message: ["Merchant.ItemPage.deletedToast"] });
        navigate('/merchants');
      } else if (deleteState.error) {
        if (deleteState.error.code === 'in-use') {
          confirmDeletion(false);
          Toast.showDanger({
            message: ["Merchant.ItemPage.cannotDeleteInUseAccount", { entity: deleteState.error['branch'] }],
          })
        } else {
          Toast.showApiError(deleteState.error);
        }
      }
    }
  }, [deleteState]);

  return (
    <>
      <PageHeader
        icon={<BsStripe size={20} style={{ marginInlineEnd: 5 }} />}
        title={
          id === 'new'
            ? ["Merchant.StripeItemPage.newHeader"]
            : (merchant?.name + (merchant?.status === 'onboarding'
              ? t("Merchant.StripeItemPage.onboaringHeaderSuffix") : ''))
        }
        backButtonPath={state?.backpath || "/merchants"}>
        <Button
          text={["continue"]}
          intent="primary"
          onClick={() => dispatch(CreateMerchantAccount(formData))}
          hidden={id !== 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text={["Merchant.StripeItemPage.completeOnboardingButton"]}
          intent="primary"
          onClick={() => window.location.replace(merchant!.stripeOnboardingLink!)}
          hidden={merchant?.status !== 'onboarding'}
          loading={isLoading(saveState)}
        />
        <Button
          text={["save"]}
          intent="success"
          onClick={() => dispatch(UpdateMerchantAccount(merchant!, changes))}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="trash"
                text={["delete"]}
                intent='danger'
                hidden={merchant == null}
                disabled={merchant == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button
            minimal
            rightIcon="menu"
            hidden={merchant == null}
          />
        </Popover2>
      </PageHeader>
      <PageContent>
        {id === 'new' && (
          <Callout intent='primary' style={{ marginBottom: 30 }}>
            <>
              {t("Merchant.StripeItemPage.calloutBeforeLinkText")}
              <a href='https://stripe.com' target='_blan'>Stripe</a>
              {t("Merchant.StripeItemPage.calloutAfterLinkText")}
            </>
          </Callout>
        )}
        <ApiCallStateBuilder state={syncMerchantAccountsState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValue={() => {
            if (!merchant && id !== "new")
              return <PageNotFound />
            return (
              <Grid md={2} xs={1} gap={20}>
                <Card>
                  <CardHeader title={["general"]} />
                  <CardContent>
                    <MerchantAccountForm
                      value={formData}
                      errors={formErrors}
                      readOnly={isLoading(saveState) || merchant?.status === 'onboarding'}
                      onChange={onChange}
                      hiddenFields={id === 'new' ? ['currency'] : undefined}
                      readOnlyFields={id !== 'new' ? ['currency'] : []}
                    />
                  </CardContent>
                </Card>
              </Grid>
            );
          }}
        />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText={['delete']}
          cancelButtonText={['nope']}
          icon="trash"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteMerchantAccount(id!))}>
          <p>{t("Merchant.StripeItemPage.deleteDialogBody")}</p>
        </Alert>
      </PageContent>
    </>
  );
}
