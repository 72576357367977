/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Category } from "category/category.entities";
import { ApiAction, failed, loading, success } from "common/utils";
import { SaveCategoryState } from "../category.store";


export function CreateCategory(category: Category): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(SaveCategoryState(loading()));
    try {
      const { value } = await api.post('/catalog/categories', {
        data: category,
      })
      dispatch(SaveCategoryState(success(value)));
    } catch (error) {
      dispatch(SaveCategoryState(failed(error)));
    }
  };
}
