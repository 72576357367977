/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Icon } from "@blueprintjs/core";
import { HTMLAttributes, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useIdentity } from "auth/auth.hooks";
import { Button } from "common/components";
import { useApiState } from "common/utils";
import {
	GetCustomerPortalLink
} from "subscription/actions/get-customer-portal-link.action";

import styles from "./styles.module.scss";


export default function ({
  className, portalLinkUrl, buttonText, ...rest
}: ManageSubscriptionProps) {
  const { t } = useTranslation();
  const identity = useIdentity();
  const classes = useMemo(() => [styles.manageSubscription, className].join(" "), [className]);

  const [getCustomerPortalLinkState, dispatch] = useApiState((store) => store.subscription.getCustomerPortalLink);

  const getCustomerPortalLink = useCallback(() => {
    dispatch(GetCustomerPortalLink(window.location.href))
  }, []);

  useMemo(() => {
    if (portalLinkUrl == null)
      getCustomerPortalLink();
  }, [identity?.subscription, portalLinkUrl])

  return identity?.subscription == null ? null : (
    <div className={classes} {...rest}>
      <img
        className="logo"
        src={`/images/logo512.png`}
        alt="logo"
      />
      <h2>{t(`Subscription.Names.${identity.subscription.plan}`)}</h2>
      <span>
        {t(`Subscription.Descriptions.${identity.subscription.plan}`)}
      </span>
      {/* <p>This includes:</p>
      <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        Curbside Pickup Technology
      </p>
      <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        Geofence based notifications and alerts
      </p>
      <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        Users place orders via mobile apps or any mobile browser
      </p>
      <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        Provide Online Payments and Pay-Upon-Arrival options
      </p>
      <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        Allows for custom Orders
      </p>
      <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        Integrates with phone orders
      </p> */}
      {/* <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        {t("Subscription.TotalOrders", { count: identity.subscription.quota.totalOrders })}
      </p>
      {/* <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        {t("Subscription.Tiers.UpToOrders", { count: 40, price: 29, currency: 'A$' })}
      </p>
      <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        {t("Subscription.Tiers.UpToOrders", { count: 100, price: 69, currency: 'A$' })}
      </p>
      <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        {t("Subscription.Tiers.UpToOrders", { count: 200, price: 109, currency: 'A$' })}
      </p>
      <p>
        <Icon icon="tick-circle" color='grey' size={12} />
        {t("Subscription.Tiers.UpToOrders", { count: 0, price: 149, currency: 'A$' })}
      </p> */}
      <Button
        fill
        intent='warning'
        text={buttonText || ["Subscription.ManageForm.manageBtn"]}
        disabled={!portalLinkUrl && !getCustomerPortalLinkState.value}
        loading={getCustomerPortalLinkState.isLoading}
        onClick={() => window.open(portalLinkUrl || getCustomerPortalLinkState.value!, "_self")}
      />
    </div>
  );
}

export type ManageSubscriptionProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  portalLinkUrl?: string;
  buttonText?: string;
};