/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction, failed, loading, success } from "common/utils";
import { GetCustomerPortalLinkState } from "subscription/subscription.store";


export function GetCustomerPortalLink(returnUrl?: string): ApiAction {
  return async (dispatch, _getStore, api) => {
    dispatch(GetCustomerPortalLinkState(loading()));
    try {
      const { value } = await api.get('/pricing/subscription/stripe/customer-portal-link', {
        params: { returnUrl }
      })
      dispatch(GetCustomerPortalLinkState(success(value)));
    } catch (error) {
      dispatch(GetCustomerPortalLinkState(failed(error)));
    }
  };
}
