/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createSlice } from "@reduxjs/toolkit";

import { ApiCallState } from "common/utils/api-state";

import { ExImage } from "./media.entities";


export const MediaStore = createSlice({
  name: "media",
  initialState: {
    uploadExImage: {} as ApiCallState<ExImage>,
    updateExImage: {} as ApiCallState<ExImage>,
  },
  reducers: {
    UpdateUploadExImageState: (state, action) => {
      state.uploadExImage = action.payload;
    },
    NextUpdateExImageState: (state, action) => {
      state.uploadExImage = action.payload;
    },
  },
});

export const {
  UpdateUploadExImageState,
  NextUpdateExImageState,
} = MediaStore.actions;

export type MediaState = ReturnType<typeof MediaStore.getInitialState>

export const { reducer: mediaReducer } = MediaStore;