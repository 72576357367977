/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { UpdateSendOtpState } from "auth/auth.store";
import { ExtPhone } from "common/common.entities";
import { ApiAction, failed, loading, success } from "common/utils";


export function SendOtpAction(
  destination: ExtPhone | string,
  locale?: string,
  checkNotTaken?: boolean,
): ApiAction {
  return async (dispatch, getState, api) => {
    dispatch(UpdateSendOtpState(loading()));
    try {
      const { value } = await api.post("/auth/otp", {
        data: {
          phone: destination['code'] ? destination : undefined,
          email: destination['code'] ? undefined : destination,
          locale,
          apiKey: process.env.REACT_APP_API_KEY,
          checkNotTaken,
        },
      });
      dispatch(UpdateSendOtpState(success(value)));
    } catch (error) {
      dispatch(UpdateSendOtpState(failed(error)));
    }
  };
}