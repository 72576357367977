/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from "react";

import styles from "./styles.module.sass";


export default function FormRow({
  className, children, ...rest
}: FormRowProps) {
  // consts
  const classes = [styles.formrow, className].join(" ");

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
}

export type FormRowProps = React.ComponentPropsWithRef<"div"> & {

}