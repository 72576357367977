/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { InfoTooltip } from "common/components/tooltips";

import styles from "./styles.module.sass";


export default function FormLabel({
  className, text, tooltip, actions, children, ...rest
}: FormLabelProps) {
  const { t } = useTranslation();
  const classes = [styles.formLabel, className];
  const labelText = useMemo(() =>
    _.isArray(text) ? t(text[0], text[2], text[1]) : text,
    [text]);
  const tooltipText = useMemo(() =>
    _.isArray(tooltip) ? t(tooltip[0], tooltip[2], tooltip[1]) : _.isString(tooltip) ? tooltip : null,
    [tooltip])

  return (
    <>
      <div className={classes.join(' ')} {...rest}>
        <span className="text">
          {labelText}
        </span>
        {tooltipText && (
          <InfoTooltip content={tooltipText} position="top-right" />
        )}
        {actions}
      </div>
      {children}
    </>
  );
}

export type FormLabelProps = React.ComponentPropsWithRef<"div"> & {
  text?: string | [string, object?, string?],
  tooltip?: string | [string, object?, string?],
  actions?: React.ReactNode,
}
