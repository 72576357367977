/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ExtPhone } from "common/common.entities";
import { Employee } from 'employee/employee.entities';

export const businessCategories = [
  'restaurant',
  'grocery',
  'pharmacy',
  'butcher',
  'bakery',
  'florist',
  'autoParts',
  'other'
] as const;

export const businessStatuses = [
  'restricted', // newly created businesses until validated (phone, email, etc)
  'active',
  'suspended',
] as const;

export interface Business {
  id: string;
  status?: typeof businessStatuses[number];
  category: typeof businessCategories[number];
  country: string;
  name: string;
  description?: string;
  email: string; // send invoices to
  phone?: ExtPhone;
  employee?: Employee;
}