/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction, failed, loading, success } from "common/utils";

import { Logout } from "auth/auth.store";
import { UpdateDeleteBusinessState, UpdateLoadMyBusinessesState } from "../business.store";


export function DeleteBusiness(id: string): ApiAction {
  return async (dispatch, store, api) => {
    dispatch(UpdateDeleteBusinessState(loading()));
    try {
      const { value, identity } = await api.delete(`/businesses/${id}`)
      dispatch(UpdateDeleteBusinessState(success(value)));
      if (identity?.business)
        dispatch(UpdateLoadMyBusinessesState({}));
      else
        dispatch(Logout());
    } catch (error) {
      dispatch(UpdateDeleteBusinessState(failed(error)));
    }
  };
}
