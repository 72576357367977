/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from 'moment';

import { DateRangeInput, DateRangeInputProps } from "@blueprintjs/datetime";
import { useTranslation } from 'react-i18next';

import styles from "./styles.module.sass";


export default function ({
  className, ...rest
}: TagProps) {
  const { t, i18n } = useTranslation();
  const classes = [styles.tag, className];
  return (
    <DateRangeInput
      className={classes.join(" ")}
      locale={i18n.language}
      localeUtils={{
        formatDate(date: Date, format?: string | string[], locale?: string): string {
          return moment(date).format('dd-MMM-yyyy hh:mm a');
        },
        formatDay(day: Date, locale?: string): string {
          return moment(day).format('DD');
        },
        formatMonthTitle(month: Date, locale?: string): string {
          return moment(month).format('MMM');
        },
        formatWeekdayLong(weekday: number, locale?: string): string {
          const keys = [
            "Weekdays.mon",
            "Weekdays.tue",
            "Weekdays.wed",
            "Weekdays.thu",
            "Weekdays.fri",
            "Weekdays.sat",
            "Weekdays.sun",
          ];
          return t(keys[weekday]);
        },
        formatWeekdayShort(weekday: number, locale?: string): string {
          const keys = [
            "WeekdaysShort.mon",
            "WeekdaysShort.tue",
            "WeekdaysShort.wed",
            "WeekdaysShort.thu",
            "WeekdaysShort.fri",
            "WeekdaysShort.sat",
            "WeekdaysShort.sun",
          ]
          return t(keys[weekday]);
        },
        getFirstDayOfWeek(): number {
          return 0;
        },
        getMonths() {
          return [
            t("Months.jan"),
            t("Months.feb"),
            t("Months.mar"),
            t("Months.apr"),
            t("Months.may"),
            t("Months.jun"),
            t("Months.jul"),
            t("Months.aug"),
            t("Months.sep"),
            t("Months.oct"),
            t("Months.nov"),
            t("Months.dec"),
          ]
        },
        parseDate(str: string, format?: string, locale?: string): Date {
          return moment(str, format).toDate();
        }
      }}
      shortcuts={false}
      {...rest} />
  );
}

export type TagProps = DateRangeInputProps & {
}