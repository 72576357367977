/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useMemo } from "react";

import { ClipboardButton, InfoTooltip } from "common/components";
import {
	Form, FormRow, NumericField, TextInputField
} from "common/components/form";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { GeoAddress } from "geo/geo.entities";

import styles from "./styles.module.scss";


export default function ({
  className, readOnly, value, errors, onChange,
  children, displayAsTooltip, ...rest
}: AddressFormProps) {
  const classes = useMemo(() => [styles.addressForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  return (
    <Form className={classes} {...rest}>
      <TextInputField
        maxLength={100}
        name={fieldName("displayAs")}
        label={["displayAs"]}
        value={value?.displayAs}
        error={_.isArray(errors) ? errors : errors?.displayAs}
        readOnly={readOnly}
        hidden={isHidden("displayAs")}
        endElement={<ClipboardButton minimal textToCopy={value?.displayAs} />}
        onChange={onChange}
        actions={displayAsTooltip && (
          <InfoTooltip
            content={displayAsTooltip}
            position="top-right"
            iconProps={{ style: { marginInlineStart: 4, marginBottom: 1.2 } }} />
        )}
      />
      <FormRow hidden={isHidden("point")} >
        <NumericField
          key='latitude'
          name={fieldName("latitude")}
          label={["latitude"]}
          value={value?.latitude}
          error={errors?.latitude}
          readOnly={readOnly}
          onChange={onChange}
          stepSize={0.1}
        />
        <NumericField
          key='longitude'
          name={fieldName("longitude")}
          label={["longitude"]}
          value={value?.longitude}
          error={errors?.longitude}
          readOnly={readOnly}
          onChange={onChange}
          stepSize={0.1}
        />
      </FormRow>
    </Form>
  );
}

export type AddressFormProps = EntityFormProps<GeoAddress> & {
  displayAsTooltip?: string;
}