/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { BackActivityIndicator, Backout } from "common/components";
import { GeoPoint } from "geo/geo.entities";
import styles from './styles.module.scss';


export default function GoogleMap({
  className, options, onInit, children, ...rest
}: GoogleMapProps) {
  const classNames = useMemo(() => [styles.googleMap, className].join(" "), [className]);
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const initMap = useCallback(() => {
    if (ref.current) {
      const map = new window.google.maps.Map(ref.current!);
      setMap(map);
      if (onInit)
        onInit(map as GoogleMapInstance);
    } else {
      setTimeout(initMap, 100);
    }
  }, [ref]);

  useLayoutEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    if (map && options)
      map.setOptions(options);
    map?.addListener
  }, [map, options]);

  const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <Backout icon='error' message={'Failed to load Google Maps'} />;
    return <BackActivityIndicator />;
  };

  return (
    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY!} render={render}>
      <div ref={ref} className={classNames} id="map" {...rest}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            // @ts-ignore
            return React.cloneElement(child, { map });
          }
        })}
      </div>
    </Wrapper>
  )
}

export type GoogleMapInstance = google.maps.Map;

export type GoogleMapProps = React.ComponentPropsWithRef<"div"> & {
  className?: string;
  options?: google.maps.MapOptions,
  onInit?: (map: GoogleMapInstance) => void,
}

export const GoogleMapMarker: React.FC<GoogleMapMarkerOptions> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [onDragEndListener, setOnDragEndListener] = useState<google.maps.MapsEventListener>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
        onDragEndListener?.remove();
      }
    };
  }, [marker, options.key]);

  useEffect(() => {
    if (marker && options?.onDragEnd) {
      if (onDragEndListener)
        onDragEndListener.remove();
      setOnDragEndListener(marker.addListener('dragend', () => {
        const position = marker.getPosition();
        if (position)
          options?.onDragEnd!({
            latitude: position.lat(),
            longitude: position.lng(),
          });
      }));
    }
  }, [marker, options.onDragEnd]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export type GoogleMapMarkerOptions = google.maps.MarkerOptions & {
  key?: any;
  onDragEnd?: (point: GeoPoint) => void;
}