/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from 'moment';

import { useCallback, useMemo } from "react";

import { CatalogSchedule, CatalogScheduleDay } from "branch/branch.entities";
import { Form, TimeRangeField } from "common/components/form";
import { EntityFormProps } from 'common/components/form/Form';
import { OnChangeEvent, useFieldName, useIsFieldHidden } from "common/utils";
import styles from "./styles.module.sass";


export default function ({
  className, readOnly, value, errors, onChange, children, ...rest
}: BranchCatalogScheduleFormProps) {
  const classes = useMemo(() => [styles.branchCatalogScheduleField, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  const dayToDates = useCallback((day?: CatalogScheduleDay) => {
    const values: [Date?, Date?] = [];
    if (day?.since)
      values[0] = moment(day.since, 'HH:mm').toDate()
    if (day?.till)
      values[1] = moment(day.till, 'HH:mm').toDate()
    return values;
  }, [])

  const onDatesChange = useCallback((event: OnChangeEvent<[Date | null, Date | null]>) => {
    if (onChange) {
      const values = event.target.value;
      const since = values[0] == null ? null : moment(values[0]).format('HH:mm');
      const till = values[1] == null ? null : moment(values[1]).format('HH:mm');
      onChange({
        target: {
          name: event.target.name,
          value: since == null && till == null ? null : { since, till }
        }
      })
    }
  }, [onChange])

  return (
    <Form {...rest}>
      <TimeRangeField
        nullable
        className={classes}
        name={fieldName("mon")}
        label={["Weekdays.mon"]}
        value={dayToDates(value?.mon)}
        errors={[errors?.mon?.since, errors?.mon?.till]}
        readOnly={readOnly}
        hidden={isHidden("mon")}
        onChange={onDatesChange}
      />
      <TimeRangeField
        nullable
        className={classes}
        name={fieldName("tue")}
        label={["Weekdays.tue"]}
        value={dayToDates(value?.tue)}
        errors={[errors?.tue?.since, errors?.tue?.till]}
        readOnly={readOnly}
        hidden={isHidden("tue")}
        onChange={onDatesChange}
      />
      <TimeRangeField
        nullable
        className={classes}
        name={fieldName("wed")}
        label={["Weekdays.wed"]}
        value={dayToDates(value?.wed)}
        errors={[errors?.wed?.since, errors?.wed?.till]}
        readOnly={readOnly}
        hidden={isHidden("wed")}
        onChange={onDatesChange}
      />
      <TimeRangeField
        nullable
        className={classes}
        name={fieldName("thu")}
        label={["Weekdays.thu"]}
        value={dayToDates(value?.thu)}
        errors={[errors?.thu?.since, errors?.thu?.till]}
        readOnly={readOnly}
        hidden={isHidden("thu")}
        onChange={onDatesChange}
      />
      <TimeRangeField
        nullable
        className={classes}
        name={fieldName("fri")}
        label={["Weekdays.fri"]}
        value={dayToDates(value?.fri)}
        errors={[errors?.fri?.since, errors?.fri?.till]}
        readOnly={readOnly}
        hidden={isHidden("fri")}
        onChange={onDatesChange}
      />
      <TimeRangeField
        nullable
        className={classes}
        name={fieldName("sat")}
        label={["Weekdays.sat"]}
        value={dayToDates(value?.sat)}
        errors={[errors?.sat?.since, errors?.sat?.till]}
        readOnly={readOnly}
        hidden={isHidden("sat")}
        onChange={onDatesChange}
      />
      <TimeRangeField
        nullable
        className={classes}
        name={fieldName("sun")}
        label={["Weekdays.sun"]}
        value={dayToDates(value?.sun)}
        errors={[errors?.sun?.since, errors?.sun?.till]}
        readOnly={readOnly}
        hidden={isHidden("sun")}
        onChange={onDatesChange}
      />
      {children}
    </Form>
  );
}

export type BranchCatalogScheduleFormProps = EntityFormProps<CatalogSchedule> & {
  // className?: string,
  // name: string,
  // value?: CatalogSchedule,
  // errors?: { [K in keyof CatalogSchedule]: any },
  // readOnly?: boolean,
  // onChange?: OnChangeEventHandler<CatalogScheduleDay>,
  // nullable?: boolean,
};