/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiCategory } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import { Category } from "category/category.entities";
import { useSyncCategories } from "category/category.hooks";
import CategoryCard from "category/components/CategoryCard";
import {
	BackAsyncError, Backout, Button, Callout, Grid, GridContentLoader
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { ApiCallStateBuilder } from "common/utils";


export default function CategoriesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const { syncCategoriesState, categories } = useSyncCategories({
    filter: (category) =>
      _.isEmpty(searchQuery) ||
      _.values(category).join(" ").toLowerCase().includes(searchQuery!),
  });

  return (
    <>
      <PageHeader
        icon={<BiCategory size={23} style={{ marginInlineEnd: 1, marginTop: -1 }} />}
        title={['categories']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text={["create"]}
          intent="success"
          onClick={() => navigate('new')}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder<Category[]>
          state={syncCategoriesState}
          onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueEmpty={() => (
            <>
              <Callout intent='primary' style={{ marginBottom: 30 }}>
                {t("Category.ListPage.welcomeCallout")}
              </Callout>
              <div style={{ marginTop: 150 }}>
                <Backout
                  icon={
                    <BiCategory style={{ marginTop: -3, marginInlineStart: -2 }} />
                  }
                  title={["Category.ListPage.emptyBackoutTitle"]}>
                  <div style={{ marginTop: 20 }}>
                    <Button
                      outlined
                      text={["create"]}
                      onClick={() => navigate("/categories/new")} />
                  </div>
                </Backout>
              </div>
            </>
          )}
          onValueNotEmpty={() =>
            <Grid md={3} sm={2} xs={1} gap={20} >
              {categories.map(category =>
                <CategoryCard
                  key={category.id}
                  value={category}
                  onClick={() => navigate(category.id!)}
                />
              )}
            </Grid>
          }
        />
      </PageContent>
    </>
  );
}
