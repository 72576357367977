/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useMemo } from 'react';
import { BiStore } from 'react-icons/bi';

import { Column } from "common/components";
import Card, { CardProps } from "common/components/cards/Card";
import { Branch } from "branch/branch.entities";
import styles from "./styles.module.scss";


export default function BranchCard({
  className, value, onClick, children, ...rest
}: BranchCardProps) {
  const classes = useMemo(() => [styles.branchCard, className].join(" "), [className]);

  return (
    <Card
      interactive={onClick != null}
      className={classes}
      onClick={onClick}
      {...rest}>
      <div className={styles.branchIcon}><BiStore /></div>
      {value.image?.small &&
        <img 
          className={styles.branchImage} 
          src={value.image.small.url}
          data-online={value.online}
          onError={(event: any) => event.target.style.display = 'none'} />}
      <Column fill>
        <h4>{value.name}</h4>
        {children}
      </Column>
    </Card>
  );
}

export type BranchCardProps = CardProps & {
  value: Branch,
};