/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from "moment";

import {
	Dialog, DialogBody, DialogFooter, ResizeSensor
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineBugReport } from "react-icons/md";

import { UpdateSessionLocaleAction } from "auth/actions/update-session-locale";
import { useIdentity } from "auth/auth.hooks";
import { sendFeedback } from "common/actions/send-feedback.action";
import { SendFeedbackState } from "common/common.store";
import { Button, Toast } from "common/components";
import TextArea from "common/components/form/TextArea";
import ChecklistForm from "common/containers/Checklist";
import { isSuccessful, useApiErrors, useApiState } from "common/utils";
import { useSyncOrders } from "order/order.hooks";

import Sidebar from "../../containers/Sidebar";
import Topbar from "../../containers/Topbar";
import styles from "./styles.module.sass";


const dispayMenuAsPopupWidth = 1000;

export default function WorkspaceLayout({
  className, children, ...rest
}: WorkspaceLayoutProps) {
  const { t, i18n } = useTranslation();
  const classes = [styles.workspace, className];

  // state
  const [sidebarAsPopup, showSidebarAsPopup] =
    useState(window.innerWidth < dispayMenuAsPopupWidth);
  const [rerenders, rerender] = useState(0);

  const identity = useIdentity()
  const [isChecklistDialogOpenOnStartup, openChecklistDialogOnStartup] =
    useState(localStorage.getItem(`dontDisplayChecklistOnStartup_${identity!.user.id}`) == null);
  const [isChecklistDialogOpen, openChecklistDialog] = useState(false);

  useEffect(() => {
    if (isChecklistDialogOpenOnStartup)
      localStorage.removeItem(`dontDisplayChecklistOnStartup_${identity!.user.id}`);
    else
      localStorage.setItem(`dontDisplayChecklistOnStartup_${identity!.user.id}`, '1');
  }, [isChecklistDialogOpenOnStartup])

  useEffect(() => {
    if (isChecklistDialogOpenOnStartup && identity?.business && !identity.business!.employee) {
      openChecklistDialog(true);
    }
  }, [identity?.business])

  useSyncOrders();

  // locale
  useEffect(() => {
    if (i18n.language == 'ar') {
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl')
    }
    rerender(rerenders + 1);
    if (identity && i18n.language) {
      dispatch(UpdateSessionLocaleAction(i18n.language));
    }
  }, [identity, i18n.language])
  useEffect(() => {
    moment.defineLocale('ar', {
      weekdays: [
        t("Weekdays.mon"),
        t("Weekdays.tue"),
        t("Weekdays.wed"),
        t("Weekdays.thu"),
        t("Weekdays.fri"),
        t("Weekdays.sat"),
        t("Weekdays.sun"),
      ],
      weekdaysShort: [
        t("WeekdaysShort.mon"),
        t("WeekdaysShort.tue"),
        t("WeekdaysShort.wed"),
        t("WeekdaysShort.thu"),
        t("WeekdaysShort.fri"),
        t("WeekdaysShort.sat"),
        t("WeekdaysShort.sun"),
      ],
      months: [
        t("Months.jan"),
        t("Months.feb"),
        t("Months.mar"),
        t("Months.apr"),
        t("Months.may"),
        t("Months.jun"),
        t("Months.jul"),
        t("Months.aug"),
        t("Months.sep"),
        t("Months.oct"),
        t("Months.nov"),
        t("Months.dec"),
      ],
      monthsShort: [
        t("MonthsShort.jan"),
        t("MonthsShort.feb"),
        t("MonthsShort.mar"),
        t("MonthsShort.apr"),
        t("MonthsShort.may"),
        t("MonthsShort.jun"),
        t("MonthsShort.jul"),
        t("MonthsShort.aug"),
        t("MonthsShort.sep"),
        t("MonthsShort.oct"),
        t("MonthsShort.nov"),
        t("MonthsShort.dec"),
      ]
    })
  }, []);

  // feedback
  const [isFeedbackDialogShown, showFeedbackDialog] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [sendFeedbackState, dispatch] = useApiState((state) => state.common.sendFeedback);
  useApiErrors(sendFeedbackState);
  useEffect(() => {
    if (isSuccessful(sendFeedbackState)) {
      Toast.showSuccess({ message: ["Sidebar.feedbackSentToast"] });
      dispatch(SendFeedbackState({}));
      setFeedbackText('');
      showFeedbackDialog(false);
    }
  }, [sendFeedbackState])
  const onResize = (entries: ResizeObserverEntry[]) => {
    let displaySidebarAsPopup = false;
    entries.forEach((e) => {
      if (e.contentRect.width < dispayMenuAsPopupWidth) {
        displaySidebarAsPopup = true;
      }
    });
    if (displaySidebarAsPopup !== sidebarAsPopup) {
      showSidebarAsPopup(displaySidebarAsPopup);
    }
  };

  return (
    <ResizeSensor onResize={onResize}>
      <div className={classes.join(" ")} {...rest}>
        <Topbar
          menuButton={!sidebarAsPopup ? undefined :
            <Popover2
              placement="bottom-end"
              interactionKind="click"
              content={
                <Sidebar
                  onOpenChecklist={() => openChecklistDialog(true)}
                  onSendFeedback={() => showFeedbackDialog(!isFeedbackDialogShown)}
                />
              }>
              <Button icon="menu" />
            </Popover2>
          }
        />
        <div className={styles.body}>
          {sidebarAsPopup ? undefined :
            <Sidebar
              className={styles.sidebar}
              onOpenChecklist={() => openChecklistDialog(true)}
              onSendFeedback={() => showFeedbackDialog(!isFeedbackDialogShown)}
            />
          }
          <div key={`render${rerenders}`} className={styles.content}>
            {children}
          </div>
        </div>
        <Dialog
          icon="info-sign"
          title={t("Checklist.Dialog.header")}
          isOpen={isChecklistDialogOpen}
          onClose={() => openChecklistDialog(false)}>
          <DialogBody>
            <p>{t("Checklist.Dialog.welcomeMsg")}</p>
            <ChecklistForm />
          </DialogBody>
          <DialogFooter>
            <div className={styles.dontDisplayOnStartup}>
              <input
                id="dontDisplayOnStartup"
                type="checkbox"
                checked={isChecklistDialogOpenOnStartup}
                onChange={(e) => {
                  openChecklistDialogOnStartup(e.target.checked);
                }}
              />
              <label htmlFor="dontDisplayOnStartup">{t("Checklist.Dialog.dislayOnStartup")}</label>
            </div>
          </DialogFooter>
        </Dialog>
        <Dialog
          icon={<MdOutlineBugReport size={18} style={{ marginInlineEnd: 7, marginInlineStart: 7 }} />}
          title={t("Sidebar.SendFeedbackDialog.title")}
          isCloseButtonShown={true}
          isOpen={isFeedbackDialogShown}
          onClose={() => showFeedbackDialog(false)}>
          <DialogBody>
            <TextArea
              style={{ width: '100%', height: 80 }}
              placeholder={t("Sidebar.SendFeedbackDialog.placeholder")}
              value={feedbackText}
              onChange={e => setFeedbackText(e.target.value)}
            />
          </DialogBody>
          <DialogFooter
            actions={
              <Button
                text={["send"]}
                intent="primary"
                loading={sendFeedbackState.isLoading}
                onClick={() => {
                  dispatch(sendFeedback(feedbackText));
                }}
              />
            } />
        </Dialog>
      </div>
    </ResizeSensor >
  );
}

export type WorkspaceLayoutProps = React.ComponentPropsWithRef<"div"> & {
}