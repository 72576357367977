/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { isEmpty, isUseless, ApiSyncController, useApiState } from "common/utils";
import { GeocodeIpAction } from "geo/actions/geocode-ip.action";
import { SyncVisitorLocationAction } from "geo/actions/sync-visitor-location.action";


export function useSyncVisitorLocation(orderId: string, opts: {
  auto?: boolean,
} = {}) {
  const [syncStateMap, dispatch] = useApiState((state) => state.geo.syncVisitorLocationMap);
  const syncVisitorLocationsState = syncStateMap[orderId] || {};

  const syncVisitorLocations = useCallback(() => {
    const unsub = new ApiSyncController();
    dispatch(SyncVisitorLocationAction(orderId, unsub));
    return unsub.cancel;
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(syncVisitorLocationsState))
      syncVisitorLocations();
  }, [opts.auto !== false, isEmpty(syncVisitorLocationsState)])

  return { syncVisitorLocationsState, syncVisitorLocations };
}

export function useGeocodeIp(opts?: { auto?: boolean }) {
  const [geocodeIpState, dispatch] = useApiState((state) => state.geo.geocodeIp);

  useEffect(() => {
    if (isEmpty(geocodeIpState) && opts?.auto) {
      dispatch(GeocodeIpAction())
    }
  }, [geocodeIpState, opts?.auto])

  return geocodeIpState;
}