/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as Blueprint from "@blueprintjs/core";
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import styles from "./styles.module.scss";


export default function ({
  className, hidden, text, children, ...rest
}: MenuItemProps) {
  if (hidden)
    return null;
  const { t } = useTranslation();
  const classes = [styles.menuItem, className];
  const textValue = _.isArray(text) ? t(text[0], text[2], text[1]) : text;
  return (
    <Blueprint.MenuItem
      className={classes.join(" ")}
      text={textValue}
      {...rest}>
      {children}
    </Blueprint.MenuItem>
  );
}

export type MenuItemProps = Blueprint.MenuItemProps & {
  hidden?: boolean;
  text?: string | [string, object?, string?],
}