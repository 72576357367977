/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from 'moment';
import { useCallback, useMemo } from "react";

import { OpeningHours } from "common/common.entities";
import { Form, TimeInputField } from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";


export default function <T extends OpeningHours>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: OpeningHoursFormProps<T>) {
  const classes = useMemo(() => [className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  const onDateChange = useCallback((e) => {
    if (onChange) {
      const value = moment(e.target.value).format('HH:mm');
      onChange({ target: { name: e.target.name, value } });
    }
  }, [onChange])

  return (
    <Form className={classes} {...rest}>
      <TimeInputField
        selectAllOnFocus
        name={fieldName("opensAt")}
        label={["Branch.OpeningHoursForm.opensAt"]}
        value={value?.opensAt ? moment(value.opensAt, 'HH:mm').toDate() : null}
        error={errors?.opensAt}
        readOnly={readOnly}
        hidden={isHidden("opensAt")}
        onChange={onDateChange}
      />
      <TimeInputField
        selectAllOnFocus
        name={fieldName("closesAt")}
        label={["Branch.OpeningHoursForm.closesAt"]}
        value={value?.closesAt ? moment(value.closesAt, 'HH:mm').toDate() : null}
        error={errors?.closesAt}
        readOnly={readOnly}
        hidden={isHidden("closesAt")}
        onChange={onDateChange}
      />
      <TimeInputField
        nullable
        selectAllOnFocus
        name={fieldName("breakStartsAt")}
        label={["Branch.OpeningHoursForm.breakStartsAt"]}
        value={value?.breakStartsAt ? moment(value.breakStartsAt, 'HH:mm').toDate() : null}
        error={errors?.breakStartsAt}
        readOnly={readOnly}
        hidden={isHidden("breakStartsAt")}
        onChange={onDateChange}
      />
      <TimeInputField
        nullable
        selectAllOnFocus
        name={fieldName("breakEndsAt")}
        label={["Branch.OpeningHoursForm.breakEndsAt"]}
        value={value?.breakEndsAt ? moment(value.breakEndsAt, 'HH:mm').toDate() : null}
        error={errors?.breakEndsAt}
        readOnly={readOnly}
        hidden={isHidden("breakEndsAt")}
        onChange={onDateChange}
      />
      {children}
    </Form>
  );
}

export type OpeningHoursFormProps<T extends OpeningHours> = EntityFormProps<T> & {
};