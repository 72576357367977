/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
  ApiAction, CreateListOperation, DeleteListItemOperation,
  ApiSyncController, UpdateListItemOperation,
  UpsertListItemOperation, failed, loading, success
} from "common/utils";
import { Category } from "../category.entities";
import { SyncCategoriesState } from '../category.store';


export function SyncCategories(ctrl: ApiSyncController): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(SyncCategoriesState(loading()));
    try {
      const io = api.sync<Category>("/catalog/categories", {
        onConnected: (socket) => socket.emit("sync"),
        onEvent: {
          data: (data) => {
            dispatch(SyncCategoriesState(success(new CreateListOperation(data))));
          },
          create: (item) => {
            dispatch(SyncCategoriesState(success(new UpsertListItemOperation(item, test))));
          },
          update: (item) => {
            dispatch(SyncCategoriesState(success(new UpdateListItemOperation(item, test))));
          },
          delete: (item) => {
            dispatch(SyncCategoriesState(success(new DeleteListItemOperation(item, test))));
          }
        },
        onError(error) {
          dispatch(SyncCategoriesState(failed(error)));
        },
      });
      ctrl.attach({
        onCancel: () => io.disconnect(),
      })
    } catch (error) {
      dispatch(SyncCategoriesState(failed(error)));
    }
  };
}

function test(a: Category, b: Category) {
  return a.id === b.id;
}