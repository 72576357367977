/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { TimePicker, TimePickerProps } from "@blueprintjs/datetime";
import { useCallback, useMemo } from "react";

import { OnChangeEventHandler } from "common/utils";
import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.scss";


export default function TimeInputField({
  className, label, hidden, disabled, error, name,
  readOnly, value, nullable, onChange, ...rest
}: TimeInputFieldProps) {
  const classes = useMemo(() => {
    const classes = [styles.timeInputField, className];
    if (value == null)
      classes.push(styles.noValue);
    return classes.join(" ").trim();
  }, [className, value]);

  const onDateChange = useCallback((value: Date) => {
    if (onChange) {
      onChange({ target: { name, value } });
    }
  }, [onChange])

  return (
    <FormField
      className={classes}
      label={label}
      disabled={disabled}
      hidden={hidden}
      error={error} >
      <TimePicker
        disabled={disabled}
        intent={error ? "danger" : undefined}
        value={value}
        onChange={onDateChange}
        onKeyDown={(e) => {
          if (onChange) {
            if (e.key === 'Backspace' && nullable) {
              onChange({ target: { name, value: null } });
            }
            if (value == null && e.key === 'Enter') {
              onChange({ target: { name, value: new Date(0, 0, 0, 0, 0, 0, 0) } });
            }
          }
        }}
        {...rest}
      />
    </FormField>
  );
}



export type TimeInputFieldProps = FormFieldProps &
  Omit<TimePickerProps, "placeholder" | "onChange"> & {
    name: string,
    readOnly?: boolean,
    onChange?: OnChangeEventHandler<Date | null>,
    nullable?: boolean,
  }