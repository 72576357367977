/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";
import moment from "moment";

import {
	Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState
} from "react";
import { useTranslation } from "react-i18next";

import { EtrScheduleDay } from "branch/branch.entities";
import { useSyncBranches } from "branch/branch.hooks";
import {
	ApiSyncController, isEmpty, useApiState, weekdaysShort
} from "common/utils";
import * as historyActions from "order/actions/load-order-history.action";
import { SyncOrders } from "order/actions/sync-orders.action";
import { Order, PickupOrder } from "order/order.entities";


export function useSyncOrders<T extends Order = Order>(opts: {
  auto?: boolean,
  find?: (value: Order, index: number, array: Order[]) => boolean,
  filter?: (value: Order, index: number, array: Order[]) => boolean,
} = {}, deps: any[] = []) {
  const [syncOrdersState, dispatch] = useApiState((state) => state.order.sync);

  const syncOrders = useCallback(() => {
    const unsub = new ApiSyncController();
    dispatch(SyncOrders(unsub));
    return unsub.cancel;
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(syncOrdersState))
      syncOrders();
  }, [opts.auto !== false, isEmpty(syncOrdersState)])

  const orders = useMemo(() =>
    ((opts.filter == null
      ? syncOrdersState.value
      : syncOrdersState.value?.filter(opts.filter)) || []) as T[],
    [syncOrdersState, opts.filter, ...deps]);

  const order = useMemo(() =>
    opts.find == null
      ? null
      : syncOrdersState.value?.find(opts.find) as T,
    [syncOrdersState, opts.find, ...deps]);

  return { syncOrdersState, syncOrders, orders, order };
}

export function useLoadOrderHistory(opts: {
  auto?: boolean,
  find?: (value: Order, index: number, array: Order[]) => boolean,
  filter?: (value: Order, index: number, array: Order[]) => boolean,
  pageSize: number,
} = { pageSize: 50 }, deps: any[] = []) {
  const [loadOrderHistoryState, dispatch] = useApiState((state) => state.order.loadOrderHistory);
  const [loadMoreOrderHistoryState] = useApiState((state) => state.order.loadMoreOrderHistory);

  const loadOrderHistory = useCallback((query?: string) =>
    dispatch(historyActions.loadOrderHistory(opts.pageSize, query)), []);
  const loadMoreOrderHistory = useCallback((from: number) =>
    dispatch(historyActions.loadMoreOrderHistory(opts.pageSize, from)), []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(loadOrderHistoryState))
      loadOrderHistory();
  }, [opts.auto, loadOrderHistoryState])

  const orders = useMemo(() =>
  ((opts.filter == null
    ? loadOrderHistoryState.value
    : loadOrderHistoryState.value?.filter(opts.filter)) || []),
    [loadOrderHistoryState, opts.filter, ...deps]);

  const order = useMemo(() =>
    opts.find == null ? null : loadOrderHistoryState.value?.find(opts.find),
    [loadOrderHistoryState, opts.find, ...deps]);

  return {
    loadOrderHistoryState,
    loadMoreOrderHistoryState,
    loadMoreOrderHistory,
    loadOrderHistory,
    orders,
    order
  };
}

export function useCreatedAgo(order?: Order | null) {
  const { t, i18n } = useTranslation(undefined, { keyPrefix: "Duration" });
  return useMemo(() => {
    if (!order) return null;

    const createdAt = moment(order.createdAt).local();
    const mins = moment().diff(createdAt, 'minutes');
    if (mins == 0) return t("ago", '', { duration: t('justNow') });
    if (mins < 60) return t("ago", '', { duration: t('minutes', '', { count: mins }) });
    const hours = moment().diff(createdAt, 'hours');
    if (hours <= 24) return t("ago", '', { duration: t('hours', '', { count: hours }) });
    const days = moment().diff(createdAt, 'days');
    return t("ago", '', { duration: t('days', '', { count: days }) });
  }, [order?.createdAt, i18n.language]);
}

function useFormatEtrEta() {
  const { t, i18n } = useTranslation(undefined, { keyPrefix: "Duration" });
  return useCallback((
    when: Date,
    wrapperFn: ((duration: string, durationUnit: 'minute' | 'hour' | 'day') => string),
    alreadyFn: ((minsAgo: number) => string)) => {
    const mins = moment(when).local().diff(moment(), 'minutes');
    if (alreadyFn && mins <= 0) return alreadyFn(mins);
    if (mins < 60) return wrapperFn(t('minutes', '', { count: mins }), 'minute');
    const hours = moment(when).local().diff(moment(), 'hours');
    if (hours <= 24) return wrapperFn(t('hours', '', { count: hours }), 'hour');
    const days = moment(when).local().diff(moment(), 'days');
    return wrapperFn(t('days', '', { count: days }), 'day');
  }, [i18n.language]);
}

export function useEtrString(order?: Order | null) {
  const { t, i18n } = useTranslation();
  const formatEtrEta = useFormatEtrEta();
  const value = useMemo(() => {
    if (order?.etr == null) return null;
    return formatEtrEta(order.etr,
      (duration) => t('Duration.readyIn', '', { duration }),
      () => t('ready'));
  }, [order?.etr, i18n.language]);
  return value;
};

export const usePreferredEtrString = (order?: Order | null, alreadyFn?: ((minsAgo: number) => string)) => {
  const { t } = useTranslation();
  const formatEtrEta = useFormatEtrEta();
  const value = useMemo(() => {
    if (order?.preferredEtr == null) return null;
    return formatEtrEta(order.preferredEtr,
      (d, unit) => moment(order.preferredEtr).local().format(t("Formats.time")) + (unit === 'day' ? ` +${d}` : ''),
      alreadyFn || (() => ""));
  }, [order?.preferredEtr]);
  return value;
};

export const useEtaString = (order?: PickupOrder | null) => {
  const { t, i18n } = useTranslation();
  const formatEtrEta = useFormatEtrEta();
  const value = useMemo(() => {
    if (order == null) return null;
    if (order.visitorActivity?.status === 'arrived') return t('Order.JourneyStatus.arrived');
    if (_.get(order, 'visitorActivity.directions.routes.0.eta') == null) return null;
    return formatEtrEta(moment.utc().add(
      (order as PickupOrder).visitorActivity!.directions!.routes![0].eta!, 'seconds').toDate(),
      (duration) => t('Duration.arrivingIn', '', { duration }),
      () => t('arriving'));
  }, [order, i18n.language]);
  return value;
};

export function useDefaultEtr(order?: Order | null): [number, Dispatch<SetStateAction<number>>] {
  const { branches } = useSyncBranches({ auto: false });
  const [etr, setEtr] = useState(10);

  useEffect(() => {
    if (order != null) {
      var maxEtr = -1;
      const maxEtrItem = _.maxBy(order.cart.items, (i) => i.product.etr || -1);
      if (maxEtr < (maxEtrItem?.product?.etr || -1)) {
        maxEtr = maxEtrItem!.product!.etr!;
      }
      const branch = branches.find(b => b.id === order.branch.id);
      if (branch?.etrSchedule) {
        const etrSchedule = branch!.etrSchedule!;
        if (maxEtr < (etrSchedule.defaultEtr || -1)) {
          maxEtr = etrSchedule.defaultEtr!;
        }
        const branchTime = moment.tz(branch.timezone);
        const weekday = weekdaysShort[branchTime.isoWeekday() - 1];
        const etrScheduleDay = etrSchedule[weekday] as EtrScheduleDay;
        if (etrScheduleDay?.delays?.at(branchTime.hour())) {
          maxEtr += etrScheduleDay.delays[branchTime.hour()]!;
        }
      }
      if (maxEtr > 0) {
        setEtr(maxEtr);
      }
    }
  }, [order == null, branches.length]);

  return [etr, setEtr];
};