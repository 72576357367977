/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Employee } from "employee/employee.entities";
import { ApiAction, failed, loading, success } from "common/utils";
import { DeleteEmployeeState } from "../employee.store";


export function DeleteEmployee(id: string): ApiAction {
  return async (dispatch, store, api) => {
    dispatch(DeleteEmployeeState(loading()));
    try {
      const { value } = await api.delete(`/business/employees/${id}`);
      dispatch(DeleteEmployeeState(success(value)));
    } catch (error) {
      dispatch(DeleteEmployeeState(failed(error)));
    }
  };
}
