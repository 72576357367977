/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import {
	InputGroup, InputGroupProps2, Menu, Position
} from "@blueprintjs/core";
import { Popover2, Popover2Props } from "@blueprintjs/popover2";
import { useEffect, useState } from "react";

import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";


export default function Suggestions<Suggestion>({
  className, label, hidden, disabled, error, onSubmit,
  value, query, onQueryChange, suggestions, renderSuggestion,
  popoverProps, actions, endElement, ...rest
}: SuggestionsProps<Suggestion>) {
  const rtl = document.body.classList.contains('rtl');
  const [isSuggestionsShown, showSuggestions] = useState(false);

  // callbacks
  if (onSubmit) rest.onKeyUp = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onSubmit(e);
    }
  };
  // effects
  useEffect(() => {
    showSuggestions(!_.isEmpty(suggestions));
  }, [suggestions])

  return (
    <Popover2
      fill
      minimal
      matchTargetWidth
      className={styles.popover}
      position={Position.BOTTOM_LEFT}
      isOpen={isSuggestionsShown}
      autoFocus={false}
      onInteraction={(show) => !show && showSuggestions(false)}
      content={
        <Menu>
          {_.map(suggestions, (s) =>
            renderSuggestion(s, () => showSuggestions(false)))}
        </Menu>
      }
      {...popoverProps}>
      <FormField
        className={[styles.suggestionsfield, className].join(" ")}
        label={label}
        disabled={disabled}
        hidden={hidden}
        error={error}
        actions={actions}>
        <InputGroup
          disabled={disabled}
          intent={error ? "danger" : undefined}
          value={query || ''}
          placeholder={value}
          onChange={(e) => onQueryChange(e.target.value)}
          onFocusCapture={() => {
            if (!_.isEmpty(suggestions) && !isSuggestionsShown)
              setTimeout(() => {
                showSuggestions(true);
              }, 200);
          }}
          leftElement={rtl ? endElement : undefined}
          rightElement={rtl ? undefined : endElement}
          {...rest}
        />
      </FormField>
    </Popover2>
  );
}



export type SuggestionsProps<Suggestion> = FormFieldProps
  & Omit<InputGroupProps2, "placeholder" | "onChange" | "onFocusCapture"> & {
    suggestions?: Suggestion[]
    renderSuggestion: (s: Suggestion, closePopover: () => void) => JSX.Element,
    popoverProps?: Popover2Props,
    query: string | null,
    onQueryChange: (q: string) => void,
    endElement?: JSX.Element,
  }