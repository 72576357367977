/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction, UpdateListItemOperation, failed, loading, success } from "common/utils";
import { Order } from "order/order.entities";
import { CompleteOrderState, SyncOrdersState } from "../order.store";


export function CompleteOrder(id: string): ApiAction {
  return async (dispatch, store, api) => {
    dispatch(CompleteOrderState(loading()));
    try {
      const { value } = await api.post(`/orders/${id}/complete`);
      dispatch(CompleteOrderState(success(value)));
      dispatch(SyncOrdersState(success(new UpdateListItemOperation(value, test))));
    } catch (error) {
      dispatch(CompleteOrderState(failed(error)));
    }
  };
}

function test(a: Order, b: Order) {
  return a.id === b.id;
}