/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Column, Row, Tag } from 'common/components';
import Card, { CardProps } from "common/components/cards/Card";
import { Table } from "../../table.entities";
import styles from "./styles.module.scss";


export default function ({
  className, value, onClick, children, ...rest
}: TableCardProps) {
  const { t } = useTranslation();
  const classNames = useMemo(() =>
    [styles.tableCard, className].join(" ").trim(),
    [className]);

  return (
    <Card
      key={value.id}
      interactive={onClick != null}
      className={classNames}
      onClick={onClick}
      {...rest}>
      <Row>
        <Avatar
          className={styles.avatar}
          size={55}
          name={value.name}
          picture={value.picture?.small?.url}
          grayscale={value.active != true}
        />
        <Column>
          <h4>
            {value.name}
            <Tag
              intent={
                value.active != true ? 'none' :
                  value.status === 'free' ? 'success' : 'danger'
              }>
              {value.status}
            </Tag>
          </h4>
        </Column>
      </Row>
      {children}
    </Card>
  );
}

export type TableCardProps = CardProps & {
  value: Table,
};