/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { SyncEmployees } from "employee/actions/sync-employees.action";
import { Employee } from "employee/employee.entities";
import { isEmpty, ApiSyncController, useApiState } from "common/utils";


export function useSyncEmployees<T extends Employee = Employee>(opts: {
  auto?: boolean,
  find?: (value: Employee, index: number, array: Employee[]) => boolean,
  filter?: (value: Employee, index: number, array: Employee[]) => boolean,
} = {}) {
  const [syncEmployeesState, dispatch] = useApiState((state) => state.employee.sync);

  const syncEmployees = useCallback(() => {
    const unsub = new ApiSyncController();
    dispatch(SyncEmployees(unsub));
    return unsub.cancel;
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(syncEmployeesState))
      syncEmployees();
  }, [opts.auto, syncEmployeesState])

  const employees = useMemo(() =>
    ((opts.filter == null
      ? syncEmployeesState.value
      : syncEmployeesState.value?.filter(opts.filter)) || []) as T[],
    [syncEmployeesState, opts.filter]);

  const employee = useMemo(() =>
    opts.find == null
      ? null
      : syncEmployeesState.value?.find(opts.find) as T,
    [syncEmployeesState, opts.find]);

  return { syncEmployeesState, syncEmployees, employees, employee };
}