/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useState } from "react";
import { MdOutlineTableBar } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import {
	BackAsyncError, Backout, Button, Grid, GridContentLoader
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { ApiCallStateBuilder } from "common/utils";
import TableCard from "table/components/TableCard";
import { Table } from "table/table.entities";
import { useSyncTables } from "table/table.hooks";


export default function TablesPage() {
  // contexts
  const navigate = useNavigate()

  // state
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  // api state
  const { syncTablesState, tables } = useSyncTables({
    filter: (table) => _.isEmpty(searchQuery) ||
      _.values(table).join(" ").toLowerCase().includes(searchQuery!),
  });

  return (
    <>
      <PageHeader
        icon={<MdOutlineTableBar size={20} style={{ marginInlineEnd: 2 }} />}
        title={['Tables']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text="Add"
          intent="success"
          onClick={() => navigate('new')}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder<Table[]>
          state={syncTablesState}
          onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueEmpty={() => (
            <div style={{ marginTop: 150 }}>
              <Backout
                icon={<MdOutlineTableBar style={{ marginTop: -3, marginInlineStart: -2 }} />}
                title="No Tables Yet">
                <div style={{ marginTop: 20 }}>
                  <Button
                    outlined
                    text={["Add"]}
                    onClick={() => navigate("/tables/new")} />
                </div>
              </Backout>
            </div>
          )}
          onValueNotEmpty={() =>
            <Grid md={3} sm={2} xs={1} gap={20} >
              {tables.map(table =>
                <TableCard
                  key={table.id}
                  value={table}
                  onClick={() => navigate(table.id!)}
                />
              )}
            </Grid>
          }
        />
      </PageContent>
    </>
  );
}
