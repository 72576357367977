/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BranchCatalog, CatalogSchedule } from "branch/branch.entities";
import BranchCatalogScheduleFields from "branch/components/BranchCatalogScheduleForm";
import { useSyncCatalogs } from "catalog/catalog.hooks";
import { Backout, Button, Callout } from "common/components";
import {
	DropdownField, Form, FormField, SwitchField
} from "common/components/form";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { useSyncMerchantAccounts } from "merchant/merchant.hooks";

import styles from "./styles.module.scss";


export default function BranchCatalogForm<T extends BranchCatalog>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: BranchCatalogFormProps<T>) {
  const { t } = useTranslation();

  const classes = useMemo(() => [styles.branchCatalogForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  const { catalogs, catalog } = useSyncCatalogs({
    find: (item) => value?.catalog === item.id,
  }, [value?.catalog]);
  const { merchants, merchant } = useSyncMerchantAccounts({
    find: (item) => value?.merchant === item.id,
    filter: (item) => item.status === 'active',
  }, [value?.merchant]);

  return (
    <Form className={classes} {...rest}>
      <SwitchField
        name={fieldName("available")}
        label={["available"]}
        value={value?.available}
        error={errors?.available}
        readOnly={readOnly}
        hidden={isHidden("available")}
        onChange={onChange}
      />
      <DropdownField
        nullable={catalog == null}
        name="catalog"
        label={["catalog"]}
        value={catalog?.id}
        error={errors?.catalog}
        options={catalogs.map(item => ({
          id: item.id!,
          value: item,
          name: item.name + (item.available ? '' : ` (${t('unavailable', { postProcess: 'lowercase' })})`),
        }))}
        onChange={(event) => {
          onChange({
            target: {
              name: fieldName("catalog"),
              value: event.target.value?.id,
            }
          })
        }}
      />
      <DropdownField
        nullable={merchant == null}
        name="merchant"
        label={["merchantAccount"]}
        value={merchant?.id}
        error={errors?.merchant}
        options={merchants.map(item => ({
          id: item.id!,
          value: item,
          name: item.name,
        }))}
        actions={
          <>
            <Button
              key="connect-action"
              minimal
              text={["connect", { postProcess: 'lowercase' }]}
              onClick={() => window.open("/merchants/stripe/new", "_blank")} />
          </>
        }
        onChange={(event) => {
          onChange({
            target: {
              name: fieldName("merchant"),
              value: event.target.value?.id,
            }
          })
        }}
      />
      <FormField
        collapsed={value?.schedule == null}
        label={["timeSchedule"]}
        style={{ marginTop: 5 }} >
        {value?.schedule == null ? (
          <Backout
            compact
            icon='calendar'
            title={["Branch.CatalogForm.noTimeScheduleTitle"]}
            message={["Branch.CatalogForm.noTimeScheduleMsg"]}>
            <Button text={["create"]}
              onClick={() => {
                onChange({
                  target: {
                    name: fieldName("schedule"),
                    value: {} as CatalogSchedule,
                  }
                })
              }} />
          </Backout>
        ) : (
          <BranchCatalogScheduleFields
            name={fieldName("schedule")}
            value={value?.schedule}
            errors={errors?.schedule}
            readOnly={readOnly}
            onChange={onChange}>
            <Callout intent='primary' className={styles.callout}>
              <>
              {t("Branch.CatalogForm.noValuesCallout")}<br />
              <a href="#"
                onClick={() => {
                  const value: CatalogSchedule = {
                    mon: { since: '00:00', till: '00:00' },
                    tue: { since: '00:00', till: '00:00' },
                    wed: { since: '00:00', till: '00:00' },
                    thu: { since: '00:00', till: '00:00' },
                    fri: { since: '00:00', till: '00:00' },
                    sat: { since: '00:00', till: '00:00' },
                    sun: { since: '00:00', till: '00:00' },
                  }
                  onChange({
                    target: {
                      name: fieldName("schedule"),
                      value,
                    }
                  })
                }}>{t("Branch.CatalogForm.noValuesAction")}</a>
              </>
            </Callout>
          </BranchCatalogScheduleFields>
        )}
      </FormField>
      {children}
    </Form>
  );
}

export type BranchCatalogFormProps<T extends BranchCatalog> = EntityFormProps<T> & {
};