/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Icon } from "@blueprintjs/core";
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AvatarEditor, { AvatarEditorProps, Position } from 'react-avatar-editor';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import QuickPinchZoom from "react-quick-pinch-zoom";
import { BehaviorSubject, debounceTime } from 'rxjs';

import { OnChangeEventHandler } from 'common/utils/types';
import { ExImage, ExImageFrame } from '../../media.entities';
import styles from "./styles.module.sass";


export default function ExImageEditor({
  className, name, value, onChange, children, disabled,
  frameProps, dropzoneProps, editorProps,
  ...rest
}: ExImageEditorProps) {
  // state 
  const [imageSize, setImageSize] = useState<{ width: number, height: number }>()
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState<Position>({ x: 0.5, y: 0.5 });

  // callbacks
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!_.isEmpty(acceptedFiles)) {
      onChange({
        target: {
          name: `${name}.file`,
          value: acceptedFiles[0],
        }
      });
      // triger frame change
      setTimeout(() => setScale(scale + 0.01), 100);
    }
  }, [onChange])

  // context
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: value != null,
    disabled: disabled,
    multiple: false,
    accept: 'image/jpeg,image/png',
    ...dropzoneProps
  })

  // memos
  const classes = useMemo(() => {
    const list = [styles.eximage, className];
    if (isDragActive)
      list.push(styles.dragging)
    return list;
  }, [isDragActive]);

  const previewImageUrl = useMemo(() =>
    value?.file ? URL.createObjectURL(value!.file) : value?.origin?.url,
    [value])

  const onFrameChange = useMemo(() => {
    if (!imageSize) return;

    const widthAspect = imageSize.width / frameProps.width;
    const heightAspect = imageSize.height / frameProps.height;
    const sub = new BehaviorSubject<{ scale: number, position: Position } | null>(null);
    sub.pipe(debounceTime(500))
      .subscribe({
        next: (event) => {
          if (event) {
            const w = 1 / (event.scale * widthAspect);
            const h = 1 / (event.scale * heightAspect);
            const x = event.position.x;
            const y = event.position.y;
            const newFrame: ExImageFrame = { x, y, w, h };
            if (!_.isEqual(newFrame, value?.frame)) {
              onChange({
                target: {
                  name: `${name}.frame`,
                  value: newFrame,
                },
              });
            }
          }
        }
      })
    return sub;
  }, [onChange, imageSize]);

  useEffect(() => {
    onFrameChange?.next({ scale, position })
  }, [scale, position])

  useEffect(() => {
    if (imageSize && value?.frame) {
      const widthAspect = imageSize.width / frameProps.width;
      const heightAspect = imageSize.height / frameProps.height;
      const widthScale = 1 / value.frame.w * widthAspect;
      const heightScale = 1 / value.frame.h * heightAspect;
      setScale(Math.min(widthScale, heightScale));
      setPosition({ x: value.frame.x, y: value.frame.y });
    }
  }, [imageSize])

  return (
    <div className={classes.join(" ")}
      {...getRootProps()}
      style={{ height: frameProps.height + (frameProps.margin || 2) }}
      {...rest}>
      <input {...getInputProps()} />
      {
        _.isEmpty(previewImageUrl)
          ? <Icon icon="cloud-upload" />
          : <QuickPinchZoom
            enabled={false}
            onUpdate={(action) => {
              setScale(action.scale);
              // https://trello.com/c/7wlzDcAl
              if (scale > action.scale)
                setPosition({ x: 0.5, y: 0.5 })
            }}>
            <AvatarEditor
              image={previewImageUrl as string}
              width={frameProps.width}
              height={frameProps.height}
              border={0}
              borderRadius={frameProps.radius}
              color={[255, 255, 255, 1]}
              scale={scale}
              position={position}
              onPositionChange={setPosition}
              onLoadSuccess={setImageSize}
              {...editorProps}
            />
          </QuickPinchZoom>
      }
      {children}
    </div>
  )
}

export type ExImageEditorProps = Omit<React.ComponentPropsWithRef<"div">, "onChange"> & {
  className?: string,
  name: string,
  value?: ExImage | null,
  onChange: OnChangeEventHandler<any>;
  disabled?: boolean,
  children?: React.ReactNode,
  dropzoneProps?: DropzoneOptions
  editorProps?: AvatarEditorProps,
  frameProps: {
    height: number,
    width: number,
    radius?: number,
    margin?: number,
  }
}
