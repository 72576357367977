/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useCallback, useMemo, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";

import { OnChangeEventHandler } from 'common/utils';
import styles from "./styles.module.scss";


export type ListItem = {
  id: string;
}

export default function DragAndDropOrderList<T extends ListItem>({
  className, values, name, id, onChange, renderItem,
}: DragAndDropOrderListProps<T>) {
  const [wrapperHeight, setWrapperHeight] = useState<number>();

  const classNames = useMemo(() =>
    [styles.dragAndDropOrderList, className].join(" "),
    [className]);

  const onDragStart = useCallback(() => {
    const el = document.getElementById(`${id}-wrapper`);
    setWrapperHeight(el?.clientHeight);
  }, []);
  const onDragEnd = useCallback((result: DropResult) => {
    setWrapperHeight(undefined);
    if (result.destination && onChange) {
      const newValues = values.slice();
      const [reorderedItem] = newValues.splice(result.source.index, 1);
      newValues.splice(result.destination.index, 0, reorderedItem);
      onChange({ target: { name: name || '', value: newValues } });
    }
  }, [values]);

  return (
    <div
      id={`${id}-wrapper`}
      className={classNames}
      style={{ height: wrapperHeight ? `${wrapperHeight}px` : 'unset' }}>
      <DragDropContext onBeforeDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId={id} direction='vertical'>
          {(provided) =>
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {values.map((value, index) =>
                <Draggable key={value.id} draggableId={value.id} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      {renderItem(value, index)}
                    </div>
                  )}
                </Draggable>
              )}
            </div>
          }
        </Droppable>
      </DragDropContext>
    </div>
  );
}
export type DragAndDropOrderListProps<T> = Omit<React.ComponentPropsWithRef<"div">, 'onChange'> & {
  id: string;
  name?: string;
  values: T[];
  onChange?: OnChangeEventHandler<T[]>;
  renderItem: (item: T, index: number) => JSX.Element;
}