/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Catalog } from "catalog/catalog.entities";
import { ApiAction, failed, loading, success } from "common/utils";
import { SaveCatalogState } from "../catalog.store";


export function CreateCatalog(catalog: Catalog): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(SaveCatalogState(loading()));
    try {
      const { value } = await api.post('/catalogs', {
        data: catalog,
      })
      dispatch(SaveCatalogState(success(value)));
    } catch (error) {
      dispatch(SaveCatalogState(failed(error)));
    }
  };
}
