/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { TagProps } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";

import { Tag } from "common/components";
import { Order } from "order/order.entities";

import styles from "./styles.module.scss";


export default function ({
  className, order, lowercase, children, ...rest
}: OrderStageTagProps) {
  const { t } = useTranslation();
  const intent = order.status === 'created'
    ? "warning"
    : order.status === 'prepared'
      ? "primary"
      : order.status === 'active'
        ? 'success'
        : order.status === 'cancelled' || order.status === 'declined'
          ? 'danger'
          : "none";
  let text = t(`Order.Status.${order.status}`);
  if (lowercase)
    text = text.toLowerCase();
  return (
    <Tag
      minimal
      className={styles.orderStageTag}
      intent={intent}>
      {text}
      {children}
    </Tag>
  );
}

export type OrderStageTagProps = TagProps & {
  order: Order,
  lowercase?: boolean,
};