/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import * as bp from '@blueprintjs/core';
import { useI18n } from 'common/i18n/hooks';
import { useMemo } from "react";

import styles from "./styles.module.scss";

export default bp.Tab;

// TODO: does not work properly:

// export default function Tab({
//   className, title, ...rest
// }: TabProps) {
//   const classes = useMemo(() => {
//     const cls = [styles.tab];
//     if (className) cls.push(className);
//     return cls.join(' ').trim();
//   }, [className]);
//   const titleText = useI18n(_.isObject(title) ? '' : title);

//   return (
//     <bp.Tab
//       className={classes}
//       title={_.isObject(title) ? title :titleText}
//       {...rest} />
//   );
// }

// export type TabProps = bp.TabProps & {
//   className?: string,
//   title?: string | [string, object?, string?] | JSX.Element,
// }