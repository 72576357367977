/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Pincode } from "auth/auth.entities";
import { LoginState, SavePincodeState } from "auth/auth.store";
import { ApiAction, failed, loading, success } from "common/utils";


export function SavePincodeAction(pincode: Pincode): ApiAction {
  return async (dispatch, getState, api) => {
    dispatch(SavePincodeState(loading()));
    try {
      const { value } = await api.put("/auth/pincode", {
        params: pincode,
      });
      dispatch(SavePincodeState(success(value)));
      const state = getState();
      if (state.auth.login.value) {
        dispatch(LoginState(success({
          ...state.auth.login.value,
          user: value
        })));
      }
    } catch (error) {
      dispatch(SavePincodeState(failed(error)));
    }
  };
}