/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createAction, createSlice } from "@reduxjs/toolkit";

import { ApiCallState, isSuccessful, success } from "common/utils/api-state";

import { Identity, Profile } from "./auth.entities";


export const AuthStore = createSlice({
  name: "auth",
  initialState: {
    login: (localStorage.getItem("identity")
      ? success(JSON.parse(localStorage.getItem("identity")!))
      : {}) as ApiCallState<Identity>,
    pincode: {
      save: {} as ApiCallState<Identity>,
      delete: {} as ApiCallState<Identity>,
    },
    otp: {
      send: {} as ApiCallState<{ token: string, expiresIn: number }>,
      signValue: {} as ApiCallState<string>,
    },
    saveProfile: {} as ApiCallState<Profile>,
  },
  reducers: {
    LoginState: (state, action: { payload: ApiCallState<Identity> }) => {
      if (isSuccessful(action.payload)) {
        localStorage.setItem("identity", JSON.stringify(action.payload.value));
      } else {
        localStorage.removeItem("identity");
      }
      state.login = action.payload;
    },
    // Pincode
    SavePincodeState: (state, action) => {
      state.pincode.save = action.payload;
    },
    DeletePincodeState: (state, action) => {
      state.pincode.delete = action.payload;
    },
    UpdateSaveProfileState: (state, action: { payload: ApiCallState<Profile> }) => {
      state.saveProfile = action.payload;
    },
    // OTP
    UpdateSendOtpState: (state, action) => {
      state.otp.send = action.payload;
    },
    SignOtpValueState: (state, action) => {
      state.otp.signValue = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, () => {
      localStorage.removeItem("identity");
      window.open('/', '_self');
      return getInitialState();
    })
  },
});

export const {
  LoginState,
  SavePincodeState,
  DeletePincodeState,
  UpdateSaveProfileState,
  UpdateSendOtpState,
  SignOtpValueState,
} = AuthStore.actions;

export const Logout = createAction("auth/Logout");

export const { reducer: authReducer, getInitialState } = AuthStore;
export type AuthState = ReturnType<typeof AuthStore.getInitialState>