/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from "react-i18next";


export function useI18n(text?: string | [string, object?, string?]) {
  const { t } = useTranslation();
  return useMemo(() =>
    !text ? undefined : _.isArray(text) ? t(text[0], text[2], text[1]) : text,
    [text]);
}