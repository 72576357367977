/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction, failed, loading, success } from "common/utils";
import { UpdateGeocodeIpState } from "../geo.store";


export function GeocodeIpAction(): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(UpdateGeocodeIpState(loading()));
    try {
      const { value } = await api.get('/geo/ip');
      dispatch(UpdateGeocodeIpState(success(value)));
    } catch (error) {
      dispatch(UpdateGeocodeIpState(failed(error)));
    }
  };
}
