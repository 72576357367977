/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import * as Blueprint from "@blueprintjs/core";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";


export default function ({
  className, hidden, title, children, ...rest
}: MenuDividerProps) {
  if (hidden)
    return null;

  const { t } = useTranslation();
  const classes = [styles.menuDivider, className];
  const titleValue = _.isArray(title) ? t(title[0], title[2], title[1]) : title;
  return (
    <Blueprint.MenuDivider
      className={classes.join(" ")}
      title={titleValue}
      {...rest}>
      {children}
    </Blueprint.MenuDivider>
  );
}

export type MenuDividerProps = Blueprint.MenuDividerProps & {
  hidden?: boolean;
}