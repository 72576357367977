/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as Blueprint from "@blueprintjs/core";

import { useI18n } from "common/i18n/hooks";


export default function ({
  confirmButtonText, cancelButtonText, children, ...rest
}: AlertProps) {
  const confirmButtonTextValue = useI18n(confirmButtonText);
  const cancelButtonTextValue = useI18n(cancelButtonText);
  return (
    <Blueprint.Alert
      confirmButtonText={confirmButtonTextValue}
      cancelButtonText={cancelButtonTextValue}
      {...rest}>
      {children}
    </Blueprint.Alert>
  );
}

export type AlertProps = Omit<Blueprint.AlertProps,
  "confirmButtonText" |
  "cancelButtonText"> & {
    confirmButtonText?: string | [string, object?, string?],
    cancelButtonText?: string | [string, object?, string?],
  }