/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from "moment";

import { Icon } from "@blueprintjs/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BiWallet } from "react-icons/bi";
import { FaRoute } from "react-icons/fa";
import { MdOutlineTableBar } from "react-icons/md";

import { Column, Row, Tag } from "common/components";
import Card, { CardProps } from "common/components/cards/Card";
import OrderPaymentTag from "order/components/OrderPaymentTag";
import OrderStatusTag from "order/components/OrderStatusTag";
import OrderTypeTag from "order/components/OrderTypeTag";
import { DineInOrder, Order, PickupOrder } from "order/order.entities";
import {
	useCreatedAgo, useEtaString, useEtrString, usePreferredEtrString
} from "order/order.hooks";

import styles from "./styles.module.scss";


export default function OrderCard({
  className, value, onClick, children, ...rest
}: OrderCardProps) {
  const { t } = useTranslation();
  const classNames = useMemo(() =>
    [styles.orderCard, className].join(" "),
    [className]);

  // const { products } = useSyncProducts({
  //   filter: (product) => value.cart?.items &&
  //     value.cart.items.find(item => item.product.id === product.id!) != null,
  // });

  // const productImages = useMemo(() => _.take(
  //   value.cart?.items?.map(item => products.find(p => p.id === item.product.id)).filter(p => p?.image?.small),
  //   4).map((p, i) => <img key={i} src={p!.image!.small.url} />),
  const createdAgoString = useCreatedAgo(value);
  const etrString = useEtrString(value);
  const etaString = useEtaString(value as PickupOrder);
  const preferredEtrString = value.type != 'pickup' ? null
  : usePreferredEtrString(value as PickupOrder,
    ((minsAgo) => t("Duration.ago", { duration: t("Duration.minutes", { count: -minsAgo }) })));

  return (
    <Card
      key={value.id}
      interactive={onClick != null}
      className={classNames}
      onClick={onClick}
      {...rest}>
      <Row>
        <Column style={{ overflow: 'hidden' }}>
          <h4>
            {value.number}
            <OrderStatusTag order={value} />
            <OrderTypeTag
              className={styles.pickupMethodTag}
              order={value as PickupOrder} />
            {value.status === 'active' &&
              value['visitorActivity'] != null &&
              value['visitorActivity']?.lastTrackedAt == null && (
                <Tag
                  minimal
                  className={styles.manualLocationTrackingTag}
                  intent="warning">
                  {["Order.manualLocationTracking"]}
                </Tag>
              )}
          </h4>
          <p>
            <Icon icon='calendar' size={12} style={{ marginBottom: 3, marginInlineStart: 2 }} />
            <span className="content">
              {moment(value.createdAt).local().format(t("Formats.time"))}&nbsp;({createdAgoString})
            </span>
          </p>
          <p>
            <Icon icon='time' size={12} style={{ marginBottom: 3, marginInlineStart: 2, marginInlineEnd: 2 }} />
            {etrString == null ? <i>{t("notSpecified")}</i> : <span className="content">{etrString}</span>}
          </p>
          {value.type === 'pickup' && (
            <p>
              <FaRoute size={13} style={{ marginInlineStart: 2, marginInlineEnd: 9, marginBottom: 0 }} />
              <span className="content">
                {value['vehicle'] ? (value as PickupOrder).vehicle :
                  t(`Order.JourneyType.${(value as PickupOrder).visitorActivity?.type}`)}
                {etrString == null && preferredEtrString == null ? '' : `, ${preferredEtrString || etaString}`}
              </span>
            </p>
          )}
          {value.type === 'dine-in' && (
            <p>
              <MdOutlineTableBar size={16} style={{ marginInlineStart: 1, marginBottom: 0 }} />
              <span className="content">
                {(value as DineInOrder).table}
              </span>
            </p>
          )}
          <p style={{ marginBottom: 2 }}>
            <BiWallet size={15} style={{ marginInlineStart: 1, marginInlineEnd: 9, marginBottom: 0 }} />
            <span className="content">
              {value.cart.totalPrice.toFixed(2) + ' ' + value.payment!.merchant.currency} &nbsp;
            </span>
            <OrderPaymentTag order={value} />
          </p>
        </Column>
        {/* <Grid className={styles.imagesGrid} md={2}>
          {productImages}
        </Grid> */}
      </Row>
      {children}
    </Card>
  );
}

export type OrderCardProps = CardProps & {
  value: Order,
};