/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction, UpsertListItemOperation, failed, loading, success } from "common/utils";
import { Order, PreparePickupOrder } from "order/order.entities";
import { PreparePickupOrderState, SyncOrdersState } from "../order.store";


export function preparePickupOrder(data: PreparePickupOrder): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(PreparePickupOrderState(loading()));
    try {
      const { value } = await api.post('/orders/pickup/prepare', {
        data: data,
      })
      dispatch(PreparePickupOrderState(success(value)));
      dispatch(SyncOrdersState(success(new UpsertListItemOperation(value, test))));
    } catch (error) {
      dispatch(PreparePickupOrderState(failed(error)));
    }
  };
}

function test(a: Order, b: Order) {
  return a.id === b.id;
}