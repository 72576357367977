/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Order } from "order/order.entities";

export function doesOrderMeetSearchQuery(order: Order, query: string) {
  const substr = query.toLowerCase();
  return order.number.toLowerCase().includes(substr) ||
    order.branch.name.toLowerCase().includes(substr) ||
    order.customer.name.toLowerCase().includes(substr) ||
    order.customer.email?.toLowerCase().includes(substr) === true ||
    order.customer.phone?.number?.toLowerCase().includes(substr) === true ||
    order.cart.items.find(item => item.product.name.toLowerCase().includes(substr)) != null;
}