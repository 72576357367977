/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useMemo } from "react";
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components';
import { DropdownField } from "common/components/form";
import { DropdownFieldProps, DropdownOption } from "common/components/form/Dropdown";
import { isFailed, isNotSuccessful, isSuccessful, useInitial } from "common/utils";
import { useGetCountries } from 'directory/directory.hooks';
import styles from './styles.module.scss';


export default function CountryDropdownField({
  className, value, error, onChange, readOnly, children, ...rest
}: CountryDropdownFieldProps) {
  const { t } = useTranslation();
  const initial = useInitial();
  const classes = useMemo(() => [styles.countryDropdownField, className].join(" "), [className]);

  const { getCountriesState, getCountries } = useGetCountries()
  const [getCountriesError, options] = useMemo(() => {
    let options: DropdownOption<string>[] = [];
    let error: string | undefined = undefined;
    if (isSuccessful(getCountriesState)) {
      getCountriesState.value!.forEach(country =>
        options.push({
          id: country.id,
          value: country.id,
          name: country.name,
        }));
    } else if (isFailed(getCountriesState)) {
      const apiError = getCountriesState.error!;
      const params = _.omit(apiError, "code", "message");
      error = t(`ApiErrors.${apiError.code}`, apiError.code, params);
    }
    return [error, options];
  }, [initial, getCountriesState])

  return (
    <DropdownField
      className={classes}
      value={value}
      error={getCountriesError || error}
      placeholder={getCountriesState.isLoading ? 'loading...' : undefined}
      options={options}
      actions={
        getCountriesState.error == null ? null : (
          <Button
            minimal
            icon="repeat"
            intent='danger'
            onClick={() => getCountries()}
          />
        )
      }
      readOnly={readOnly || isNotSuccessful(getCountriesState)}
      onChange={onChange}
      {...rest} />
  );
}

export type CountryDropdownFieldProps = Omit<DropdownFieldProps<string>, "options"> & {
}