/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { Collapse, FormGroup, FormGroupProps } from "@blueprintjs/core";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "common/components/buttons";
import FormLabel from "common/components/form/Label";

import styles from "./styles.module.scss";


export default function FormField({
  className, hidden, label = '', error, tooltip,
  children, actions, horizontal, collapsed, ...rest
}: FormFieldProps) {
  if (hidden) return null;

  const { t } = useTranslation();
  const [isCollapsed, setCollapsed] = useState(collapsed);
  const errorText = useMemo(() =>
    _.isArray(error) ? t(error[0], error[2], error[1]) : _.isString(error) ? error : null,
    [error])
  const labelText = useMemo(() => {
    const labelText = _.isArray(label) ? t(label[0], label[2], label[1]) : label;
    return [labelText, (_.isEmpty(labelText) || _.isEmpty(errorText) ? '' : ': '), errorText].join('');
  }, [label, errorText]);
  const classes = useMemo(() => {
    const items = [styles.formField, className];
    if (horizontal) items.push("horizontal");
    if (errorText) items.push("error");
    return items.join(' ');
  }, [className, errorText, horizontal]);

  return (
    <FormGroup
      className={classes}
      label={
        <FormLabel
          text={labelText}
          tooltip={tooltip}
          actions={[
            actions,
            collapsed == null ? undefined : (
              <Button
                key="collapse-action"
                small
                minimal
                icon={isCollapsed ? "expand-all" : "collapse-all"}
                onClick={() => setCollapsed(!isCollapsed)}
              />
            )
          ]}
        />
      } {...rest} >
      <Collapse isOpen={!isCollapsed}>
        {children}
      </Collapse>
    </FormGroup>
  );
}

export type FormFieldProps = FormGroupProps & {
  hidden?: boolean,
  label?: string | [string, object?, string?],
  error?: string | [string, object?, string?],
  tooltip?: string | [string, object?, string?],
  children?: React.ReactNode,
  actions?: React.ReactNode,
  horizontal?: boolean,
  collapsed?: boolean,
}