/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";
import moment from "moment";

import { Callout } from "@blueprintjs/core";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaApple, FaRegEnvelope } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { MdPassword } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { ApiState } from "app.store";
import { LoginWithOtpAction } from "auth/actions/otp/login-with-otp.action";
import { SendOtpAction } from "auth/actions/otp/send-otp.action";
import {
	LoginWithPincode
} from "auth/actions/pincode/login-with-pincode.action";
import { Identity, userRoles } from "auth/auth.entities";
import { useHasUserRole } from "auth/auth.hooks";
import {
	Backout, Button, Card, CardContent, CardHeader, Column, ExtPhoneField, Form,
	FormButton, Row, TextInputField
} from "common/components";
import Toast from "common/components/toast";
import {
	formatExPhone, isFailed, isNotSuccessful, isSuccessful, useApiErrors,
	useFormData, useInitial
} from "common/utils";

import styles from "./styles.module.scss";


export const IdentityContext = createContext<Identity | null>(null);

export default function AuthGuard({ roles, layout }: AuthGuardProps) {
  const { t, i18n } = useTranslation();
  const initial = useInitial();
  const dispatch = useDispatch();
  const hasUserRole = useHasUserRole();

  const [showPincodeForm, setShowPincodeForm] = useState(false);
  const [showOtpSendForm, setShowOtpSendForm] = useState(false);
  const [showOtpLoginForm, setShowOtpLoginForm] = useState(false);

  // api state
  const loginState = useSelector((state: ApiState) => state.auth.login);
  const sendOtpState = useSelector((state: ApiState) => state.auth.otp.send);

  // form data
  const { formData, onChange, formErrors, setFormErrors } = useFormData<any>(null, {}, []);
  useApiErrors(loginState, setFormErrors);
  useApiErrors(sendOtpState, setFormErrors);

  useEffect(() => {
    if (!initial && isFailed(loginState)) {
      if (loginState.error!.code === 'invalid-pincode') {
        setFormErrors({
          pincode: ['ApiErrors.invalid-pincode', {
            count: loginState.error!.attemptsLeft
          }]
        });
      } else {
        Toast.showApiError(loginState.error!);
      }
    }
  }, [initial, loginState]);

  useEffect(() => {
    if (!initial && isSuccessful(sendOtpState)) {
      setShowOtpSendForm(false);
      setShowOtpLoginForm(true);
    }
  }, [initial, sendOtpState]);

  // rate limiter
  const [sendOtpCodeIn, setSendOtpCodeIn] = useState<number | null>(null);
  useEffect(() => {
    if (sendOtpState.error?.code === 'too-many-requests') {
      const seconds = moment.utc(sendOtpState.error['until']).diff(moment.utc(), 'seconds');
      if (seconds < 1) {
        setSendOtpCodeIn(null);
      } else {
        setTimeout(() => setSendOtpCodeIn(seconds), 1000);
      }
    } else {
      setSendOtpCodeIn(null);
    }
  }, [sendOtpState, sendOtpCodeIn])

  const [verifyOtpCodeIn, setVerifyOtpCodeIn] = useState<number | null>(null);
  useEffect(() => {
    if (loginState.error?.code === 'too-many-requests') {
      const seconds = moment.utc(loginState.error['until']).diff(moment.utc(), 'seconds');
      if (seconds < 1) {
        setVerifyOtpCodeIn(null);
      } else {
        setTimeout(() => setVerifyOtpCodeIn(seconds), 1000);
      }
    } else {
      setVerifyOtpCodeIn(null);
    }
  }, [loginState, verifyOtpCodeIn])

  let child: JSX.Element;
  if (isNotSuccessful(loginState)) {
    child = (
      <div className="fullscreenGuard">
        <Card elevation={2} className={styles.card}>
          <Row>
            <Column>
              <CardHeader
                title={
                  showOtpSendForm || showOtpLoginForm ? t("Otp.oneTimePassword")
                    : showPincodeForm ? t("pincode") : t("Otp.signInSignUp")
                }
                left={showPincodeForm || showOtpSendForm || showOtpLoginForm ? (
                  <div style={{ marginInlineStart: -10 }}>
                    <Button
                      minimal
                      icon="arrow-left"
                      onClick={() => {
                        setShowPincodeForm(false);
                        setShowOtpSendForm(showOtpLoginForm);
                        setShowOtpLoginForm(false);
                      }} />
                  </div>
                ) : null}
                right={showPincodeForm ? (
                  <div style={{ width: 10 }} />
                ) : null}
              />
              <CardContent>
                <Form className={styles.form}>
                  <div
                    className={styles.formSection}
                    style={{ height: showOtpLoginForm || showOtpSendForm || showPincodeForm ? 0 : undefined }}>
                    <FormButton outlined
                      key="googleButton"
                      className={styles.continueButton}
                      icon={<FcGoogle size={18} />}
                      text={["Otp.continiueWithGoogle"]}
                      onClick={() => {
                        const redirectUrl = new URL('/on-login', window.location.href);
                        const googleAuthUrl = new URL('/auth/google/login', process.env.REACT_APP_API_URL!);
                        googleAuthUrl.searchParams.append('apiKey', process.env.REACT_APP_API_KEY!)
                        googleAuthUrl.searchParams.append('redirectTo', redirectUrl.toString());
                        window.open(googleAuthUrl.toString(), '_self');
                      }}
                    />
                    <FormButton outlined
                      key="appleButton"
                      className={styles.continueButton}
                      icon={<FaApple size={18} />}
                      text={["Otp.continiueWithApple"]}
                      onClick={() => {
                        const redirectUrl = new URL('/on-login', window.location.href);
                        const googleAuthUrl = new URL('/auth/apple/login', process.env.REACT_APP_API_URL!);
                        googleAuthUrl.searchParams.append('apiKey', process.env.REACT_APP_API_KEY!)
                        googleAuthUrl.searchParams.append('redirectTo', redirectUrl.toString());
                        window.open(googleAuthUrl.toString(), '_self');
                      }}
                    />
                    <FormButton outlined
                      key="pincodeButton"
                      className={styles.continueButton}
                      icon={<MdPassword size={18} />}
                      text={["Otp.continiueWithPincode"]}
                      onClick={() => setShowPincodeForm(true)}
                    />
                    <FormButton outlined
                      key="otpButton"
                      className={styles.continueButton}
                      icon={<FaRegEnvelope size={18} />}
                      text={["Otp.continiueWithOtp"]}
                      onClick={() => setShowOtpSendForm(true)}
                    />
                  </div>
                  <div className={styles.formSection} style={{ height: showPincodeForm ? undefined : 0 }}>
                    <TextInputField
                      autoFocus
                      maxLength={50}
                      name="username"
                      label={["Otp.username"]}
                      placeholder={["Otp.usernamePlaceholder"]}
                      autoCapitalize="none"
                      value={formData.username}
                      error={formErrors.username}
                      readOnly={loginState.isLoading}
                      onChange={onChange}
                    />
                    <TextInputField
                      key="pincodeInput"
                      maxLength={6}
                      name="pincode"
                      label={["Otp.pincode"]}
                      placeholder={["Otp.pincodePlaceholder"]}
                      readOnly={loginState.isLoading}
                      value={formData.pincode}
                      error={formErrors.pincode}
                      type="password"
                      onChange={onChange}
                      onSubmit={() => {
                        if (!_.isEmpty(formData.username) && !_.isEmpty(formData.pincode))
                          dispatch(LoginWithPincode(formData.username, formData.pincode))
                      }}
                    />
                    <FormButton
                      outlined
                      text={["continue"]}
                      loading={loginState.isLoading}
                      disabled={_.isEmpty(formData.username) || _.isEmpty(formData.pincode)}
                      onClick={() => {
                        dispatch(LoginWithPincode(formData.username, formData.pincode))
                      }} />
                  </div>
                  <div className={styles.formSection} style={{ height: showOtpSendForm ? undefined : 0 }}>
                    <ExtPhoneField
                      name="phone"
                      placeholder={["phoneNumber"]}
                      value={formData.phone}
                      error={formErrors?.phone}
                      onChange={(e) => {
                        onChange(e, {
                          target: {
                            name: 'email',
                            value: '',
                          }
                        });
                      }}
                      onSubmit={() => {
                        dispatch(SendOtpAction(formData.phone, i18n.language))
                      }}
                    />
                    <span className={styles.otpOrLabel}>{t('or', { postProcess: 'uppercase' })}</span>
                    <TextInputField
                      maxLength={50}
                      name="email"
                      placeholder={["email"]}
                      value={formData.email}
                      error={formErrors?.email}
                      onChange={(e) => {
                        onChange(e, {
                          target: {
                            name: 'phone',
                            value: {
                              code: formData.phone?.code,
                              value: '',
                            }
                          }
                        });
                        if (formErrors?.phone) {
                          setFormErrors({ ...formErrors, phone: null });
                        }
                      }}
                      onSubmit={() => {
                        dispatch(SendOtpAction(formData.email, i18n.language))
                      }}
                    />
                    <div style={{ height: 15 }} />
                    <FormButton
                      outlined
                      text={sendOtpCodeIn != null ? ["continueIn", { seconds: sendOtpCodeIn }] : ["continue"]}
                      loading={sendOtpState.isLoading}
                      disabled={sendOtpCodeIn != null || _.isEmpty(formData.phone?.number) && _.isEmpty(formData.email)}
                      onClick={() => {
                        if (_.isEmpty(formData.email)) {
                          dispatch(SendOtpAction(formData.phone, i18n.language))
                        } else {
                          dispatch(SendOtpAction(formData.email, i18n.language))
                        }
                      }} />
                  </div>
                  <div className={styles.formSection} style={{ height: showOtpLoginForm ? 210 : 0 }}>
                    <Callout intent="success" style={{ maxWidth: 260, marginBottom: 20 }}>
                      {t('Otp.enterCodeCallout', {
                        destination: _.isEmpty(formData.email) ? formatExPhone(formData.phone) : formData.email,
                        expiresIn: sendOtpState.value?.expiresIn,
                      })}
                    </Callout>
                    <TextInputField
                      autoFocus
                      maxLength={6}
                      name="otp"
                      placeholder={["Otp.verificationCode"]}
                      autoCapitalize="none"
                      value={formData.otp}
                      error={formErrors.otp}
                      readOnly={loginState.isLoading}
                      onChange={onChange}
                      onSubmit={() => {
                        dispatch(LoginWithOtpAction(sendOtpState.value!.token, formData.otp))
                      }}
                    />
                    <FormButton
                      outlined
                      text={verifyOtpCodeIn != null ? ["continueIn", { seconds: verifyOtpCodeIn }] : ["continue"]}
                      loading={loginState.isLoading}
                      disabled={verifyOtpCodeIn != null || _.isEmpty(formData.otp)}
                      onClick={() => {
                        dispatch(LoginWithOtpAction(sendOtpState.value!.token, formData.otp))
                      }} />
                  </div>
                </Form >
              </CardContent>
            </Column>
            <div className={styles.logobox}>
              <img
                src="/images/logo512.png"
                alt="logo"
              />
              <img
                src="/images/title1024.png"
                alt="title"
              />
            </div>
          </Row>
        </Card>
      </div >
    );
  } else if (roles?.length && !hasUserRole(...roles)) {
    child = (
      <div className="fullscreenGuard">
        <Backout
          icon="lock"
          title={["accessDenied"]}
          message={["youDontHavePermission"]} />
      </div>
    );
  } else {
    child = (
      <IdentityContext.Provider value={loginState.value!}>
        <Outlet />
      </IdentityContext.Provider>
    );
  }

  return layout == null ? child : layout(child);
}

export interface AuthGuardProps {
  roles?: typeof userRoles[number][];
  layout?: (child: JSX.Element | null) => JSX.Element;
}