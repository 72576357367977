/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { CurrentBusinessChanged } from "business/business.store";
import { ApiCallState } from "common/utils/api-state";
import { BasicSalesReport, PeriodicalSalesReport } from "report/report.entities";


export const ReportStore = createSlice({
  name: "report",
  initialState: {
    loadBasicSalesReport: {} as ApiCallState<BasicSalesReport>,
    loadDailySalesReport: {} as ApiCallState<PeriodicalSalesReport>,
    loadWeeklySalesReport: {} as ApiCallState<PeriodicalSalesReport>,
    loadMonthlySalesReport: {} as ApiCallState<PeriodicalSalesReport>,
  },
  reducers: {
    ChangeLoadBasicSalesReportState: (
      state,
      action: { payload: ApiCallState<BasicSalesReport> },
    ) => {
      state.loadBasicSalesReport = action.payload;
    },
    ChangeLoadDailySalesReportState: (
      state,
      action: { payload: ApiCallState<PeriodicalSalesReport> },
    ) => {
      state.loadDailySalesReport = action.payload;
    },
    ChangeLoadWeeklySalesReportState: (
      state,
      action: { payload: ApiCallState<PeriodicalSalesReport> },
    ) => {
      state.loadWeeklySalesReport = action.payload;
    },
    ChangeLoadMonthlySalesReportState: (
      state,
      action: { payload: ApiCallState<PeriodicalSalesReport> },
    ) => {
      state.loadMonthlySalesReport = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, getInitialState);
    builder.addCase(CurrentBusinessChanged, getInitialState);
  },
});

export const {
  ChangeLoadBasicSalesReportState,
  ChangeLoadDailySalesReportState,
  ChangeLoadWeeklySalesReportState,
  ChangeLoadMonthlySalesReportState,
} = ReportStore.actions;

export const { reducer: reportReducer, getInitialState } = ReportStore;

export type ReportState = ReturnType<typeof ReportStore.getInitialState>
