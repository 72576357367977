/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { TextArea, TextAreaProps } from "@blueprintjs/core";

import { useI18n } from "common/i18n/hooks";
import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";


export default function TextAreaField({
  className, label, hidden, disabled, value, error, placeholder,
  ...rest
}: FormTextAreaProps) {
  const placeholderText = useI18n(placeholder);

  return (
    <FormField
      className={[styles.textareafield, className].join(" ")}
      label={label}
      disabled={disabled}
      hidden={hidden}
      error={error}
    >
      <TextArea
        fill
        disabled={disabled}
        intent={error ? "danger" : undefined}
        placeholder={placeholderText}
        value={value || ''}
        {...rest}
      />
    </FormField>
  );
}


export type FormTextAreaProps = FormFieldProps &
  Omit<TextAreaProps, "value" | "placeholder"> & {
    value?: string,
    placeholder?: string | [string, object?, string?],
  }