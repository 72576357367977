/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { LoginState } from "auth/auth.store";
import { ApiAction, failed, loading, success } from "common/utils";


export function LoginWithPincode(username: string, pincode: string): ApiAction {
  return async (dispatch, _getState, api) => {
    dispatch(LoginState(loading()));
    try {
      const { identity } = await api.get("/auth/pincode", {
        params: {
          username: username.trim(),
          pincode,
          apiKey: process.env.REACT_APP_API_KEY,
        }
      });
      dispatch(LoginState(success(identity!)));
    } catch (error) {
      dispatch(LoginState(failed(error)));
    }
  };
}