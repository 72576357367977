/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ExtPhone } from "common/common.entities";
import { ExImage } from "media/media.entities";


export const employeeRoles = [
  "admin",   // full access
  "manager", // has access to orders, menu and branches
  "employee",  // has aceess to menu and order
] as const;

export interface Employee {
  id: string;
  active: boolean;
  role: typeof employeeRoles[number];
  branches: string[];
  name: string;
  phone?: ExtPhone;
  email?: string;
  picture?: ExImage;
}