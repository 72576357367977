/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { TagProps } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { Tag } from 'common/components';
import { Order } from "order/order.entities";
import styles from './styles.module.scss';

export default function ({
  className, order, lowercase, children, ...rest
}: OrderPaymentTagProps) {
  const { t } = useTranslation();
  const intent = ['authorised', 'paid'].includes(order.payment?.intent?.status!)
    ? "success"
    : order.payment?.method == null || ['payment-failed', 'refunded', 'cancelled'].includes(order.payment?.intent?.status!)
      ? 'danger'
      : order.payment?.method?.offline || ['pending', 'unpaid'].includes(order.payment?.intent?.status!)
        ? 'warning'
        : "none";
  return (
    <Tag
      minimal
      className={styles.orderStatusTag}
      intent={intent}>
      <>
        <span>
          {order.payment?.method?.offline == true
            ? t(`Pricing.PaymentMethods.${order.payment?.method?.type || 'none'}`)
            : t(`Pricing.PaymentStatus.${order.payment?.intent?.status || 'none'}`)}
        </span>
        <span>
          {order.payment?.method == null || order.payment?.method?.offline == true
            ? ''
            : ` (${t(`Pricing.PaymentMethods.${order.payment?.method?.type || 'none'}`)})`}
        </span>
        {children}
      </>
    </Tag>
  );
}

export type OrderPaymentTagProps = TagProps & {
  order: Order,
  lowercase?: boolean,
};