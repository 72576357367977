import _ from "lodash";

/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as BluePrint from "@blueprintjs/core";
import { CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";


export default function Button({
  id, className, text, hidden, animateVisibility = true, loading,
  loadingAnimation = "waves", style, ...rest
}: ButtonProps) {
  if (hidden && !animateVisibility) return null;

  const { t, i18n } = useTranslation();
  const buttonText = useMemo(() =>
    _.isArray(text) ? t(text[0], text[2], text[1]) : text,
    [text, i18n.language]);
  const classes = useMemo(() => {
    const cls = [styles.button];
    if (className) cls.push(className);
    if (loading && loadingAnimation === "waves") cls.push(styles.loading)
    if (hidden && animateVisibility)
      cls.push(styles.hidden);
    return cls.join(" ").trim();
  }, [className, hidden, loading, animateVisibility]);

  return (
    <BluePrint.Button
      id={id}
      className={classes}
      style={style}
      loading={loadingAnimation === "circle" && loading}
      {...rest}>
      {buttonText}
    </BluePrint.Button>
  );
}


export type ButtonProps = Omit<BluePrint.ButtonProps, "text" | "hidden"> & {
  id?: string,
  className?: string,
  text?: string | [string, object?, string?],
  hidden?: boolean,
  animateVisibility?: boolean,
  loadingAnimation?: "waves" | "circle",
  style?: CSSProperties,
}