/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiBookLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { Catalog } from "catalog/catalog.entities";
import { useSyncCatalogs } from "catalog/catalog.hooks";
import CatalogCard from "catalog/components/CatalogCard";
import {
	BackAsyncError, Backout, Button, Callout, Grid, GridContentLoader
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { ApiCallStateBuilder } from "common/utils";

import styles from "./styles.module.scss";


export default function CatalogsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const { syncCatalogsState, catalogs } = useSyncCatalogs({
    filter: (catalog) =>
      _.isEmpty(searchQuery) || _.values(catalog).join(" ").toLowerCase().includes(searchQuery!),
  }, [searchQuery]);

  return (
    <>
      <PageHeader
        icon={<RiBookLine size={23} style={{ marginInlineEnd: 1, marginTop: -1 }} />}
        title={['catalogs']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text={["create"]}
          intent="success"
          onClick={() => navigate('new')}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder<Catalog[]>
          state={syncCatalogsState}
          onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueEmpty={() => (
            <>
              <Callout intent='primary' style={{ marginBottom: 30 }}>
                {t("Catalog.ListPage.welcomeCallout")}
              </Callout>
              <div style={{ marginTop: 150 }}>
                <Backout
                  icon={
                    <RiBookLine style={{ marginTop: -3, marginInlineStart: -2 }} />
                  }
                  title={["Catalog.ListPage.emptyBackoutTitle"]}>
                  <div style={{ marginTop: 20 }}>
                    <Button
                      outlined
                      text={["Create"]}
                      onClick={() => navigate("/catalogs/new")} />
                  </div>
                </Backout>
              </div>
            </>
          )}
          onValueNotEmpty={() =>
            <Grid md={3} sm={2} xs={1} gap={20} >
              {catalogs.map(catalog =>
                <CatalogCard
                  interactive
                  key={catalog.id}
                  className={styles.card}
                  onClick={() => navigate(catalog.id!)}
                  value={catalog} />
              )}
            </Grid>
          }
        />
      </PageContent>
    </>
  );
}
