/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { InputGroup, InputGroupProps2 } from "@blueprintjs/core";

import { useI18n } from "common/i18n/hooks";

import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";


export default function FormTextInput({
  className, label, hidden, disabled, error, onSubmit,
  value, actions, startElement, endElement, placeholder, tooltip,
  selectAllOnFocus = false,
  ...rest
}: FormTextInputProps) {
  const rtl = document.body.classList.contains('rtl');
  const placeholderValue = useI18n(placeholder);

  if (onSubmit) rest.onKeyUp = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onSubmit(e);
    }
  };

  return (
    <FormField
      className={[styles.textInputField, className].join(" ")}
      label={label}
      disabled={disabled}
      hidden={hidden}
      error={error}
      tooltip={tooltip}
      actions={actions}>
      <InputGroup
        disabled={disabled}
        intent={error ? "danger" : undefined}
        value={value || ''}
        placeholder={placeholderValue}
        leftElement={rtl ? endElement : startElement}
        rightElement={rtl ? startElement : endElement}
        onFocus={(e) => {
          if (selectAllOnFocus)
            setTimeout(() => e.target.select(), 100);
        }}
        {...rest}
      />
    </FormField>
  );
}



export type FormTextInputProps = FormFieldProps & Omit<InputGroupProps2, "value" | "placeholder"> & {
  value?: string,
  startElement?: JSX.Element,
  endElement?: JSX.Element,
  placeholder?: string | [string, object?, string?],
  selectAllOnFocus?: boolean,
}
