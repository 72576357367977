/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ImportCatalog } from "catalog/catalog.entities";
import { ImportMenuState } from "catalog/catalog.store";
import { ApiAction, failed, loading, success } from "common/utils";


export function importMenu(data: ImportCatalog): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(ImportMenuState(loading()));
    try {
      const { value } = await api.put('/catalogs', { data })
      dispatch(ImportMenuState(success(value.entity)));
    } catch (error) {
      dispatch(ImportMenuState(failed(error)));
    }
  };
}
