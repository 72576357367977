/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { useLocation, useParams } from 'react-router-dom';

import {
  BackActivityIndicator, BackAsyncError,
  Button, Card, CardContent, CardHeader,
  Grid, PageContent, PageHeader, PageNotFound
} from 'common/components';
import {
  ApiCallStateBuilder, isLoading, useApiState, useFormData,
  useApiErrors
} from 'common/utils';
import { UpdateMerchantAccount } from 'merchant/common/actions/update-merchant-account.action';
import MerchantAccountForm from 'merchant/common/components/MerchantAccountForm';
import { MerchantAccount } from 'merchant/merchant.entities';
import { useSyncMerchantAccounts } from 'merchant/merchant.hooks';


export default function BasicMerchantAccountPage() {
  const { id } = useParams();
  const { state } = useLocation();

  // store
  const { syncMerchantAccountsState, merchant } = useSyncMerchantAccounts({
    find: merchant => merchant.id === id,
  });
  const [saveState, dispatch] = useApiState((store) => store.merchant.save);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<MerchantAccount>(merchant, null, [merchant]);
  useApiErrors(saveState, setFormErrors);

  return (
    <>
      <PageHeader
        title={merchant?.name || ''}
        backButtonPath={state?.backpath || "/merchants"}>
        <Button
          text={["save"]}
          intent="success"
          onClick={() => dispatch(UpdateMerchantAccount(merchant!, changes))}
          hidden={merchant == null || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder state={syncMerchantAccountsState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValue={() => {
            if (!merchant)
              return <PageNotFound />
            return (
              <Grid md={2} xs={1} gap={20}>
                <Card>
                  <CardHeader title={["general"]} />
                  <CardContent>
                    <MerchantAccountForm
                      value={formData}
                      errors={formErrors}
                      readOnly={isLoading(saveState)}
                      onChange={onChange}
                    />
                  </CardContent>
                </Card>
              </Grid>
            );
          }}
        />
      </PageContent>
    </>
  );
}
