/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction } from "common/utils";


export function UpdateSessionLocaleAction(locale: string): ApiAction {
  return async (dispatch, store, api) => {
    try {
      await api.patch("/auth/session", { data: { locale } })
    } catch (error) {
    }
  };
}
