/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Profile } from "auth/auth.entities";
import { ApiAction, failed, loading, success } from "common/utils";

import { LoginState } from "auth/auth.store";
import { UpdateSaveProfileState } from "../auth.store";


export function UpdateProfileAction(changes: Partial<Profile>): ApiAction {
  return async (dispatch, store, api) => {
    dispatch(UpdateSaveProfileState(loading()));
    try {
      const { value } = await api.put(`/auth/user`, {
        data: changes,
      })
      dispatch(UpdateSaveProfileState(success(value)));
      // update login state
      const identity = store().auth.login.value!;
      dispatch(LoginState(success({ ...identity, user: value })));
    } catch (error) {
      dispatch(UpdateSaveProfileState(failed(error)));
    }
  };
}
