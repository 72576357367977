/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { isEmpty, ApiSyncController, useApiState } from "common/utils";
import { SyncMerchantAccounts } from "merchant/common/actions/sync-merchant-accounts.action";
import { MerchantAccount } from "merchant/merchant.entities";


export function useSyncMerchantAccounts<T extends MerchantAccount = MerchantAccount>(opts: {
  auto?: boolean,
  find?: (value: MerchantAccount, index: number, array: MerchantAccount[]) => boolean,
  filter?: (value: MerchantAccount, index: number, array: MerchantAccount[]) => boolean,
} = {}, deps: any[] = []) {
  const [syncMerchantAccountsState, dispatch] = useApiState((state) => state.merchant.sync);

  const syncMerchantAccounts = useCallback(() => {
    const unsub = new ApiSyncController();
    dispatch(SyncMerchantAccounts(unsub));
    return unsub.cancel;
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(syncMerchantAccountsState))
      syncMerchantAccounts();
  }, [opts.auto, syncMerchantAccountsState])

  const merchants = useMemo(() =>
    ((opts.filter == null
      ? syncMerchantAccountsState.value
      : syncMerchantAccountsState.value?.filter(opts.filter)) || []) as T[],
    [syncMerchantAccountsState, opts.filter, ...deps]);

  const merchant = useMemo(() =>
    opts.find == null
      ? null
      : syncMerchantAccountsState.value?.find(opts.find) as T,
    [syncMerchantAccountsState, opts.find, ...deps]);

  return { syncMerchantAccountsState, syncMerchantAccounts, merchants, merchant };
}