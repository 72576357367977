/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import styles from "./styles.module.sass";


export default function Center({
  className, children,
}: CenterProps) {
  return (
    <div className={[styles.center, className].join(" ")}>
      {children}
    </div>
  );
}
export type CenterProps = React.ComponentPropsWithRef<"div">