/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { TagProps } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { Tag } from 'common/components';
import { Order, PickupOrder } from "order/order.entities";
import styles from './styles.module.scss';

export default function ({
  className, order, lowercase, children, ...rest
}: OrderTypeTagProps) {
  const { t } = useTranslation();
  const intent = order.type == 'dine-in' ? 'success' :
    (order as PickupOrder).pickupMethod === 'in-store' ? "none" : 'warning';
  let text = t(`Order.PickupMethod.${order.type == 'dine-in' ? 'dine-in' : (order as PickupOrder).pickupMethod}`);
  if (lowercase)
    text = text.toLowerCase();
  return (
    <Tag
      minimal
      className={[className, styles.orderTypeTag].join(' ')}
      intent={intent}
      {...rest}>
      {text}
      {children}
    </Tag>
  );
}

export type OrderTypeTagProps = TagProps & {
  order: Order,
  lowercase?: boolean,
  suffix?: boolean,
};