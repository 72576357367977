/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import {
	HTMLSelectProps, InputGroup, InputGroupProps2
} from "@blueprintjs/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useIdentity } from "auth/auth.hooks";
import { ExtPhone } from "common/common.entities";
import { Button } from "common/components";
import { useI18n } from "common/i18n/hooks";
import {
	isFailed, isSuccessful, OnChangeEventHandler, useInitial
} from "common/utils";
import { useGetCountries } from "directory/directory.hooks";
import { useGeocodeIp } from "geo/geo.hooks";

import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";


export default function ({
  className, name, label, hidden, disabled, error, onSubmit,
  value, actions, endElement, placeholder, selectAllOnFocus = false,
  onChange, selectProps, tooltip, ...rest
}: ExtPhoneFieldProps) {
  const rtl = document.body.classList.contains('rtl');
  const { t } = useTranslation();
  const initial = useInitial();
  const identity = useIdentity();
  const placeholderText = useI18n(placeholder);

  const ipGeoState = useGeocodeIp({ auto: identity?.business?.country == null });
  const { getCountriesState, getCountries, countries, country } = useGetCountries({
    find: (item) => item.id === identity?.business?.country || item.code === ipGeoState.value?.countryCode,
  }, [identity, ipGeoState])

  const [getCountriesError, dropdownOptions] = useMemo(() => {
    const dropdownOptions = new Array<JSX.Element>();
    let dialingCodes = new Set<string>();
    let error: string | undefined = undefined;
    if (isSuccessful(getCountriesState)) {
      getCountriesState.value!.forEach(country => {
        dialingCodes.add(country.dialingCode);
      });
      Array.from(dialingCodes).sort().forEach((code, index) =>
        dropdownOptions.push(<option key={`option_${index}`} value={code}>{code}</option>));
    } else if (isFailed(getCountriesState)) {
      const apiError = getCountriesState.error!;
      const params = _.omit(apiError, "code", "message");
      error = t(`ApiErrors.${apiError.code}`, apiError.code, params);
    } else {
      dropdownOptions.push(<option key={`placeholder`} disabled value="">...</option>);
    }
    return [error, dropdownOptions];
  }, [initial, getCountriesState])

  if (onSubmit) rest.onKeyUp = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onSubmit(e);
    }
  };

  const startElement = (
    <select
      className={['ext-phone-select', getCountriesState.isLoading ? 'loading' : ''].join(' ')}
      value={value?.code || country?.dialingCode}
      defaultValue=""
      disabled={rest.readOnly || disabled || getCountriesState.isLoading}
      onChange={({ target }) => {
        const selected = countries.find(o => o.dialingCode === target.value);
        if (selected && onChange && name)
          onChange({
            target: {
              name,
              value: {
                code: selected.dialingCode,
                number: value?.number || '',
              }
            }
          });
      }}
      {...selectProps}>
      {dropdownOptions}
    </select>
  );

  return (
    <FormField
      className={[styles.exPhoneField, "ext-phone-form-field", className].join(" ")}
      label={label}
      disabled={disabled}
      hidden={hidden}
      error={getCountriesError || error?.code || error?.number || error}
      tooltip={tooltip}
      actions={
        <>
          {actions}
          {getCountriesState.error == null ? null : (
            <Button
              minimal
              style={{ marginTop: 3 }}
              icon="repeat"
              intent='danger'
              onClick={() => getCountries()}
            />
          )}
        </>
      }>
      <InputGroup
        maxLength={15}
        disabled={disabled}
        intent={getCountriesError || error ? "danger" : undefined}
        value={value?.number || ''}
        type='phone'
        placeholder={placeholderText}
        leftElement={rtl ? endElement : startElement}
        rightElement={rtl ? startElement : endElement}
        onFocus={(e) => {
          if (selectAllOnFocus)
            setTimeout(() => e.target.select(), 100);
        }}
        onChange={!onChange || !name ? undefined : ({ target }) => {
          onChange({
            target: {
              name,
              value: {
                code: value?.code || country?.dialingCode || '',
                number: target.value,
              }
            }
          });
        }}
        {...rest}
      />
    </FormField>
  );
}

export type ExtPhoneFieldProps = Omit<FormFieldProps, "error"> &
  Omit<InputGroupProps2, "value" | "placeholder" | "leftElement" | "onChange"> & {
    value?: ExtPhone,
    error?: { [K in keyof ExtPhone]: any },
    endElement?: JSX.Element,
    placeholder?: string | [string, object?, string?],
    selectAllOnFocus?: boolean,
    onChange?: OnChangeEventHandler<ExtPhone>,
    selectProps?: Omit<HTMLSelectProps, "onChange" | "options" | "value">,
  }
