/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import React from "react";

import styles from "./styles.module.sass";


export default function PageContent({
  className, hidden, children, ...rest
}: PageContentProps) {
  if (hidden) return null;

  const classes = [styles.pagecontent, className].join(" ");

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
}

export type PageContentProps = {
  className?: string,
  hidden?: boolean,
  children?: React.ReactNode,
}