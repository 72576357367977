/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as Blueprint from "@blueprintjs/core";
import _ from "lodash";
import { Translation } from "react-i18next";

import { ApiError } from "common/utils";
import styles from "./styles.module.sass";


export type ToastProps = Blueprint.ToastProps & {
  message: string | [string, object?, string?]
}

class ToasterWrapper {
  private toaster: Blueprint.ToasterInstance;

  constructor() {
    this.toaster = Blueprint.OverlayToaster.create({
      className: styles.toaster,
      maxToasts: 1,
    });
  }

  public showApiError = (error: ApiError, { ...rest } = {}) => {
    const params = _.omit(error, "code", "message");
    const message: [string, object?, string?] =
      [`ApiErrors.${error.code}`, params, error.message || error.code];
    const icon = error.code === "http-error" || error.code === "ws-error"
      ? "offline" : "warning-sign";
    this.show({
      icon,
      intent: "danger",
      message,
      ...rest,
    });
  };

  public showSuccess(props: ToastProps, key?: string | undefined) {
    this.show({
      icon: "confirm",
      intent: "success",
      ...props,
    }, key);
  };

  public showWarning(props: ToastProps, key?: string | undefined) {
    this.show({
      icon: "confirm",
      intent: "warning",
      ...props,
    }, key);
  };

  public showInfo(props: ToastProps, key?: string | undefined) {
    this.show({
      icon: "confirm",
      intent: "none",
      ...props,
    }, key);
  };

  public showPrimary(props: ToastProps, key?: string | undefined) {
    this.show({
      icon: "confirm",
      intent: "primary",
      ...props,
    }, key);
  };

  public showDanger(props: ToastProps, key?: string | undefined) {
    this.show({
      icon: "confirm",
      intent: "danger",
      ...props,
    }, key);
  };

  private show(props: ToastProps, key?: string | undefined) {
    const message = _.isArray(props.message) ? (
      <Translation>
        {t => t(props.message[0], props.message[2], props.message[1])}
      </Translation>
    ) : props.message;
    this.toaster.show({ ...props, message }, key);
  }
}

const Toast = new ToasterWrapper();

export default Toast;
