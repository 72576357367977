/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ApiAction, failed, loading, success } from "common/utils";

import { GetCountriesState } from "../directory.store";


export function GetCountriesAction(): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(GetCountriesState(loading()));
    try {
      const { value } = await api.get("/directory/countries");
      dispatch(GetCountriesState(success(value)));
    } catch (error) {
      dispatch(GetCountriesState(failed(error)));
    }
  };
}
