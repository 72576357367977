/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as _ from 'lodash';
import PropTypes, { InferProps } from "prop-types";

import styles from "./styles.module.sass";
import { useMemo } from 'react';


export default function Avatar({
  className, hidden, name, picture, size = 40, grayscale, ...rest
}: AvatarProps) {
  if (hidden) return null;

  const classes = useMemo(() => {
    const classes = [styles.container, className]
    if (grayscale)
      classes.push(styles.grayscale)
    return classes.join(" ").trim();
  }, [className, grayscale]);

  return (
    <div className={classes} {...rest}
      style={{ width: size!, height: size! }}>
      {name &&
        <span style={{ fontSize: size! * 0.45 }}>{name.split(' ').map(part => part ? part[0].toUpperCase() : '').splice(0, 2)}</span>
      }
      {picture &&
        <img alt="" src={picture} width={size! - 1} height={size! - 1}
          onError={(event: any) => event.target.style.display = 'none'} />
      }
    </div>
  );
}

Avatar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  picture: PropTypes.string,
  size: PropTypes.number,
  hidden: PropTypes.bool,
  grayscale: PropTypes.bool,
};

export type AvatarProps = InferProps<typeof Avatar.propTypes>