/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction, failed, loading, success } from "common/utils";
import { DeleteMerchantAccountState } from "../../merchant.store";


export function DeleteMerchantAccount(id: string): ApiAction {
  return async (dispatch, store, api) => {
    dispatch(DeleteMerchantAccountState(loading()));
    try {
      const { value } = await api.delete(`/pricing/merchants/${id}`);
      dispatch(DeleteMerchantAccountState(success(value)));
    } catch (error) {
      dispatch(DeleteMerchantAccountState(failed(error)));
    }
  };
}
