/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useContext } from "react";

import { userRoles } from "auth/auth.entities";
import { IdentityContext } from "auth/guards/Auth";
import { employeeRoles } from "employee/employee.entities";


export function useIdentity() {
  const identity = useContext(IdentityContext);
  return identity;
}

export function useHasUserRole() {
  const identity = useIdentity()
  return useCallback((...roles: typeof userRoles[number][]) =>
    identity != null && ['admin', ...roles].includes(identity.user.role),
    [identity]);
}

export function useHasEmployeeRole() {
  const identity = useIdentity()
  return useCallback((...roles: typeof employeeRoles[number][]) =>
    identity?.business != null &&
    (identity?.business?.employee == null || roles.includes(identity.business.employee.role)),
    [identity]);
}