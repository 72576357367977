/* 
 * Copyright (C) Deeprake.ru - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Popover2Props } from "@blueprintjs/popover2";

import { Branch } from "branch/branch.entities";
import { Menu, MenuItem } from "common/components";
import { OnChangeEventHandler } from "common/utils";


export default function BranchCatalogMenu({
  branch, catalogIndex, onChange,
}: BranchCatalogMenuProps) {
  return (
    <Menu>
      <MenuItem
        key="add"
        icon="plus"
        text={["Branch.CatalogMenu.add"]}
        onClick={() => {
          const value = branch.catalogs!.slice();
          value.splice(catalogIndex + 1, 0, {
            available: true,
            catalog: '',
            merchant: '',
          });
          onChange({
            target: {
              name: 'catalogs',
              value: value,
            },
          })
        }} />
      {branch.catalogs[catalogIndex].schedule != null &&
        <MenuItem
          key="remove-time-schedule"
          icon="th-disconnect"
          text={["Branch.CatalogMenu.deleteTimeSchedule"]}
          onClick={() => {
            const value = branch.catalogs.slice();
            value[catalogIndex] = {
              ...value[catalogIndex],
              schedule: undefined,
            }
            onChange({
              target: {
                name: 'catalogs',
                value,
              },
            })
          }} />
      }
      {catalogIndex >= 1 &&
        <MenuItem
          key="move-up"
          icon="arrow-up"
          text={["Branch.CatalogMenu.moveUp"]}
          onClick={() => {
            const value = branch.catalogs.slice();
            value.splice(catalogIndex, 1, value.splice(catalogIndex - 1, 1, value[catalogIndex])[0]);
            onChange({
              target: {
                name: 'catalogs',
                value,
              },
            })
          }} />
      }
      {catalogIndex < branch.catalogs.length - 1 &&
        <MenuItem
          key="move-bottom"
          icon="arrow-down"
          text={["Branch.CatalogMenu.moveDown"]}
          onClick={() => {
            const value = branch.catalogs.slice();
            value.splice(catalogIndex, 1, value.splice(catalogIndex + 1, 1, value[catalogIndex])[0]);
            onChange({
              target: {
                name: 'catalogs',
                value,
              },
            })
          }} />
      }
      <MenuItem
        key="delete"
        icon="trash"
        text={["Branch.CatalogMenu.delete"]}
        intent='danger'
        onClick={() => {
          const value = branch.catalogs.slice();
          value.splice(catalogIndex, 1);
          onChange({
            target: {
              name: 'catalogs',
              value,
            },
          })
        }} />
    </Menu>
  );
}

export type BranchCatalogMenuProps = Popover2Props & {
  branch: Branch;
  catalogIndex: number;
  onChange: OnChangeEventHandler<any>,
}