/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useTranslation } from "react-i18next";
import { FaAppStore, FaGooglePlay } from "react-icons/fa";
import { IoIosApps } from "react-icons/io";
import { TbWorldWww } from "react-icons/tb";
import QRCode from "react-qr-code";
import { saveSvgAsPng } from "save-svg-as-png";

import {
	Button, Card, CardContent, CardHeader, Grid, PageContent, PageHeader
} from "common/components";

import styles from "./styles.module.scss";


export default function ApplicationsPage() {
  const { t } = useTranslation(undefined, { keyPrefix: "Applications" });

  return (
    <>
      <PageHeader
        icon={<IoIosApps size={22} style={{ marginInlineEnd: 2, marginTop: -1.5 }} />}
        title={['Applications.header']} >
      </PageHeader>
      <PageContent>
        <Grid md={2} xs={1} gap={20}>
          <Card>
            <CardHeader title={["Applications.Fba.header"]} />
            <CardContent>
              <b>{t("Fba.title")}</b>
              <br />
              <br />
              <p>{t("Fba.desc")}</p>
              <div className={styles.appItem}>
                <FaAppStore size={40} />
                <b>{t("Fba.appStoreTtl")}</b>
                <p>{t("Fba.appStoreDesc")}</p>
                <a className="bp4-button"
                  href="https://apps.apple.com/us/app/geome-drive-thru/id1489848824"
                  target="_blank">{t("downloadForIos")}</a>
              </div>
              <div className={styles.appItem}>
                <FaGooglePlay size={40} />
                <b>{t("Fba.playMarketTtl")}</b>
                <p>{t("Fba.playMarketDesc")}</p>
                <a className="bp4-button"
                  href="https://play.google.com/store/apps/details?id=io.geome.drivethru"
                  target="_blank">{t("downloadForAndroid")}</a>
              </div>
              <div className={styles.appItem}>
                <TbWorldWww size={40} />
                <b>{t("Fba.webTtl")}</b>
                <p>{t("Fba.webDesc")}</p>
                <a className="bp4-button"
                  href="https://staff.dishdash.io"
                  target="_blank">{t("useBrowser")}</a>
              </div>
              <br />
              <div className={styles.appItem}>
                <QRCode
                  id='fba_qr_code'
                  size={100}
                  style={{ height: "100px", width: "100px" }}
                  value={"https://dl.dishdash.io/s/taff"}
                  viewBox={`0 0 350 350`}
                />
                <b>{t("scanQrCode")}</b>
                <p>{t("scanQrCodeDesc")}</p>
                <Button
                  text={t("saveQrCode")}
                  onClick={() => {
                    saveSvgAsPng(
                      document.getElementById('fba_qr_code'),
                      'DishDash Business QR.png',
                      { scale: 10 },
                    );
                  }}
                />
              </div>
              <br />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={["Applications.Fua.header"]} />
            <CardContent>
              <b>{t("Fua.title")}</b>
              <br />
              <br />
              <p>{t("Fua.desc")}</p>
              <div className={styles.appItem}>
                <FaAppStore size={40} />
                <b>{t("Fua.appStoreTtl")}</b>
                <p>{t("Fua.appStoreDesc")}</p>
                <a className="bp4-button"
                  href="https://apps.apple.com/us/app/geome/id1489847032"
                  target="_blank">{t("downloadForIos")}</a>
              </div>
              <div className={styles.appItem}>
                <FaGooglePlay size={40} />
                <b>{t("Fua.playMarketTtl")}</b>
                <p>{t("Fua.playMarketDesc")}</p>
                <a className="bp4-button"
                  href="https://play.google.com/store/apps/details?id=io.geome.user"
                  target="_blank">{t("downloadForAndroid")}</a>
              </div>
              <div className={styles.appItem}>
                <TbWorldWww size={40} />
                <b>{t("Fua.webTtl")}</b>
                <p>{t("Fua.webDesc")}</p>
                <a className="bp4-button"
                  href="https://web.dishdash.io"
                  target="_blank">{t("useBrowser")}</a>
              </div>
              <br />
              <div className={styles.appItem}>
                <QRCode
                  id='fua_qr_code'
                  size={100}
                  style={{ height: "100px", width: "100px" }}
                  value={"https://dl.dishdash.io/u/ser"}
                  viewBox={`0 0 350 350`}
                />
                <b>{t("scanQrCode")}</b>
                <p>{t("scanQrCodeDesc")}</p>
                <Button
                  text={t("saveQrCode")}
                  onClick={() => {
                    saveSvgAsPng(
                      document.getElementById('fua_qr_code'),
                      'DishDash QR.png',
                      { scale: 10 },
                    );
                  }}
                />
              </div>
              <br />
              <br />
            </CardContent>
          </Card>
        </Grid>
      </PageContent>
    </>
  );
}
