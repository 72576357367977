/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ApiAction, failed, loading, success } from "common/utils";
import { Branch } from "branch/branch.entities";
import { SaveBranchState } from "../branch.store";


export function UpdateBranch(branch: Branch, changes: Partial<Branch>): ApiAction {
  return async (dispatch, _getStore, api) => {
    dispatch(SaveBranchState(loading()));
    try {
      let data: any, contentType: string;
      if (changes.image?.file) {
        data = new FormData();
        data.append("image", changes.image.file);
        data.append("data", JSON.stringify(changes));
        contentType = 'multipart/form-data';
      } else {
        data = { data: changes };
        contentType = 'application/json'
      }
      const { value } = await api.put(`/business/branches/${branch.id}`, {
        headers: {
          'Content-Type': contentType,
        },
        data,
      })
      dispatch(SaveBranchState(success(value)));
    } catch (error) {
      dispatch(SaveBranchState(failed(error)));
    }
  };
}
