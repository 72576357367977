/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction, failed, loading, success } from "common/utils";
import { GetCurrentSubscriptionState } from "subscription/subscription.store";


export function GetCurrentSubscription(): ApiAction {
  return async (dispatch, _getStore, api) => {
    dispatch(GetCurrentSubscriptionState(loading()));
    try {
      const { value } = await api.get('/pricing/subscription/?nocache=' + Math.random());
      dispatch(GetCurrentSubscriptionState(success(value)));
    } catch (error) {
      dispatch(GetCurrentSubscriptionState(failed(error)));
    }
  };
}
