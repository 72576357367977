/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { InputGroup } from "@blueprintjs/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegUser } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

import { SendOtpAction } from "auth/actions/otp/send-otp.action";
import { SignOtpValueAction } from "auth/actions/otp/sign-value.action";
import { UpdateProfileAction } from "auth/actions/update-profile.action";
import { Profile } from "auth/auth.entities";
import { useIdentity } from "auth/auth.hooks";
import { UpdateSendOtpState } from "auth/auth.store";
import ProfileForm from "auth/components/ProfileForm";
import { Button, Card, CardContent, Grid, Toast } from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import {
	formatExPhone, isFailed, isLoading, isSuccessful, useApiErrors, useApiState,
	useFormData, useInitial
} from "common/utils";

import styles from "./styles.module.scss";


export default function ProfilePage() {
  const initial = useInitial();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  // store
  const identity = useIdentity();
  const [saveState, dispatch] = useApiState((state) => state.auth.saveProfile);
  const [sendOtpState] = useApiState((state) => state.auth.otp.send);
  const [signOtpValueState] = useApiState((state) => state.auth.otp.signValue);

  // form data
  const { formData, formErrors, setFormErrors, onChange, changes } =
    useFormData<Profile>(identity?.user, null, [identity]);
  useApiErrors(saveState, setFormErrors);

  // otp value tokens
  const [phoneOtpToken, setPhoneOtpToken] = useState<string>('');
  const [emailOtpToken, setEmailOtpToken] = useState<string>('');
  const [phoneOtpCode, setPhoneOtpCode] = useState<string>('');
  const [emailOtpCode, setEmailOtpCode] = useState<string>('');
  const [phoneOtpValueToken, setPhoneOtpValueToken] = useState<string>();
  const [emailOtpValueToken, setEmailOtpValueToken] = useState<string>();
  const [phoneOtpError, setPhoneOtpError] = useState<boolean>(false);
  const [emailOtpError, setEmailOtpError] = useState<boolean>(false);

  useEffect(() => {
    setPhoneOtpToken('');
    setPhoneOtpValueToken(changes.phone?.number?.length ? '' : undefined);
  }, [changes.phone]);
  useEffect(() => {
    setEmailOtpToken('');
    setEmailOtpValueToken(changes.email ? '' : undefined);
  }, [changes.email]);

  // send otp state
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(sendOtpState)) {
        if (changes.phone) {
          Toast.showSuccess({
            message: t('Otp.enterCodeCallout', {
              destination: formatExPhone(changes.phone),
              expiresIn: sendOtpState.value!.expiresIn,
            })
          });
          setPhoneOtpToken(sendOtpState.value!.token);
          setPhoneOtpValueToken('');
        } else {
          Toast.showSuccess({
            message: t('Otp.enterCodeCallout', {
              destination: changes.email,
              expiresIn: sendOtpState.value!.expiresIn,
            })
          });
          setEmailOtpToken(sendOtpState.value!.token);
          setEmailOtpValueToken('');
        }
      } else if (isFailed(sendOtpState)) {
        if (sendOtpState.error?.code === 'constraint-error') {
          Toast.showDanger({
            message: t(changes.phone ? "Profile.ItemPage.phoneAlreadyTaken" : "Profile.ItemPage.emailAlreadyTaken")
          });
        } else {
          Toast.showApiError(sendOtpState.error!);
        }
      }
    }
  }, [sendOtpState]);

  // sign otp value state
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(signOtpValueState)) {
        if (changes.phone) {
          setPhoneOtpValueToken(signOtpValueState.value!);
        } else {
          setEmailOtpValueToken(signOtpValueState.value!);
        }
        save();
      } else if (isFailed(signOtpValueState)) {
        if (changes.phone) {
          setPhoneOtpError(true);
        } else {
          setEmailOtpError(true);
        }
        Toast.showApiError(signOtpValueState.error!);
      }
    }
  }, [signOtpValueState]);

  // save
  const save = useCallback(() => {
    const data = _.cloneDeep(changes);
    if (phoneOtpValueToken === '') {
      if (phoneOtpToken === '') {
        dispatch(SendOtpAction(changes.phone!, i18n.language, true));
        return;
      }
      if (phoneOtpCode.length < 4) {
        setPhoneOtpError(true);
        return;
      }
      dispatch(SignOtpValueAction(phoneOtpToken, phoneOtpCode, changes.phone));
      return;
    } else {
      data['phone'] = phoneOtpValueToken as any;
    }
    if (emailOtpValueToken === '') {
      if (emailOtpToken === '') {
        dispatch(SendOtpAction(changes.email!, i18n.language, true));
        return;
      }
      if (emailOtpCode.length < 4) {
        setEmailOtpError(true);
        return;
      }
      dispatch(SignOtpValueAction(emailOtpToken, emailOtpCode, changes.email));
      return;
    } else {
      data['email'] = emailOtpValueToken as any;
    }
    dispatch(UpdateProfileAction(data));
  }, [changes, emailOtpToken, emailOtpCode, emailOtpValueToken,
    phoneOtpToken, phoneOtpCode, phoneOtpValueToken]);

  // save state
  useEffect(() => {
    if (!initial && identity?.user && isSuccessful(saveState)) {
      Toast.showSuccess({ message: ["Profile.ItemPage.updatedToast"] });
      setEmailOtpToken('');
      setPhoneOtpToken('');
      setEmailOtpCode('');
      setPhoneOtpCode('');
      setEmailOtpValueToken(undefined);
      setPhoneOtpValueToken(undefined);
      dispatch(UpdateSendOtpState({}));
    }
  }, [saveState]);

  return (
    <>
      <PageHeader
        icon={<FaRegUser size={23} style={{ marginTop: -2 }} />}
        title={identity?.user?.name}
        backButtonPath={searchParams.get("backButtonPath")}>
        <Button
          text={["save"]}
          intent="primary"
          onClick={() => save()}
          hidden={_.isEmpty(changes)}
          loading={isLoading(saveState) || isLoading(sendOtpState)}
        />
      </PageHeader>
      <PageContent className={styles.wbProfilePage}>
        <Grid md={2} xs={1} gap={20}>
          <Card elevation={1}>
            <CardContent>
              <ProfileForm
                value={formData}
                errors={formErrors}
                onChange={onChange}
                emailRightElement={emailOtpValueToken == null || sendOtpState.value == null ? undefined : (
                  <InputGroup
                    autoFocus
                    className={styles.otpInput}
                    type="password"
                    leftIcon='lock'
                    intent={emailOtpError ? 'danger' : 'none'}
                    value={emailOtpCode}
                    maxLength={6}
                    placeholder={t("Profile.ItemPage.otpTooltip")}
                    onChange={(e) => {
                      setEmailOtpCode(e.target.value);
                      setEmailOtpError(false);
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        save();
                      }
                    }}
                  />
                )}
                phoneRightElement={phoneOtpToken === '' ? undefined : (
                  <InputGroup
                    autoFocus
                    className={styles.otpInput}
                    type="password"
                    leftIcon='lock'
                    intent={phoneOtpError ? 'danger' : 'none'}
                    value={phoneOtpCode}
                    maxLength={6}
                    placeholder={t("Profile.ItemPage.otpTooltip")}
                    onChange={(e) => {
                      setPhoneOtpCode(e.target.value);
                      setPhoneOtpError(false);
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        save();
                      }
                    }}
                  />
                )}
              />
            </CardContent>
          </Card>
        </Grid>
      </PageContent>
    </>
  );
}
