/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ExtPhone } from "common/common.entities";

export function formatExPhone(phone?: ExtPhone) {
  if (!phone) return null;
  return phone.code + phone.number;
}