/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { TimePicker, TimePickerProps } from "@blueprintjs/datetime";
import { useCallback, useMemo } from "react";

import { Button } from "common/components";
import { OnChangeEventHandler } from "common/utils";
import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.scss";


export default function TimeRangeField({
  className, label, hidden, disabled, errors, name,
  readOnly, value, nullable, onChange, ...rest
}: TimeRangeFieldProps) {
  const classes = useMemo(() => {
    const classes = [styles.timeRangeField, className];
    if (value?.[0] == null)
      classes.push(styles.noFirstValue);
    if (value?.[1] == null)
      classes.push(styles.noSecondValue);
    return classes.join(" ").trim();
  }, [className, value]);

  const onDate1Change = useCallback((val: Date | null) => {
    if (onChange) {
      onChange({ target: { name, value: [val, value?.[1] || null] } });
    }
  }, [onChange])
  const onDate2Change = useCallback((val: Date | null) => {
    if (onChange) {
      onChange({ target: { name, value: [value?.[0] || null, val] } });
    }
  }, [onChange])

  return (
    <FormField
      horizontal
      className={classes}
      label={label}
      disabled={disabled}
      hidden={hidden}
      error={errors?.[0] || errors?.[1]} >
      <TimePicker
        selectAllOnFocus
        className={errors?.[0] ? "error" : undefined}
        disabled={disabled}
        value={value?.[0] || new Date(0, 0, 0, 0, 0, 0, 0)}
        onChange={onDate1Change}
        {...rest}
      />
      <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
      <TimePicker
        selectAllOnFocus
        className={errors?.[1] ? "error" : undefined}
        disabled={disabled}
        value={value?.[1] || new Date(0, 0, 0, 0, 0, 0, 0)}
        onChange={onDate2Change}
        {...rest}
      />
      <Button
        minimal
        icon='cross'
        hidden={!nullable}
        onClick={() => {
          if (onChange) {
            onChange({ target: { name, value: [null, null] } });
          }
        }} />
    </FormField>
  );
}

export type TimeRangeFieldProps = Omit<FormFieldProps, "error"> &
  Omit<TimePickerProps, "value" | "placeholder" | "onChange"> & {
    name: string,
    value?: [Date?, Date?],
    errors?: [any?, any?],
    readOnly?: boolean,
    onChange?: OnChangeEventHandler<[Date | null, Date | null]>,
    nullable?: boolean,
  }