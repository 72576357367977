/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from "moment";

import { Icon, Menu, MenuProps } from "@blueprintjs/core";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineBank } from "react-icons/ai";
import { BiCategory, BiMedal, BiStore } from "react-icons/bi";
import { BsBoxSeam } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { IoIosApps } from "react-icons/io";
import { LuLanguages, LuPackageOpen, LuPackageSearch } from "react-icons/lu";
import {
	MdOutlineBugReport, MdOutlineBusinessCenter, MdOutlineCategory
} from "react-icons/md";
import { PiMagicWand } from "react-icons/pi";
import { RiBookLine } from "react-icons/ri";
import { TbChecklist } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";

import { useHasEmployeeRole, useHasUserRole } from "auth/auth.hooks";
import { MenuDivider, MenuItem, Tag } from "common/components";
import { useSyncOrders } from "order/order.hooks";

import styles from "./styles.module.sass";


export default function ({
  className, onOpenChecklist, onSendFeedback, ...rest
}: AppSidebarProps) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = [styles.appsidebar, className].join(" ");

  // store
  const { orders } = useSyncOrders({ auto: false });
  const hasUserRole = useHasUserRole();
  const hasEmployeeRole = useHasEmployeeRole();

  // locale
  const changeLocale = useCallback((lang: 'en' | 'ar') => {
    i18n.changeLanguage(lang);
    moment.updateLocale(lang, {});
  }, []);

  return (
    <Menu className={classes} {...rest}>
      <MenuDivider title={["Sidebar.orders"]} />
      <MenuItem
        icon={<LuPackageOpen size={19} style={{ marginInlineEnd: 1 }} />}
        text={["Sidebar.manageOrders"]}
        selected={location.pathname.startsWith('/orders')}
        onClick={() => navigate('/orders')}
        labelElement={
          <Tag minimal
            hidden={orders.length === 0}
            intent="success">
            {orders.length}
          </Tag>
        }
      />
      <MenuItem
        icon={<LuPackageSearch size={19} style={{ marginInlineEnd: 1 }} />}
        text={["Sidebar.orderHistory"]}
        selected={location.pathname.startsWith('/order-history')}
        onClick={() => navigate('/order-history')}
      />
      <MenuItem
        icon={<Icon icon="series-search" style={{ marginInlineStart: 1, marginInlineEnd: 3 }} />}
        text={["Sidebar.salesReport"]}
        popoverProps={{ usePortal: true }}
        selected={location.pathname.startsWith('/reports/sales')}
        hidden={!hasEmployeeRole('admin', 'manager')} >
        <MenuItem
          text={["Sidebar.basicSalesReport"]}
          selected={location.pathname.startsWith('/reports/sales/basic')}
          onClick={() => navigate('/reports/sales/basic')}
        />
        <MenuItem
          text={["Sidebar.dailySalesReport"]}
          selected={location.pathname.startsWith('/reports/sales/daily')}
          onClick={() => navigate('/reports/sales/daily')}
        />
        <MenuItem
          text={["Sidebar.weeklySalesReport"]}
          selected={location.pathname.startsWith('/reports/sales/weekly')}
          onClick={() => navigate('/reports/sales/weekly')}
        />
        <MenuItem
          text={["Sidebar.monthlySalesReport"]}
          selected={location.pathname.startsWith('/reports/sales/monthly')}
          onClick={() => navigate('/reports/sales/monthly')}
        />
      </MenuItem>

      <MenuDivider title={["Sidebar.catalog"]} />
      <MenuItem
        icon={<PiMagicWand size={18} style={{ marginInlineStart: 1, marginInlineEnd: 3 }} />}
        onClick={() => navigate('/ai-menu-import')}
        selected={location.pathname.startsWith('/ai-menu-import')}
        text={["Sidebar.importMenu"]} />
      <MenuItem
        icon={<BsBoxSeam size={16} style={{ marginInlineStart: 1, marginInlineEnd: 3 }} />}
        onClick={() => navigate('/products')}
        selected={location.pathname.startsWith('/products')}
        text={["Sidebar.products"]} />
      <MenuItem
        icon={<BiCategory size={19} style={{ marginInlineEnd: 1 }} />}
        onClick={() => navigate('/categories')}
        selected={location.pathname.startsWith('/categories')}
        text={["Sidebar.categories"]} />
      <MenuItem
        icon={<RiBookLine size={18} style={{ marginInlineEnd: 3 }} />}
        onClick={() => navigate('/catalogs')}
        selected={location.pathname.startsWith('/catalogs')}
        text={["Sidebar.catalogs"]} />
      <MenuDivider
        title={["Sidebar.organization"]}
        hidden={!hasEmployeeRole('admin', 'manager')}
      />
      <MenuItem
        icon={<AiOutlineBank size={18} style={{ marginBottom: 2, marginInlineStart: 1, marginInlineEnd: 1 }} />}
        onClick={() => navigate('/merchants')}
        selected={location.pathname.startsWith('/merchants')}
        hidden={!hasEmployeeRole('admin')}
        text={["Sidebar.merchants"]} />
      <MenuItem
        icon={<BiMedal size={21} style={{ marginInlineStart: -1 }} />}
        onClick={() => navigate('/subscription')}
        selected={location.pathname.startsWith('/subscription')}
        hidden={!hasEmployeeRole('admin')}
        text={["subscription"]} />
      <MenuItem
        icon={<BiStore size={19} style={{ marginInlineEnd: 1 }} />}
        onClick={() => navigate('/branches')}
        selected={location.pathname.startsWith('/branches')}
        hidden={!hasEmployeeRole('admin', 'manager')}
        text={["Sidebar.branches"]} />
      <MenuItem
        icon={<FiUsers size={17} style={{ marginInlineStart: 2, marginInlineEnd: 1 }} />}
        onClick={() => navigate('/employees')}
        selected={location.pathname.startsWith('/employees')}
        hidden={!hasEmployeeRole('admin')}
        text={["Sidebar.employees"]} />
      {/* <MenuItem
        icon={<MdOutlineTableBar size={18} style={{ marginInlineStart: 1, marginInlineEnd: 2 }} />}
        onClick={() => navigate('/tables')}
        hidden={identity?.business?.category !== 'restaurant'}
        selected={location.pathname.startsWith('/tables')}
        text="Tables" /> */}
      <MenuItem
        icon={<MdOutlineBusinessCenter size={19} style={{ marginInlineEnd: 1 }} />}
        onClick={() => navigate('/business')}
        selected={location.pathname.startsWith('/business')}
        hidden={!hasEmployeeRole('admin')}
        text={["Sidebar.business"]} />

      <MenuDivider title={["Sidebar.help"]} />
      <MenuItem
        icon={<TbChecklist size={18} />}
        text={["Sidebar.checklist"]}
        hidden={onOpenChecklist == null || !hasEmployeeRole()}
        onClick={() => onOpenChecklist!()}
      />
      <MenuItem
        icon={<MdOutlineCategory size={19} style={{ marginInlineEnd: 1 }} />}
        onClick={() => navigate('/digital-marketing-assets')}
        selected={location.pathname.startsWith('/digital-marketing-assets')}
        text={["Sidebar.digitalMarketingAssets"]} />
      <MenuItem
        icon={<IoIosApps size={19} style={{ marginInlineEnd: 1 }} />}
        onClick={() => navigate('/apps')}
        selected={location.pathname.startsWith('/apps')}
        text={["Sidebar.applications"]} />
      <MenuItem
        icon={<MdOutlineBugReport size={19} style={{ marginInlineEnd: 1 }} />}
        hidden={onSendFeedback == null}
        onClick={() => onSendFeedback!()}
        text={["Sidebar.sendFeedback"]} />

      {/* Administration*/}
      <MenuDivider
        title={["Sidebar.admin"]}
        hidden={!hasUserRole("admin")}
      />
      <MenuItem
        icon={<FiUsers size={17} style={{ marginInlineStart: 2, marginInlineEnd: 1 }} />}
        onClick={() => navigate('/admin/users')}
        text={["Sidebar.users"]}
        hidden={!hasUserRole("admin")}
        selected={location.pathname.startsWith('/admin/users')}
      />
      <MenuDivider title={["Sidebar.settings"]} />
      <MenuItem
        popoverProps={{ usePortal: true }}
        icon={<LuLanguages size={17} style={{ marginInlineStart: 1 }} />}
        text={["Sidebar.language"]} >
        <MenuItem
          icon={i18n.language.startsWith('en') ? "tick" : "blank"}
          text={["Languages.english"]}
          onClick={() => changeLocale('en')}
        />
        <MenuItem
          icon={i18n.language.startsWith('ar') ? "tick" : "blank"}
          text={["Languages.arabic"]}
          onClick={() => changeLocale('ar')}
        />
      </MenuItem>
    </Menu>
  );
}

export type AppSidebarProps = Omit<MenuProps, "children"> & {
  onOpenChecklist?: () => void,
  onSendFeedback?: () => void,
}