/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { CurrentBusinessChanged } from "business/business.store";
import { ApiCallState } from "common/utils/api-state";
import { AddressSuggestion, GeoAddress, VisitorLocation } from "geo/geo.entities";

export interface AddressSearchResult {
  meta: { query: string, componentId: string },
  suggestions: AddressSuggestion[],
}

export interface GetAddressDetailsResult {
  meta: { id: string },
  address: GeoAddress,
}

export interface GeocodeIpResult {
  latitude: number;
  longitude: number;
  countryCode: string;
  timezone: string;
}

export const GeoStore = createSlice({
  name: "geo",
  initialState: {
    searchAddress: {} as ApiCallState<AddressSearchResult>,
    getAddressDetails: {} as ApiCallState<GetAddressDetailsResult>,
    geocodeIp: {} as ApiCallState<GeocodeIpResult>,
    syncVisitorLocationMap: {} as {
      [key: string]: ApiCallState<VisitorLocation>
    },
  },
  reducers: {
    SearchAddressState: (state, action: { payload: ApiCallState<AddressSearchResult> }) => {
      state.searchAddress = action.payload;
    },
    GetAddressDetailsState: (state, action: { payload: ApiCallState<GetAddressDetailsResult> }) => {
      state.getAddressDetails = action.payload;
    },
    UpdateGeocodeIpState: (state, action: { payload: ApiCallState<GeocodeIpResult> }) => {
      state.geocodeIp = action.payload;
    },
    UpdateSyncVisitorLocationState: (
      state,
      action: { payload: { id: string, state: ApiCallState<VisitorLocation> }, },
    ) => {
      state.syncVisitorLocationMap[action.payload.id] = action.payload.state;
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, getInitialState);
    builder.addCase(CurrentBusinessChanged, (state) => {
      state.syncVisitorLocationMap = {};
    });
  },
});

export const {
  SearchAddressState,
  GetAddressDetailsState,
  UpdateGeocodeIpState,
  UpdateSyncVisitorLocationState,
} = GeoStore.actions;

export const { reducer: geoReducer, getInitialState } = GeoStore;
export type GeoState = ReturnType<typeof GeoStore.getInitialState>
