/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ThunkAction } from "redux-thunk";

import { ApiState } from "app.store";
import { ApiClient } from "./api-client";

export type ApiAction<V = void> = ThunkAction<Promise<V>, ApiState, ApiClient, any>;

export interface ApiSyncControllerListener {
  onCancel: () => void;
  onCommand?: (cmd: string) => void;
}

export class ApiSyncController {
  protected listener?: ApiSyncControllerListener;

  public cancel() {
    this?.listener?.onCancel();
  }

  public emit(cmd: string) {
    if (this?.listener?.onCommand)
      this.listener.onCommand(cmd);
  }

  public attach(listener: ApiSyncControllerListener) {
    this.listener = listener;
  }
}