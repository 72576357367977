/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineBank } from 'react-icons/ai';
import { BsStripe } from 'react-icons/bs';
import { FaApplePay, FaCcVisa, FaGooglePay, FaWallet } from 'react-icons/fa';
import { FaCashRegister } from "react-icons/fa6";

import { CardHeader, InfoTooltip, Row } from "common/components";
import Card, { CardProps } from "common/components/cards/Card";
import { MerchantAccount } from "merchant/merchant.entities";
import styles from "./styles.module.scss";


export default function MerchantAccountCard({
  className, value, onClick, children, ...rest
}: MerchantAccountCardProps) {
  const { t } = useTranslation();
  const classes = useMemo(() => [styles.merchantAccountCard, className].join(" "), [className]);
  const onboarding = value.status === 'onboarding';

  return (
    <Card
      key={value.id}
      interactive={onClick != null}
      className={classes}
      onClick={onClick}
      {...rest}>
      <CardHeader
        left={value.type === 'stripe' ? <BsStripe /> : <AiOutlineBank />}
        title={value.name + (value.status === 'onboarding' ? ' (onboarding)' : '')}
        right={
          <InfoTooltip
            content={t(`Merchant.Descriptions.${value.type}`)}
            iconProps={{ style: { marginBottom: 2.7 } }}
          />
        }
      />
      <Row className={styles.paymentMethods}>
        <FaWallet size={18} data-enabled={!onboarding && value.uponArrivalPayments} />
        <FaCashRegister size={17} data-enabled={!onboarding && value.atCounterPayments} />
        <FaCcVisa size={19} data-enabled={!onboarding && value.cardPayments} />
        <FaApplePay size={34} style={{ margin: '-7px 0px -7px -1px' }} data-enabled={!onboarding && value.applePay} />
        <FaGooglePay size={34} style={{ margin: '-7px 0px -7px -8px' }} data-enabled={!onboarding && value.googlePay} />
      </Row>
      {children}
    </Card>
  );
}

export type MerchantAccountCardProps = CardProps & {
  value: MerchantAccount,
};