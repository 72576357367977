/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Button } from "common/components/buttons";
import FormField, { FormFieldProps } from "common/components/form/Field";
import { OnChangeEventHandler } from "common/utils";

import { ExImage } from "../../media.entities";
import ExImageEditor, { ExImageEditorProps } from "../ExImageEditor";
import styles from "./styles.module.sass";


export default function ExImageField({
  className, label, hidden, disabled, error,
  actions, readOnly, name, value, onChange, ...rest
}: ExImageFieldProps) {

  return (
    <FormField
      className={[styles.exImageField, className].join(" ")}
      label={label}
      disabled={disabled}
      hidden={hidden}
      error={error}
      actions={
        <>
          {actions}
          {value &&
            <Button
              key='remove'
              small
              minimal
              text={["remove", { postProcess: 'lowercase' }]}
              onClick={() => onChange({ target: { name, value: null } })} />
          }
        </>
      }>
      <ExImageEditor
        name={name}
        value={value}
        disabled={readOnly || disabled}
        onChange={onChange}
        {...rest} />
    </FormField>
  );
}



export type ExImageFieldProps = FormFieldProps & ExImageEditorProps & {
  readOnly?: boolean;
  name: string,
  value?: ExImage | null,
  onChange: OnChangeEventHandler<ExImage>;
}
