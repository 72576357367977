/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Icon, IconProps } from "@blueprintjs/core";
import { Tooltip2, Tooltip2Props } from "@blueprintjs/popover2";
import _ from 'lodash';
import { useMemo } from "react";

import { useTranslation } from 'react-i18next';
import styles from "./styles.module.scss";


export default function ({ className, iconProps, content, ...rest }: InfoTooltipProps) {
  const { t } = useTranslation();
  const classNames = useMemo(() => {
    const classes = [styles.container, className];
    return classes.join(' ').trim();
  }, [className]);
  const contentText = _.isArray(content) ? t(content[0], content[2], content[1]) : content;

  return (
    <Tooltip2 className={classNames} content={contentText} {...rest}>
      <Icon icon='help' size={12} {...iconProps} />
    </Tooltip2>
  );
}

export type InfoTooltipProps = Omit<Tooltip2Props, "content"> & {
  iconProps?: Partial<IconProps>,
  content: string | [string, object?, string?],
}