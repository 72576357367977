/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { TFunction } from "react-i18next";

export const Precisions = ["seconds", "minutes", "hours", "days"] as const;
export type Precision = typeof Precisions[number]
export const weekdaysShort = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export function formatDuration(t: TFunction<"translation", undefined>, ms: number, opts: {
  precision?: Precision,
  places?: number,
  join?: string,
  joinLast?: string,
  ago?: boolean,
  orText?: string | [string, object?, string?],
} = {}) {
  if (!ms && opts.orText) {
    return _.isArray(opts.orText)
      ? t(opts.orText[0], opts.orText[2], opts.orText[1])
      : opts.orText;
  }

  let ago = false;
  if (ms < 0) {
    ago = opts.ago === true;
    ms = -ms;
  }

  opts = _.merge({
    precision: "seconds",
    join: t("Duration.separator"),
    joinLast: t("Duration.lastSeparator"),
  }, opts);

  const durations = {
    days: Math.floor(ms / 86400000),
    hours: Math.floor(ms / 3600000) % 24,
    minutes: Math.floor(ms / 60000) % 60,
    seconds: Math.floor(ms / 1000) % 60,
  };

  const entries = Object.entries(durations)
    .filter(([key, value], index) => value !== 0 &&
      Precisions.indexOf(key as Precision) >= Precisions.indexOf(opts.precision!))
    .filter((_entry, index) => opts.places ? index + 1 <= opts.places : true);
  if (entries.length === 0)
    return "";

  let duration = entries.map(([key, value]) => {
    let key_ = "Duration." + _.lowerFirst(key);
    return t(key_, { count: value });
  }).reduce((prev, curr, index, arr) => {
    let separator = opts.join;
    if (opts.joinLast && index + 1 === arr.length)
      separator = opts.joinLast;
    return [prev, curr].join(separator);
  });

  if (ago)
    duration = t("Duration.ago", { duration });

  return duration;
};
