/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as Blueprint from "@blueprintjs/core";
import _ from 'lodash';
import { useMemo } from "react";
import { useTranslation } from 'react-i18next';

import styles from "./styles.module.scss";

export default function (
  { action, className, children, ...rest }: CalloutProps
) {
  const { t } = useTranslation();
  const classes = useMemo(() => {
    const cls = [styles.callout, "callout", className];
    return cls.join(" ").trim();
  }, [className])
  children = useMemo(() => {
    if (_.isArray(children))
      return t(children[0], children[2], children[1]);
    return children;
  }, [children]);
 
  return (
    <Blueprint.Callout className={classes} {...rest}>
      <div className={styles.actions}>
        {action}
      </div>
      {children}
    </Blueprint.Callout>
  );
}

export type CalloutProps = Blueprint.CalloutProps & {
  action?: JSX.Element,
}