/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction, failed, loading, success } from "common/utils";
import { MerchantAccount } from "merchant/merchant.entities";
import { SaveMerchantAccountState } from "../../merchant.store";


export function CreateMerchantAccount(merchant: MerchantAccount): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(SaveMerchantAccountState(loading()));
    try {
      const { value } = await api.post(`/pricing/merchants/${merchant.type}`, { data: merchant })
      dispatch(SaveMerchantAccountState(success(value)));
    } catch (error) {
      dispatch(SaveMerchantAccountState(failed(error)));
    }
  };
}
