/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Checkbox } from "@blueprintjs/core";
import { useMemo } from "react";

import { Form } from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { ProductOptionGroup } from "product/product.entities";
import styles from "./styles.module.scss";


export default function ({
  className, name, readOnly, group, value, errors, onChange,
  children, ...rest
}: OptionPickerFormProps) {
  const classes = useMemo(() => [styles.optionPickerForm, className].join(" "), [className]);
  const selectedInGroup = group.options.filter(option => value?.includes(option.id) === true);

  return (
    <Form className={classes} {...rest}>
      {group?.options.map((option, index) => (
        <div key={option.id} className={styles.option}>
          <Checkbox
            checked={value?.includes(option.id) === true}
            disabled={value?.includes(option.id) !== true && group.maxSelected === selectedInGroup.length}
            onChange={(event) => {
              if (name && onChange) {
                const newValue = value ? value.slice() : [];
                if (event.target['checked']) {
                  newValue.push(option.id);
                } else {
                  newValue.splice(newValue.indexOf(option.id), 1);
                }
                onChange({
                  target: {
                    name: name!,
                    value: newValue,
                  }
                })
              }
            }}>
            {option.name}
          </Checkbox>
          <span data-selected={value?.includes(option.id)}>+{option.price}</span>
        </div>
      ))}
      {children}
    </Form>
  );
}

export type OptionPickerFormProps = EntityFormProps<string[]> & {
  group: ProductOptionGroup
};