/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { TFunction } from "i18next";
import logger from 'loglevel';

import { Toast } from "common/components";


export function copyToClipboard(textToCopy: string, t?: TFunction, toastTimeout: number = 600) {
  var textArea = document.createElement('textarea');
  textArea.value = textToCopy!;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  var successful = false;
  try {
    successful = document.execCommand('copy');
    if (t && toastTimeout > 0) {
      Toast.showPrimary({
        message: t('CopiedToClipboard'),
        timeout: toastTimeout,
      });
    }
  } catch (err) {
    logger.error('failed to copy to clipboard', err);
    successful = false;
  }
  document.body.removeChild(textArea);
  return successful;
}