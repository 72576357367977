/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { SyncCatalogs } from "catalog/actions/sync-catalogs.action";
import { Catalog } from "catalog/catalog.entities";
import { isEmpty, ApiSyncController, useApiState } from "common/utils";


export function useSyncCatalogs<T extends Catalog = Catalog>(opts: {
  auto?: boolean,
  find?: (value: Catalog, index: number, array: Catalog[]) => boolean,
  filter?: (value: Catalog, index: number, array: Catalog[]) => boolean,
} = {}, deps: any[] = []) {
  const [syncCatalogsState, dispatch] = useApiState((state) => state.catalog.sync);

  const syncCatalogs = useCallback(() => {
    const unsub = new ApiSyncController();
    dispatch(SyncCatalogs(unsub));
    return unsub.cancel;
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(syncCatalogsState))
      syncCatalogs();
  }, [opts.auto, syncCatalogsState])

  const catalogs = useMemo(() =>
    ((opts.filter == null
      ? syncCatalogsState.value
      : syncCatalogsState.value?.filter(opts.filter)) || []) as T[],
    [syncCatalogsState, opts.filter, ...deps]);

  const catalog = useMemo(() =>
    opts.find == null
      ? null
      : syncCatalogsState.value?.find(opts.find) as T,
    [syncCatalogsState, opts.find, ...deps]);

  return { syncCatalogsState, syncCatalogs, catalogs, catalog };
}