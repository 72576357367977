/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useNavigate } from "react-router-dom";

import { ApiError } from "common/utils";
import { Button } from '../../buttons';
import Backout, { BackoutProps } from '../Backout';


export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Backout
      icon="search"
      title={["PageNotFound.title"]}
      message={["PageNotFound.msg"]}
      action={
        <Button
          outlined
          text={["PageNotFound.button"]}
          onClick={() => navigate("/")}
        />
      }
    />
  );
}

export type PageNotFoundProps = { error: ApiError } & BackoutProps;