/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { HandleProps, MultiSlider, MultiSliderProps } from "@blueprintjs/core";
import { OnChangeEventHandler } from "common/utils";

import { useMemo, useState } from "react";
import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";


export default function ({
  className, label, disabled, error, startName, endName,
  readOnly, startValue, endValue, onChange, max,
  startHandlerProps, endHandlerProps, valueAspect = 1,
  sliderProps, ...rest
}: NumberRangeFieldProps) {
  const classes = useMemo(() => {
    const classes = [styles.rangeField, className];
    if (disabled || readOnly)
      classes.push(styles.muted)
    return classes.join(' ').trim();
  }, [className]);

  const [startVal, setStartVal] = useState(startValue);
  const [endVal, setEndVal] = useState(endValue);

  return (
    <FormField
      className={classes}
      label={label}
      disabled={disabled}
      error={error}
      {...rest} >
      <MultiSlider
        disabled={disabled}
        showTrackFill={!disabled && !readOnly}
        intent={error ? "danger" : "success"}
        max={max}
        onChange={readOnly ? undefined : (values) => {
          setStartVal(values[0]);
          setEndVal(values[1]);
        }}
        onRelease={readOnly ? undefined : (values) => {
          if (onChange && values[0] !== startValue)
            onChange({ target: { name: startName, value: values[0] * valueAspect } });
          if (onChange && values[1] !== endValue)
            onChange({ target: { name: endName, value: values[1] * valueAspect } });
        }}
        {...sliderProps}
      >
        <MultiSlider.Handle
          value={startVal}
          type="start"
          {...startHandlerProps}
        />
        <MultiSlider.Handle
          value={endVal}
          type="end"
          {...endHandlerProps}
        />
      </MultiSlider>
    </FormField>
  );
}



export type NumberRangeFieldProps = FormFieldProps & {
  startName: string,
  endName: string,
  startValue: number,
  endValue: number,
  max?: number,
  sliderProps?: Omit<MultiSliderProps, "onChange">,
  startHandlerProps?: HandleProps,
  endHandlerProps?: HandleProps,
  onChange?: OnChangeEventHandler<number>
  valueAspect?: number,
  readOnly?: boolean,
}