/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from 'moment';

import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import {
  BackActivityIndicator, BackAsyncError, Backout, Button,
  Card, CardContent, CardHeader, CardItem, Column,
  DateRangePicker, Grid, InfoTooltip, PageContent, PageHeader,
} from "common/components";
import { ApiCallStateBuilder, isEmpty, useApiState } from "common/utils";
import { loadBasicSalesReportAction } from 'report/actions/load-basic-sales-report.action';

import styles from "./styles.module.scss";


export default function () {
  const { t } = useTranslation();
  const [loadBasicSalesReportState, dispatch] = useApiState((state) =>
    state.report.loadBasicSalesReport);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(
    [moment().add(-1, 'month').toDate(), moment().toDate()]);

  useEffect(() => {
    if (isEmpty(loadBasicSalesReportState) && dateRange[0] != null) {
      dispatch(loadBasicSalesReportAction(dateRange[0]!, dateRange[1]!));
    }
  }, [loadBasicSalesReportState, dateRange])

  return (
    <>
      <PageHeader title={["Report.BasicSalesReport.header"]}>
        <DateRangePicker
          className={styles.dateRangeInput}
          formatDate={(date) => moment(date).format(t("Report.dateRangePickerFormat"))}
          parseDate={(str) => moment(str).toDate()}
          onChange={(range) => setDateRange(range)}
          value={dateRange}
        />
        <Button
          icon='arrow-right'
          className={styles.dateRangeButton}
          onClick={() => dispatch(loadBasicSalesReportAction(dateRange[0]!, dateRange[1]!))}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder
          state={loadBasicSalesReportState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueNull={() => {
            return <Backout
              icon="series-search"
              message={["Report.noDataBackout"]} />
          }}
          onValue={(report) => {
            return (
              <>
                <h3>{t("Report.BasicSalesReport.title", "", {
                  from: moment(dateRange[0]).format(t("Report.BasicSalesReport.titleDateFormat")),
                  to: moment(dateRange[1]).format(t("Report.BasicSalesReport.titleDateFormat"))
                })}</h3>
                <Grid md={2} xs={1} gap={20}>
                  <Card style={{ minHeight: 400 }}>
                    <CardHeader
                      title={["Report.BasicSalesReport.SalesSummary.header", { currency: report.currency }]} />
                    <CardContent>
                      <Column className={styles.salesReportBody}>
                        <CardItem
                          text={["Report.BasicSalesReport.SalesSummary.numberOfOrders"]}
                          right={report.orders.length}
                        />
                        <CardItem
                          left={
                            <span>
                              {t("Report.BasicSalesReport.SalesSummary.stripeAmount")}&nbsp;
                              <InfoTooltip
                                position="top-left"
                                iconProps={{ style: { marginBottom: 2 } }}
                                content={['Report.BasicSalesReport.SalesSummary.stripeAmountTooltip']}
                              />
                            </span>
                          }
                          right={report.stripeAmount.toFixed(2)}
                        />
                        <CardItem
                          text={["Report.BasicSalesReport.SalesSummary.cashAmount"]}
                          right={report.cashAmount.toFixed(2)}
                        />
                        <CardItem
                          text={["Report.BasicSalesReport.SalesSummary.totalAmount"]}
                          right={report.totalAmount.toFixed(2)}
                        />
                        <h3>{t("Report.BasicSalesReport.SalesSummary.itemsSold")}</h3>
                        {report.topProducts.map(product => (
                          <>
                            <CardItem
                              key={'product_' + product.id}
                              text={product.name}
                              right={`${product.totalQuantity} / ${product.totalAmount.toFixed(2)}`}
                            />
                            {product.topOptions?.map(option => (
                              <CardItem
                                key={'option_' + option.id}
                                text={"- " + option.name}
                                right={`${option.totalQuantity} / ${option.totalAmount.toFixed(2)}`}
                              />
                            ))}
                          </>
                        ))}
                        <h3>{t("Report.BasicSalesReport.SalesSummary.topCustomersByOrders")}</h3>
                        {report.topCustomersByNumberOfOrders.map(customer => (
                          <>
                            <CardItem
                              key={'customerByOrders_' + customer.id}
                              text={customer.name}
                              right={`${customer.numberOfOrders} / ${customer.totalAmount.toFixed(2)}`}
                            />
                          </>
                        ))}
                        <h3>{t("Report.BasicSalesReport.SalesSummary.topCustomersByAmount")}</h3>
                        {report.topCustomersByTotalAmount.map(customer => (
                          <CardItem
                            key={'customerByAmount_' + customer.id}
                            text={customer.name}
                            right={`${customer.numberOfOrders} / ${customer.totalAmount.toFixed(2)}`}
                          />
                        ))}
                      </Column>
                    </CardContent>
                  </Card>
                  <Card style={{ minHeight: 400 }}>
                    <CardHeader
                      title={["Report.BasicSalesReport.DetailedSales.header", { currency: report.currency }]} />
                    <CardContent>
                      <Column className={styles.salesReportBody}>
                        <table className={styles.detailedReportTable}>
                          <thead>
                            <tr>
                              <td>{t("Report.BasicSalesReport.DetailedSales.dateTime")}</td>
                              <td>{t("Report.BasicSalesReport.DetailedSales.price")}</td>
                              <td>{t("Report.BasicSalesReport.DetailedSales.netAmount")}</td>
                              <td>{t("Report.BasicSalesReport.DetailedSales.orderId")}</td>
                            </tr>
                          </thead>
                          <tbody>
                            {report.orders.map((item) =>
                              <tr key={item.number}>
                                <td>{moment(item.createdAt).format(t("Report.BasicSalesReport.DetailedSales.dateTimeFormat"))}</td>
                                <td>{item.totalPrice.toFixed(2)}</td>
                                <td>{item.netAmount.toFixed(2)}</td>
                                <td><a href={`/order-history/${item.type}/${item.id}`} target='_blank'>{item.number}</a></td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Column>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          }} />
      </PageContent>
    </>
  );
}
