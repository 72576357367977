/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

export function deepDiffs<T extends {}>(obj1: T, obj2: T) {
  return _.reduce(obj1, function (result, value, key) {
    if (_.isPlainObject(value)) {
      const diffs = deepDiffs(value, _.get(obj2 as any, key, {}));
      if (!_.isEmpty(diffs)) result[key] = diffs;
    } else if (!_.isEqual(value, _.get(obj2, key, {}))) {
      result[key] = value;
    }
    return result;
  }, _.reduce(obj2, function (result, value, key) {
    if (_.get(obj1, key) == null && _.get(obj2, key) != null)
      result[key] = null;
    return result;
  }, {}));
}

export function diffs<T extends {}>(obj1: T, obj2: T) {
  return _.reduce(obj1, function (result, value, key) {
    if (!_.isEqual(value, _.get(obj2, key, {}))) {
      result[key] = value;
    }
    return result;
  }, _.reduce(obj2, function (result, value, key) {
    if (_.get(obj1, key) == null && _.get(obj2, key) != null)
      result[key] = null;
    return result;
  }, {}));
}

export function cloneEntityDeep(obj: object) {
  const transform = (o: object) =>
    _.transform(o, function (result: any, value, key) {
      if (_.isArray(value)) {
        result[key] = _.map(value, cloneEntityDeep)
      } else if (_.isPlainObject(value)) {
        result[key] = transform(value);
      } else if (!["id", "createdBy", "createdAt", "updatedAt"].includes(key)) {
        result[key] = value;
      }
    });
  return transform(_.cloneDeep(obj));
}