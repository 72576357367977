/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ApiAction, failed, loading, success } from "common/utils";

import { UpdateLoadMyBusinessesState } from "../business.store";


export function LoadMyBusinessesAction(): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(UpdateLoadMyBusinessesState(loading()));
    try {
      const { value } = await api.get('/businesses')
      dispatch(UpdateLoadMyBusinessesState(success(value)));
    } catch (error) {
      dispatch(UpdateLoadMyBusinessesState(failed(error)));
    }
  };
}
