/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as Blueprint from "@blueprintjs/core";
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from "./styles.module.sass";


export default function Tag({
  className, children, ...rest
}: TagProps) {
  const { t } = useTranslation();
  const classes = [styles.tag, className];
  children = useMemo(() => {
    if (_.isArray(children))
      return t(children[0], children[2], children[1]);
    return children;
  }, [children]);
  return (
    <Blueprint.Tag className={classes.join(" ")} {...rest}>
      {children}
    </Blueprint.Tag>
  );
}

export type TagProps = Blueprint.TagProps & {
}