/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiStore } from "react-icons/bi";
import { BsBoxSeam } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { Branch } from "branch/branch.entities";
import { useSyncBranches } from "branch/branch.hooks";
import BranchCard from "branch/components/BranchCard";
import {
	BackAsyncError, Backout, Button, Callout, Grid, GridContentLoader
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { ApiCallStateBuilder } from "common/utils";


export default function BranchesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const { syncBranchesState, branches } = useSyncBranches({
    filter: (branch) =>
      _.isEmpty(searchQuery) ||
      _.values(branch).join(" ").toLowerCase().includes(searchQuery!),
  }, [searchQuery]);

  return (
    <>
      <PageHeader
        icon={<BiStore size={22} style={{ marginInlineEnd: 2, marginTop: -1.5 }} />}
        title={['Branch.ListPage.header']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text={["create"]}
          intent="success"
          onClick={() => navigate('new')}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder<Branch[]>
          state={syncBranchesState}
          onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueEmpty={() => (
            <>
              <Callout intent='primary' style={{ marginBottom: 30 }}>
                {t("Branch.ListPage.welcomeCallout")}
              </Callout>
              <div style={{ marginTop: 150 }}>
                <Backout
                  icon={<BsBoxSeam style={{ marginTop: -3, marginInlineStart: -2 }} />}
                  title={["Branch.ListPage.noBranchesTitle"]}>
                  <div style={{ marginTop: 20 }}>
                    <Button
                      outlined
                      text={["create"]}
                      onClick={() => navigate("/branches/new")} />
                  </div>
                </Backout>
              </div>
            </>
          )}
          onValueNotEmpty={() =>
            <Grid md={3} sm={2} xs={1} gap={20} >
              {branches.map(branch =>
                <BranchCard
                  key={branch.id}
                  value={branch}
                  onClick={() => navigate(branch.id!)}
                />
              )}
            </Grid>
          }
        />
      </PageContent>
    </>
  );
}
